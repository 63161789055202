import { fetchTagData } from '../actions/routines';

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const tag = (state = initialState, action) => {
    switch (action.type) {
        case fetchTagData.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case fetchTagData.SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case fetchTagData.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchTagData.FULFILL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default tag;
