export const toggleFilterable = () => ({
    type: 'TOGGLE_FILTERABLE'
});

export const openFilterable = () => ({
    type: 'OPEN_FILTERABLE'
});

export const hideFilterable = () => ({
    type: 'HIDE_FILTERABLE'
});