import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../../../../Footer/Footer';
import { Col, Container, Row } from 'mdbreact';
import { FormattedMessage } from 'react-intl';
import Header from '../../Header';
import Input from '../../../../Form/Input';
import { Link } from 'react-router-dom';
import ButtonWithSpinner from '../../../../Utils/ButtonWithSpinner';

const RequestPassword = ({
    clientId,
    error,
    isLoading,
    onChange,
    onSubmit,
}) => {
    return (
        <div className="security request-password container-fluid">
            <Header />
            <Container>
                <Row>
                    <Col>
                        <h1 className="request-password__title">
                            <FormattedMessage id="security.password_recovery.title" />
                        </h1>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col md="4">
                        <p className="request-password__disclaimer">
                            <FormattedMessage id="security.password_recovery.disclaimer" />
                        </p>
                        <form
                            onSubmit={onSubmit}
                            className="form--white-text"
                            noValidate="novalidate"
                        >
                            <Input
                                value={clientId}
                                onChange={onChange}
                                name="clientId"
                                label="security.password_recovery.client_id"
                                type="text"
                                className={!error ? '' : 'is-invalid'}
                                invalidFeedback={error ? error : ''}
                            />
                            <div>
                                <ButtonWithSpinner
                                    label="security.password_recovery.btn"
                                    isLoading={isLoading}
                                    type="submit"
                                    className="btn-brand"
                                />
                            </div>
                            <p />
                            <Link className="security__url" to="/">
                                <FormattedMessage id="security.sign_up_url" />
                            </Link>
                        </form>
                    </Col>
                </Row>
            </Container>
            <Footer inverted />
        </div>
    );
};

RequestPassword.propTypes = {
    clientId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
};

export default RequestPassword;
