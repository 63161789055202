import React from 'react';
import Table from '../../../../Table/Table';
import getFairColumns from '../../getFairColumns';
import { FormattedMessage } from 'react-intl';
import FairSubcomponent from '../../../../Table/components/FairSubcomponent/FairSubcomponent';

class FairCart extends React.Component {
    render() {
        const {
            loading,
            fairProducts,
            fairCartProducts,
            setFairProducts,
            setFairCartProducts,
            addProduct,
            loadingState,
        } = this.props;

        return (
            <React.Fragment>
                <div className="cart__header">
                    <span className="cart__order-date">
                        <FormattedMessage id="cart.fair.title" />
                    </span>
                </div>
                <Table
                    data={fairProducts}
                    isFairTable={true}
                    columns={getFairColumns()}
                    pageSize={Object.keys(this.props.fairCartProducts).length}
                    itemsCount={
                        Object.keys(this.props.fairCartProducts).length > 0
                            ? Object.keys(this.props.fairCartProducts).length
                            : 3
                    }
                    defaultExpanded={fairProducts.map((x) => true)}
                    expanded={fairProducts.map((x) => true)}
                    SubComponent={(row) => {
                        return (
                            <FairSubcomponent
                                row={row}
                                fairProducts={fairProducts}
                                fairCartProducts={fairCartProducts}
                                setFairProducts={setFairProducts}
                                setFairCartProducts={setFairCartProducts}
                                addProduct={addProduct}
                                loadingState={loadingState}
                            />
                        );
                    }}
                    loading={loading}
                    isFairFixed={true}
                />
                {/* <div className="cart__footer">
                    <ButtonWithSpinner
                        disabled={fairProducts.length === 0}
                        onClick={makeOrder}
                        label="cart.order_btn.make_order"
                        className="cart__make-order btn-flat btn-success"
                    />
                    <NotificationContainer
                        status={orderStatus}
                        type="dialog"
                        title={
                            orderStatus === 'error'
                            ? <FormattedMessage id="error"/>
                            : <FormattedMessage id="cart.order.placed_success"/>
                        }
                        text={orderStatus === "error" && (<FormattedMessage id="error_call" />)}
                        show={showDialog}
                        handleClose={handleCloseNotification}
                    />
                </div> */}
            </React.Fragment>
        );
    }
}

export default FairCart;
