import PropTypes from 'prop-types'
import React from 'react';
import RecipesList from "./../components/RecipeList";
import {connect} from "react-redux";
import {
    toggleRecipeFavourites,
    fetchFavouritesRecipeListPromiseCreator
} from "../../../../actions/routines";
import {bindActionCreators} from "redux";
import {bindPromiseCreators} from "redux-saga-routines";
import {resetHasMore} from "../../../../actions/recipe";
import * as R from "ramda";

class RecipesFavouritesListContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            recipes: [],
            offset: 0,
            limit: 10
        }
    }

    componentDidMount() {
        this.props.resetHasMore()
    }

    loadMore = () => {
        const { limit, offset } = this.state;

        this.props.fetchFavouritesRecipeListPromiseCreator({limit: limit, offset: offset}).then((data) => {
            this.setState(prevState => {
                return {
                    recipes: [...prevState.recipes, ...data.data],
                    offset: prevState.offset + limit,
                }
            });
        });
    };

    toggleFavourites = (id) => {
        this.props.toggleRecipeFavourites(id);
        const index = R.findIndex(R.propEq('id', id))(this.state.recipes);

        if (index !== -1) {
            this.setState({
                recipes: R.remove(index, 1, this.state.recipes)
            })
        }
    };

    render() {
        const {loading, hasMore } = this.props;

        return (
            <RecipesList
                recipes={this.state.recipes}
                hasMore={hasMore}
                isLoading={loading}
                loadMore={this.loadMore}
                toggleFavourites={this.toggleFavourites}
                title="recipes.favourites_list.title"
            />
        );
    }
}

RecipesFavouritesListContainer.propTypes = {
    fetchRecipeListByCategoryPromiseCreator: PropTypes.func,
    hasMore: PropTypes.bool,
    loading: PropTypes.bool
};

RecipesFavouritesListContainer.defaultProps = {
    hasMore: true
};


const mapStateToProps = state => {
    return {
        loading: state.favouriteRecipesList.loading,
        hasMore: state.favouriteRecipesList.hasMore
    }
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({
            toggleRecipeFavourites, resetHasMore
        }, dispatch),
        ...bindPromiseCreators({
            fetchFavouritesRecipeListPromiseCreator
        }, dispatch),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(RecipesFavouritesListContainer);