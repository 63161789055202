import { call, put } from 'redux-saga/effects';
import {
    loginUserFailure,
    requestNewPasswordFailure,
    sendNewPasswordFailure,
    userLoggedIn,
    userLoggedOut,
} from '../actions/security';
import Security from '../services/api/Security';
import history from '../history';
import { loadingStart, loadingStop } from '../actions/loadingState';
import { sendNewPasswordAccessToken } from '../actions/routines';

export function* loginUserSaga(action) {
    yield put(loadingStart());
    try {
        const token = yield call(Security.login, action.data);

        localStorage.token = token.data.access_token;
        localStorage.refreshToken = token.data.refresh_token;
        yield put(userLoggedIn(token.data));

        if (token.data.need_password_reset) {
            localStorage.needPasswordReset = 1;
            history.push('/security/force-new-password');
        }
    } catch (err) {
        yield put(loginUserFailure(err.response.data.error_description));
    }
    yield put(loadingStop());
}

export function* logoutUserSaga() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    yield put(userLoggedOut());
    //history.push('/');
    document.location = '/';
}

export function* requestNewPasswordSaga(action) {
    yield put(loadingStart());
    try {
        yield call(Security.requestNewPassword, action.login);
        history.push('/security/password-recovery/check-email');
    } catch (err) {
        yield put(requestNewPasswordFailure(err.response.data.error));
    }
    yield put(loadingStop());
}

export function* sendNewPasswordResetTokenSaga(action) {
    yield put(loadingStart());
    try {
        yield call(Security.sendNewPasswordResetToken, action.data);
        history.push('/');
    } catch (err) {
        yield put(sendNewPasswordFailure(err.response.data.error_description));
    }
    yield put(loadingStop());
}

export function* sendNewPasswordAccessTokenSaga(action) {
    try {
        yield put(sendNewPasswordAccessToken.request());

        const response = yield call(
            Security.sendNewPasswordAccessToken,
            action.payload
        );

        yield put(sendNewPasswordAccessToken.success(response.data));
    } catch (error) {
        yield put(sendNewPasswordAccessToken.failure(error.message));
    } finally {
        yield put(sendNewPasswordAccessToken.fulfill());
    }
}
