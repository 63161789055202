import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../../Table/Table';
import {
    checkWhite,
    frontHand,
    pendingActions,
    trashGrey,
    alertPoint,
    checkPoint,
} from '../../../../../assets/images/icons';
import { FormattedMessage } from 'react-intl';
import { FormattedHtmlMessage } from '../../../../Utils/FormattedHtmlMessage';
import OrderButton from '../OrderButton';
import NotificationContainer from '../../../../Notification/NotificationContainer';
import { Link } from 'react-router-dom';
import Spinner from '../../../../Utils/Spinner';
import moment from 'moment';
import CampaignStickers from '../../../../CampaignPoints/CampaignStickers';
import { Context } from '../../../../context/context';
import { useContext } from 'react';
import UpdateOrderButton from '../UpdateOrderButton';
import { addItemsCart } from '../../../../../actions/routines';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ContinueOrderButton from '../ContinueOrderButton';
import OrderNumberInput from '../OrderNumberInput';
import PreferencesPhoto from '../../../../Table/components/PreferencesPhoto';

const Cart = ({
    id,
    data,
    columns,
    date,
    handleCartRemove,
    animateRemove,
    handleRemoveAnimation,
    showDialog,
    deleteLoading,
    makeOrder,
    handleCloseNotification,
    status,
    exportedStatus,
    basketItems,
    fetchingActiveLoading,
    completeOrderLoading,
    updateAmountOfItemLoading,
    openDeliveryDatepicker,
    updateDateCartLoading,
    deliveryDates,
    userDetails,
    showFooter = true,
    showHeader = true,
    showOrderNumberInput,
    hideHead,
    hideBody,
    tableClassName,
    tableOnClick,
    subtotal,
    fullCart,
    dataMainDelivery,
    addItemsCart,
    title,
    showPrice,
    campaigns,
    handleToggleColumn,
    disableSelectDate,
    convertSubtotalToEuroFormat,
}) => {
    const { draftCartList } = useContext(Context);

    let isSomeAmountChange = draftCartList.some(
        (item, index) => item?.amount !== dataMainDelivery[index]?.amount
    );

    const addItemsToCart = async () => {
        const formattedDraftCartList = draftCartList.map((cartItem) => ({
            productId: cartItem.product.id,
            amount: cartItem.amount,
        }));

        addItemsCart({
            formattedDraftCartList,
            isSomeAmountChange,
        });
    };

    const getTitle = (status) => {
        switch (status) {
            case 'P':
                let articlesNr = '';
                if (basketItems?.length) {
                    for (const item of basketItems) {
                        if (!item?.exported) {
                            articlesNr += `${item?.product?.article_nr} `;
                        }
                    }
                }

                return (
                    <FormattedHtmlMessage
                        id="cart.part_order.placed_success"
                        values={{ articlesNr: articlesNr }}
                    />
                );
            case 'D':
                return (
                    <FormattedHtmlMessage
                        id="cart.part_delivery_order.placed_success"
                        values={{
                            deliveryDate: moment(fullCart.delivery_date).format(
                                'DD/MM/YYYY'
                            ),
                        }}
                    />
                );
            case 'E':
                return (
                    <FormattedHtmlMessage id="cart.system_error_order.placed_success" />
                );
            case 'F':
                return (
                    <FormattedHtmlMessage id="cart.fatal_error_order.placed_success" />
                );
            default:
                return <FormattedHtmlMessage id="cart.order.placed_success" />;
        }
    };

    const getSmallText = (status) => {
        switch (status) {
            case 'P':
                return (
                    <FormattedHtmlMessage id="cart.part_order.delivery_url" />
                );
            case 'D':
                return '';
            case 'E':
                return (
                    <FormattedHtmlMessage id="cart.system_error_order.delivery_url" />
                );
            case 'F':
                return (
                    <FormattedHtmlMessage id="cart.fatal_error_order.delivery_url" />
                );
            default:
                return (
                    <Link to="/deliveries">
                        <FormattedHtmlMessage id="cart.order.delivery_url" />
                    </Link>
                );
        }
    };

    const getIcon = (status) => {
        switch (status) {
            case 'E':
                return frontHand;
            case 'P':
            case 'D':
            case 'F':
                return pendingActions;
            default:
                return checkWhite;
        }
    };

    let isFreeDelivery = fullCart?.transport_cost === 0;

    const getCampaignsStat = () => {
        let points = 0;
        let openPoints = 0;
        let basketPoints = 0;

        if (campaigns && campaigns.data) {
            for (const campaign of campaigns.data) {
                points += campaign.campaign_points[0].points ?? 0;
                openPoints += campaign.campaign_points[0].open_points ?? 0;

                data.forEach((product) => {
                    const p = product.campaign_new_points[campaign.id];
                    if (p) {
                        basketPoints += p;
                    }
                });
            }
        }

        return { points, openPoints, basketPoints };
    };

    const isExistActiveCampaigns = () => {
        return !!(
            campaigns &&
            campaigns.data &&
            campaigns.data.filter((campaign) => campaign.is_active).length
        );
    };

    return (
        <div
            className={`cart cart-${id} animated ${
                animateRemove ? 'zoomOut' : ''
            }`}
        >
            {deleteLoading && (
                <div className="cart__loader--full">
                    <Spinner
                        height={50}
                        width={50}
                        className="spinner--brand"
                    />
                </div>
            )}
            <div
                className="cart__header-wrapper"
                style={{ display: 'flex', gap: 45 }}
            >
                {showHeader && (
                    <div className="cart__header">
                        <span className="cart__order-date">
                            {title}
                            {updateDateCartLoading ? (
                                <Spinner
                                    height={20}
                                    width={20}
                                    className="brand-color"
                                />
                            ) : disableSelectDate ? (
                                <span className="cart__change-date">
                                    {date}
                                </span>
                            ) : (
                                <span
                                    onClick={openDeliveryDatepicker}
                                    className="cart__change-date cart__change-date__date"
                                >
                                    {date}
                                </span>
                            )}
                        </span>
                    </div>
                )}
                {showOrderNumberInput &&
                    userDetails &&
                    userDetails?.data?.username && <OrderNumberInput />}
            </div>

            {!showHeader && !showFooter && (
                <PreferencesPhoto
                    columns={columns}
                    handleToggleColumn={handleToggleColumn}
                />
            )}

            <Table
                data={data}
                columns={columns}
                filterable={false}
                pageSize={data.length > 0 ? data.length : 3}
                itemsCount={data.length > 0 ? data.length : 3}
                withStickyHeader={false}
                showPaginationBottom={false}
                showPageSizeOptions={false}
                loading={fetchingActiveLoading}
                hideHead={hideHead}
                hideBody={hideBody}
                className={tableClassName}
                onClick={tableOnClick}
                showPrice={showPrice}
                responsibility={true}
                responsibilityMaxWidth={1310}
            />

            {subtotal && showPrice && (
                <p className="cart__subtotal">
                    Subtotal: € {convertSubtotalToEuroFormat(subtotal)}
                </p>
            )}
            <div className="cart__price-description">
                <FormattedHtmlMessage id="cart.price_description" />
            </div>
            {showFooter && fullCart && (
                <div className="cart__footer">
                    {isExistActiveCampaigns() && (
                        <div className="cart-campaign-title">
                            <FormattedMessage
                                id="cart.campaign.title"
                                values={getCampaignsStat()}
                            />
                        </div>
                    )}
                    <div className="cart__footer__not-container">
                        <div
                            className={`cart__footer__notification ${
                                isFreeDelivery
                                    ? 'cart__footer__notification--green'
                                    : 'cart__footer__notification--red'
                            }`}
                        >
                            <img
                                src={isFreeDelivery ? checkPoint : alertPoint}
                            />
                            {showPrice ? (
                                <div className="cart__footer__notification__texts">
                                    <p className="cart__footer__notification__texts__hold_cost">
                                        <FormattedHtmlMessage
                                            id="cart.hight_banner_text"
                                            values={{
                                                transport_hold_cost:
                                                    fullCart.transport_hold_cost,
                                            }}
                                        />
                                    </p>
                                    <p className="cart__footer__notification__texts__date">
                                        <FormattedHtmlMessage
                                            id="cart.banner_text"
                                            values={{
                                                price: fullCart.transport_cost,
                                                date: moment(
                                                    fullCart.delivery_date
                                                ).format('DD/MM/YYYY'),
                                            }}
                                        />
                                    </p>
                                    <p className="cart__footer__notification__texts__total">
                                        <FormattedHtmlMessage
                                            id="cart.huidig_totaal"
                                            values={{
                                                fullPrice:
                                                    fullCart.total_cost_with_drop_cost_per_delivery_date,
                                                totalPrice:
                                                    fullCart.total_cost_per_delivery_date,
                                                price: fullCart.sub_total_drop_cost_by_date,
                                            }}
                                        />
                                    </p>
                                </div>
                            ) : (
                                <div>
                                    <p className="banner-text-no-price">
                                        {isFreeDelivery ? (
                                            <FormattedHtmlMessage
                                                id="cart.banner_text_no_price_green"
                                                values={{
                                                    price: fullCart.transport_hold_cost,
                                                }}
                                            />
                                        ) : (
                                            <FormattedHtmlMessage
                                                id="cart.banner_text_no_price_red"
                                                values={{
                                                    price: fullCart.transport_hold_cost,
                                                }}
                                            />
                                        )}
                                    </p>
                                </div>
                            )}
                        </div>
                        {data.length > 0 && (
                            <div className={`cart__footer__controls`}>
                                <div>
                                    <span
                                        onClick={handleRemoveAnimation}
                                        className="cart__footer__controls__cart-remove"
                                    >
                                        <FormattedMessage id="cart.cart_remove" />
                                        <img
                                            src={trashGrey}
                                            alt="remove cart"
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`cart__footer__controls__btn-wrapper`}
                                    style={{ display: 'flex' }}
                                >
                                    <ContinueOrderButton />
                                    <div
                                        className={` ${
                                            isSomeAmountChange
                                                ? 'cart__footer__controls--grey'
                                                : isFreeDelivery
                                                ? 'cart__footer__controls--green'
                                                : 'cart__footer__controls--red'
                                        }`}
                                    >
                                        {isSomeAmountChange ? (
                                            <UpdateOrderButton
                                                onClick={addItemsToCart}
                                                date={date}
                                                deliveryDates={deliveryDates}
                                                loading={
                                                    updateAmountOfItemLoading
                                                }
                                            />
                                        ) : (
                                            <OrderButton
                                                onClick={makeOrder}
                                                date={date}
                                                deliveryDates={deliveryDates}
                                                loading={completeOrderLoading}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <NotificationContainer
                        status={status}
                        icon={getIcon(exportedStatus)}
                        type="dialog"
                        title={
                            status === 'error' ? (
                                <FormattedHtmlMessage id="error" />
                            ) : (
                                getTitle(exportedStatus)
                            )
                        }
                        text={
                            status === 'error' ? (
                                <FormattedHtmlMessage id="error_call" />
                            ) : (
                                getSmallText(exportedStatus)
                            )
                        }
                        show={showDialog}
                        handleClose={handleCloseNotification}
                    />
                </div>
            )}
        </div>
    );
};

Cart.propTypes = {
    id: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    date: PropTypes.string.isRequired,
    tableClassName: PropTypes.string,
    tableOnClick: PropTypes.func,
    status: PropTypes.oneOf(['success', 'error', 'warning']).isRequired,
    exportedStatus: PropTypes.oneOf(['S', 'P', 'D', 'E', 'F']),
    basketItems: PropTypes.array,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    showDialog: PropTypes.bool.isRequired,
    animateRemove: PropTypes.bool.isRequired,
    handleCartRemove: PropTypes.func.isRequired,
    handleRemoveAnimation: PropTypes.func.isRequired,
    makeOrder: PropTypes.func.isRequired,
    handleCloseNotification: PropTypes.func.isRequired,
    hideHead: PropTypes.bool,
    hideBody: PropTypes.bool,
    subtotal: PropTypes.string,
    fullCart: PropTypes.object,
};
const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                addItemsCart,
            },
            dispatch
        ),
    };
};

const mapStateToProps = (s) => {
    return s;
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Cart));
