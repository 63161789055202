import routineReducerFactory from "./routineReducerFactory";
import {
    fetchReadNotifications,
    fetchUnreadNotifications,
    fetchUnreadNormalNotifications,
    fetchUnreadServiceNotifications,
    fetchUserData,
    setAsReadNotifications
} from '../actions/routines';

export const readNotifications = routineReducerFactory(fetchReadNotifications, {data: []});
export const unreadNotifications = routineReducerFactory(fetchUnreadNotifications, {data: []});
export const unreadNormalNotifications = routineReducerFactory(fetchUnreadNormalNotifications, {data: []});
export const unreadServiceNotifications = routineReducerFactory(fetchUnreadServiceNotifications, {data: []});

export const notifications = (state = {total: 0}, action) => {
    switch (action.type) {
        case setAsReadNotifications.SUCCESS:
            return {
                ...state,
                total: --state.total
            };
        case fetchUserData.SUCCESS:
            return {
                ...state,
                total: action.payload.user_notifications_count_not_read
            };
        default:
            return state;
    }
};
