import PropTypes from 'prop-types';
import React from 'react';
import MyOrderList from './MyOrderList';
import { connect } from 'react-redux';
import { removeFromAssortmentPromiseCreator } from '../../../../actions/routines';
import * as qs from 'qs';
import history from '../../../../history';
import { bindPromiseCreators } from 'redux-saga-routines';

class MyOrderListContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            text: this.props.text,
        };
    }

    handleRemove = () => {
        const { removeFromAssortmentPromiseCreator, id, callback } = this.props;
        const queryParams = qs.parse(history.location.search.slice(1));

        this.setState({
            loading: true,
        });

        removeFromAssortmentPromiseCreator({
            id: id,
            params: queryParams,
        }).then(() => {
            this.setState({
                loading: false,
            });
        });

        if (callback) {
            callback();
        }
    };

    render() {
        return (
            <MyOrderList
                handleRemove={this.handleRemove}
                text={this.state.text}
                isLoading={this.state.loading}
            />
        );
    }
}

MyOrderListContainer.propTypes = {
    callback: PropTypes.func,
    id: PropTypes.number.isRequired,
    removeFromAssortment: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindPromiseCreators(
            {
                removeFromAssortmentPromiseCreator,
            },
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyOrderListContainer);
