import {
    deleteCart,
    fetchActiveCartData,
    addItemCart,
    addItemsCart,
    removeItemCart,
    createOrUpdateActiveCart,
    completeCart,
} from '../actions/routines';
import { call, put } from 'redux-saga/effects';
import Cart from '../services/api/Cart';

export function* fetchActiveCartSaga() {
    try {
        yield put(fetchActiveCartData.request());

        const response = yield call(Cart.getActive);

        yield put(
            fetchActiveCartData.success({
                data: response.data,
                transportCostAmount: response.headers['x-transport-costs'],
            })
        );
    } catch (error) {
        yield put(fetchActiveCartData.failure(error.message));
    } finally {
        yield put(fetchActiveCartData.fulfill());
    }
}

export function* deleteCartSaga(action) {
    try {
        yield put(deleteCart.request());

        const response = yield call(Cart.delete, action.payload);

        yield put(deleteCart.success(response.data));
    } catch (error) {
        yield put(deleteCart.failure(error.message));
    } finally {
        yield put(deleteCart.fulfill());
    }
}

export function* addProductToCartSaga(action) {
    try {
        yield put(addItemCart.request());

        const response = yield call(Cart.addProduct, action.payload);

        yield put(addItemCart.success(response.data));
    } catch (error) {
        yield put(addItemCart.failure(error.message));
    } finally {
        yield put(addItemCart.fulfill());
    }
}

export function* addProductsToCartSaga(action) {
    try {
        yield put(addItemsCart.request());

        const response = yield call(Cart.addProducts, action.payload);

        yield put(addItemsCart.success(response.data));
    } catch (error) {
        yield put(addItemsCart.failure(error.message));
    } finally {
        yield put(addItemsCart.fulfill());
    }
}

export function* removeProductFromCartSaga(action) {
    try {
        yield put(removeItemCart.request());

        const response = yield call(Cart.removeProduct, action.payload);

        yield put(removeItemCart.success(response.data));
    } catch (error) {
        yield put(removeItemCart.failure(error.message));
    } finally {
        yield put(removeItemCart.fulfill());
    }
}

export function* createOrUpdateActiveCartSaga(action) {
    try {
        yield put(createOrUpdateActiveCart.request());

        const response = yield call(Cart.createOrUpdateActive, action.payload);

        yield put(createOrUpdateActiveCart.success(response.data));
    } catch (error) {
        yield put(createOrUpdateActiveCart.failure(error.message));
    } finally {
        yield put(createOrUpdateActiveCart.fulfill());
    }
}

export function* completeCartSaga(action) {
    try {
        yield put(completeCart.request());

        const response = yield call(Cart.complete, action.payload);

        yield put(completeCart.success(response.data));
    } catch (error) {
        yield put(completeCart.failure(error.message));
    } finally {
        yield put(completeCart.fulfill());
    }
}
