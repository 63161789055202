import { fetchUserData } from '../actions/routines';
import {call, put} from "redux-saga/effects";
import User from "../services/api/User";
import moment from "moment";
import {setDeliveryDate} from "../actions";

export function* fetchUserDetailsSaga() {
    try {
        yield put(fetchUserData.request());

        const response = yield call(User.getDetails);
        if (response.data.activeBasket) {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            const deliveryDate = !isSafari ? moment(response.data.activeBasket.delivery_date) : moment(response.data.activeBasket.delivery_date).add(1, 'day');
            
            yield put(setDeliveryDate(deliveryDate))
        }

        yield put(fetchUserData.success(response.data));

    } catch (error) {
        yield put(fetchUserData.failure(error.message));
    } finally {
        yield put(fetchUserData.fulfill());
    }
}
