import React from 'react';
import PopoverBadge from "../Table/components/PopoverBadge";
import PropTypes from "prop-types";
import moment from "moment";
import {connect} from "react-redux";

class PromotionTooltip extends React.Component {
    render() {
        const {id, wrapperClassName, promotions} = this.props;

        if (!promotions) {
            return '';
        }

        return (
            promotions.map(promotion=> promotion.promotion && <PopoverBadge
                id={id}
                type="promotion"
                badgeText={`i`}
                backgroundColor={promotion.is_future ? 'grey' : promotion.promotion_type === 1 ? '#FFDE00' : '#D70000'}
                fontColor={promotion.promotion_type === 1 ? 'darkblue' : 'white' }
                popoverHeader={promotion.promotion}
                popoverBody={`
                    ${promotion.description}
                    <br> <small>${moment(promotion.start_date).format('DD/MM/YYYY')} - ${moment(promotion.end_date).format('DD/MM/YYYY')}</small>
                `}
                wrapperClassName={wrapperClassName}
            />                
            )
        )
    }
}

PromotionTooltip.propTypes = {
  deliveryDate: PropTypes.any,
  forceSearch: PropTypes.bool,
  id: PropTypes.number,
  promotions: PropTypes.array,
  promotion: PropTypes.object,
  wrapperClassName: PropTypes.string
}

PromotionTooltip.defaultProps = {
    forceSearch: true
}

const mapStateToProps = state => {
    return {
        deliveryDate: state.deliveryDatepicker.deliveryDate,
        lang: state.locale.lang
    }
};

export default connect(mapStateToProps, null)(PromotionTooltip);