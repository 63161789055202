import * as nlAllergens from '../assets/images/allergens/nl';
import * as frAllergens from '../assets/images/allergens/fr';

/**
 * IMPORTANT NOTICE: SVG files need to have attribute data-name that match the same name as is in product.allergens (Entity coming from backend)
 * Based on this is checked if allergens is in product.
 *
 * @returns {*}
 */
const importAllAllergensIcons = (lang) => {
    const collection = lang === 'nl' ? nlAllergens : frAllergens;

    return Object.keys(collection).map((name) => ({
        name,
        Component: collection[name],
    }));
};

export default importAllAllergensIcons;
