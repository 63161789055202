
const generateRandomNumner = (max, min) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
};

const makeData = (max, withQuantity) => {
    const dataArray = [];

    for(let i = 0; i < max; i++) {
        let campaignType = generateRandomNumner(20, 3);
        let campaign = {
            id: campaignType > 10 ? 1 : 2,
            color: campaignType > 10 ? '#009BD3' : '#EB0EFF',
            points: generateRandomNumner(20, 3),
            maxPoints: campaignType > 10 ? 350 : 200,
            name: campaignType > 10 ? "TV Samsung" : "iPhone",
        };

        let promotionType = generateRandomNumner(20, 3);
        let promotion = {
            id: promotionType > 10 ? 1 : 2,
            type: promotionType > 10 ? 1 : 2,
            title: Math.round(Math.random()) ? '10% KORTING' : 'BIJ AANKOOP VAN 2 DERDE GRATIS',
            text: "gleding tot 12/03/2018"
        };

        let data = {
            id: '020105'+i,
            article_nr: '020105'+i,
            name: Math.round(Math.random()) ? 'KIP MINI\'S 100% 3x500GR IMPERIAL' : 'KIP MINI\'S 100% 3x500GR IMPERIAL EVENTUEEL OVER 2 REGELS',
            supplier: Math.round(Math.random()) ? 'imperial' : 'imperial langer',
            available: '13/04/2018',
            promotion:  generateRandomNumner(20, 1) > 10 ? promotion : {},
            weight: '0.054 Kg',
            quantity: 0,
            campaign: Math.round(Math.random()) ? campaign : {},
            onOrderList: !!Math.round(Math.random())
        };

        if (withQuantity) {
            data.quantity = generateRandomNumner(20, 1);
        }

        dataArray.push(data);
    }

    return dataArray;
};

const randomizeAllergens = () => {
    return Math.round(Math.random()) ? Math.round(Math.random()) ? 'not_contain' : null : Math.round(Math.random()) ? 'contain' : 'can_contain';
};

const makeAllergensData = (max) => {
    const dataArray = [];

    for(let i = 0; i < max; i++) {
        let data = {
            id: '020105'+i,
            productName: Math.round(Math.random()) ? 'KIP MINI\'S 100% 3x500GR IMPERIAL' : 'KIP MINI\'S 100% 3x500GR IMPERIAL EVENTUEEL OVER 2 REGELS',
            date: '13/04/2018',
            isCheckedByFranky: !!Math.round(Math.random()),
            allergens: {
                groundnuts: randomizeAllergens(),
                eggs: randomizeAllergens(),
                gluten: randomizeAllergens(),
                lupine: randomizeAllergens(),
                milk: randomizeAllergens(),
                mustard: randomizeAllergens(),
                nuts: randomizeAllergens(),
                crustaceans: randomizeAllergens(),
                sesame: randomizeAllergens(),
                celery: randomizeAllergens(),
                soy: randomizeAllergens(),
                sulfur: randomizeAllergens(),
                fish: randomizeAllergens(),
                mollusks: randomizeAllergens()
            },
            kcal: 1234,
            kj: 1234
        };

        dataArray.push(data);
    }

    return dataArray;
};

const loremGenerator = (numberOfWords) => {
    const words = [
        'lorem', 'ipsum', 'dolor', 'sit', 'amet', 'consectetur',
        'adipiscing', 'elit', 'curabitur', 'vel', 'hendrerit', 'libero',
        'eleifend', 'blandit', 'nunc', 'ornare', 'odio', 'ut',
        'orci', 'gravida', 'imperdiet', 'nullam', 'purus', 'lacinia',
        'a', 'pretium', 'quis', 'congue', 'praesent', 'sagittis',
        'laoreet', 'auctor', 'mauris', 'non', 'velit', 'eros',
        'dictum', 'proin', 'accumsan', 'sapien', 'nec', 'massa',
        'volutpat', 'venenatis', 'sed', 'eu', 'molestie', 'lacus',
        'quisque', 'porttitor', 'ligula', 'dui', 'mollis', 'tempus',
        'at', 'magna', 'vestibulum', 'turpis', 'ac', 'diam',
        'tincidunt', 'id', 'condimentum', 'enim', 'sodales', 'in',
        'hac', 'habitasse', 'platea', 'dictumst', 'aenean', 'neque',
        'fusce', 'augue', 'leo', 'eget', 'semper', 'mattis',
        'tortor', 'scelerisque', 'nulla', 'interdum', 'tellus', 'malesuada',
        'rhoncus', 'porta', 'sem', 'aliquet', 'et', 'nam',
        'suspendisse', 'potenti', 'vivamus', 'luctus', 'fringilla', 'erat',
        'donec', 'justo', 'vehicula', 'ultricies', 'varius', 'ante',
        'primis', 'faucibus', 'ultrices', 'posuere', 'cubilia', 'curae',
        'etiam', 'cursus', 'aliquam', 'quam', 'dapibus', 'nisl',
        'feugiat', 'egestas', 'class', 'aptent', 'taciti', 'sociosqu',
        'ad', 'litora', 'torquent', 'per', 'conubia', 'nostra',
        'inceptos', 'himenaeos', 'phasellus', 'nibh', 'pulvinar', 'vitae',
        'urna', 'iaculis', 'lobortis', 'nisi', 'viverra', 'arcu',
        'morbi', 'pellentesque', 'metus', 'commodo', 'ut', 'facilisis',
        'felis', 'tristique', 'ullamcorper', 'placerat', 'aenean', 'convallis',
        'sollicitudin', 'integer', 'rutrum', 'duis', 'est', 'etiam',
        'bibendum', 'donec', 'pharetra', 'vulputate', 'maecenas', 'mi',
        'fermentum', 'consequat', 'suscipit', 'aliquam', 'habitant', 'senectus',
        'netus', 'fames', 'quisque', 'euismod', 'curabitur', 'lectus',
        'elementum', 'tempor', 'risus', 'cras'
    ];

    const sentence = [];
    for (let i = 0; i < numberOfWords; i++) {
        const word = words[Math.floor(Math.random() * words.length)];

        sentence.push(word)
    }

    return sentence.join(' ');
};

const makeRecipesData = (max) => {
    const recipes = [];

    for(let i = 0; i < max; i++) {
        const title = loremGenerator(generateRandomNumner(8, 3));
        const imageHeight = generateRandomNumner(240, 130);
        let data = {
            id: i,
            title: title,
            slug: title.replace(/ /g, '-'),
            image: {
                path: "https://loremflickr.com/240/"+ imageHeight +"/food,dish?random="+generateRandomNumner(10, 1),
                width: 240,
                height: imageHeight
            },
            inFavourites: !!Math.round(Math.random())
        };

        recipes.push(data);
    }

    return recipes;
};

function generatetatusWithProbability() {
    const statuses = [
        'pending',
        'planned',
        'partially-delivered',
        'delivered',
        'cancelled'
    ];

    const probability = [0, 1, 2, 3,3,3,3,3,3,3, 4,4,4,4];
    const idx = Math.floor(Math.random() * probability.length);

    return statuses[probability[idx]];
}
const makeDeliveryData = (max) => {
    const delivers = [];


    for(let i = 0; i < max; i++) {
        const products = [];
        for(let j = 0; j < 8; j++) {
            products.push({
                id: '3602121'+j,
                quantity: 45,
                name: "Cervena Rugfilet +/-2K (Striploin) Gekoeld"
            })
        }

        let data = {
            orderNumber:'1234-'+i,
            orderDate: '13/04/2018',
            deliveryDate: '13/04/2018',
            status: generatetatusWithProbability(),
            reference: 'ABCDXYZ',
            documents: {
                pdf: 'http://www.example.com/downloads/pdf',
                excel: 'http://www.example.com/downloads/excel',
                xml: 'http://www.example.com/downloads/xml'
            },
            products: {
                delivered: {
                    deliverAt: '13/04/2018',
                    products: products
                },
                // @TODO F20-110 LEAVE FOR NOW
                // planned: {
                //     deliverAt: '20/04/2018',
                //     products: [
                //         {
                //             id: '3602121',
                //             quantity: 45,
                //             name: "Cervena Rugfilet +/-2K (Striploin) Gekoeld"
                //         }
                //     ]
                // }
            },
            invoice: {
                name: 'FACT. 234-98-98',
                url: 'http://www.example.com/downloads/invoice',
            }
        };

        delivers.push(data);
    }

    return delivers;
};

const makePromoSliderData = (max) => {
    const sliderData = [];

    for(let i = 0; i < max; i++) {

        let promotionType = generateRandomNumner(20, 3);
        let promotion = {
            id: promotionType > 10 ? 1 : 2,
            type: promotionType > 10 ? 1 : 2,
            title: Math.round(Math.random()) ? '10% KORTING' : 'BIJ AANKOOP VAN 2 DERDE GRATIS',
            text: "gleding tot 12/03/2018"
        };

        let data = {
            id: '020105'+i,
            productName: Math.round(Math.random()) ? 'KIP MINI\'S 100% 3x500GR IMPERIAL' : 'KIP MINI\'S 100% 3x500GR IMPERIAL EVENTUEEL OVER 2 REGELS',
            availableFrom: '13/04/2018',
            availableTo: '16/09/2018',
            image: {
                path: "https://loremflickr.com/234/185/food,dish?random="+generateRandomNumner(10, 1),
                width: 240,
                height: 185
            },
            promotion: promotion,
        };

        sliderData.push(data);
    }

    return sliderData
};


const makePromotionData = (max) => {
    const dataArray = [];

    for(let i = 0; i < max; i++) {
        let campaignType = generateRandomNumner(20, 3);
        let campaign = {
            id: campaignType > 10 ? 1 : 2,
            color: campaignType > 10 ? '#009BD3' : '#EB0EFF',
            points: generateRandomNumner(20, 3),
            maxPoints: campaignType > 10 ? 350 : 200,
            name: campaignType > 10 ? "TV Samsung" : "iPhone",
        };

        let numberOfDates = generateRandomNumner(5, 0);

        let availableDates = [];
        for (let j = 0; j <= numberOfDates; ++j) {
            availableDates.push({from: `13/0${++j}/2018`, to: `25/0${j}/2018`})
        }

        let data = {
            id: '020105'+i,
            productName: Math.round(Math.random()) ? 'KIP MINI\'S 100% 3x500GR IMPERIAL' : 'KIP MINI\'S 100% 3x500GR IMPERIAL EVENTUEEL OVER 2 REGELS',
            supplier: Math.round(Math.random()) ? 'imperial' : 'imperial langer',
            availableDates: availableDates,
            packageSize: '0.054 Kg',
            campaign: Math.round(Math.random()) ? campaign : {},
        };

        dataArray.push(data);
    }

    return dataArray;
};


export {makeData, makeAllergensData, makeRecipesData, makeDeliveryData, makePromoSliderData, makePromotionData};