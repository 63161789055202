import { sendRetourForm } from "../actions/routines";

const initialState = {};

const sendRetourFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case sendRetourForm.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case sendRetourForm.SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: "",
            };
        case sendRetourForm.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case sendRetourForm.FULFILL:
            return {
                ...state,
                loading: false,
            };
        case "RESET_RETOUR_ERROR":
            return {
                ...state,
                error: "",
            };
        default:
            return state;
    }
};

export default sendRetourFormReducer;
