import { fetchDeliverySchema, fetchDelivers } from '../actions/routines';
import {call, put} from "redux-saga/effects";
import Delivery from "../services/api/Delivery";

export function* fetchDeliverySchemaSaga() {
    try {
        yield put(fetchDeliverySchema.request());

        const response = yield call(Delivery.getSchema);

        if (typeof response.data === 'object' ) {
            response.data = Object.values(response.data);
        }

        yield put(fetchDeliverySchema.success(response.data));
    } catch (error) {
        yield put(fetchDeliverySchema.failure(error.message));
    } finally {
        yield put(fetchDeliverySchema.fulfill());
    }
}


export function* fetchDeliversSaga(action) {
    try {
        yield put(fetchDelivers.request());

        const response = yield call(Delivery.getHistory, action.payload);

        yield put(fetchDelivers.success({
            data: response.data,
            transportCostAmount: response.headers["x-transport-costs"]
        }));

    } catch (error) {
        yield put(fetchDelivers.failure(error.message));
    } finally {
        yield put(fetchDelivers.fulfill());
    }
}
