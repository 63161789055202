import React from 'react';
import PropTypes from 'prop-types';


const DeleteRow = ({onClick}) => {
    return (
        <span onClick={onClick} className="delete-table-row"/>
    );
};

DeleteRow.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default DeleteRow;
