const routineReducerFactory = (routine, defaultState = {}) => {
    const initialState = {
        data: defaultState.data !== undefined ? defaultState.data : null,
        loading: defaultState.loading !== undefined ? defaultState.loading : true,
        error: defaultState.error !== undefined ? defaultState.error : null,
        loaded: defaultState.loaded !== undefined ? defaultState.loaded : false,
    };

    return (state = initialState, action) => {
        switch (action.type) {
            case routine.TRIGGER:
                return {
                    ...state,
                    loading: true,
                };
            case routine.SUCCESS:
                return {
                    ...state,
                    data: action.payload,
                    loaded: true,
                };
            case routine.FAILURE:
                return {
                    ...state,
                    error: action.payload,
                };
            case routine.FULFILL:
                return {
                    ...state,
                    loading: false,
                };
            default:
                return state;
        }
    };
};

export default routineReducerFactory;