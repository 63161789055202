import PropTypes from 'prop-types'
import React from "react";
import FormattedMessageWithClass from "../../../Utils/FormattedMessageWithClass";

const SectionTitle = ({title}) => {
    return (
        <div className="my-account__section-title">
            <FormattedMessageWithClass className="my-account__section-title__title" id={title}/>
            <hr/>
        </div>
    );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default SectionTitle;
