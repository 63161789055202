const apiErrors = (state = {}, action) => {
    switch (action.type) {
        case 'LOGIN_USER':
            return { ...state, security: '' };
        case 'LOGIN_USER_FAILURE':
            return { ...state, security: action.error };
        case 'NEW_PASSWORD_REQUEST':
            return { ...state, security: ''};
        case 'NEW_PASSWORD_REQUEST_FAILURE':
            return { ...state, security: action.error };
        case 'NEW_PASSWORD_SEND_RESET_TOKEN':
            return { ...state, security: '' };
        case 'NEW_PASSWORD_SEND_FAILURE':
            return { ...state, security: action.error };
        case 'PRODUCTS_FETCHED_FAILURE':
            return { ...state, masterTable: action.error };
        default:
            return state
    }
};

export default apiErrors;