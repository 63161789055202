import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Spinner from './Spinner';
import React from 'react';
import { Button } from 'mdbreact';

const ButtonWithSpinner = ({
    label,
    isLoading,
    width,
    height,
    appendLoader,
    ...props
}) => {
    return (
        <Button {...props}>
            <div>
                {appendLoader ? (
                    <React.Fragment>
                        {isLoading ? (
                            <div className="d-inline-block mr-2">
                                <Spinner
                                    className="spinner--white "
                                    width={width}
                                    height={height}
                                />
                            </div>
                        ) : (
                            <span></span>
                        )}
                        <FormattedMessage id={label} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {!isLoading ? (
                            <FormattedMessage id={label} />
                        ) : (
                            <Spinner
                                className="spinner--white"
                                width={width}
                                height={height}
                            />
                        )}
                    </React.Fragment>
                )}
            </div>
        </Button>
    );
};

ButtonWithSpinner.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoading: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ButtonWithSpinner.defaultProps = {
    height: '0.9rem',
    width: '0.9rem',
    appendLoader: false,
};

export default ButtonWithSpinner;
