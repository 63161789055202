import React from 'react';
import PropTypes from 'prop-types';
import ProductDetails from './ProductDetail';
import { fetchProductData } from '../../actions/routines';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'mdbreact';
import Spinner from '../Utils/Spinner';
import Products from '../../services/api/Products';
import saveFile from '../../services/fileSaver';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import { hideProductDetails } from '../../actions/productDetails';
import * as R from 'ramda';
import { fetchProducts } from '../../actions/masterTable';
import * as qs from 'qs';
import history from '../../history';

class ProductDetailsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inAssortment: null,
        };
    }

    componentDidUpdate(prevProps) {
        const { id, isOpen, deliveryDate, product } = this.props;

        if (!prevProps.isOpen && isOpen && id) {
            this.props.fetchProductData({ id, deliveryDate });
        }

        if (!R.equals(prevProps.product, product)) {
            this.setState({
                inAssortment: product.in_assortment,
            });
        }
    }

    handleDownloadProductSpecificationsPdf = () => {
        const { intl } = this.props;

        Products.getProductSpecificationPdf(this.props.product.id).then(
            (response) => {
                if (response.status === 204) {
                    toast.error(
                        intl.formatMessage({
                            id: 'api.error.file_empty_content',
                        })
                    );

                    return;
                }

                saveFile(
                    response,
                    intl.formatMessage({
                        id: 'product_details.downloads.sheet_file_title',
                    })
                );
            }
        );
    };

    handleRemoveFromAssortment = () => {
        this.setState({
            inAssortment: false,
        });
    };

    handleTagClick = (id) => {
        const params = {
            limit: '50',
            page: '1',
            s_tag: id,
        };

        localStorage.setItem('resetAllergenFilters', '1');

        history.push({
            ...history.location,
            search: qs.stringify(params),
        });

        if (!history.location.pathname.includes('products')) {
            history.push({ pathname: '/products' });
        } else {
            this.props.fetchProducts({ params });
        }

        this.props.hideProductDetails();
    };

    render() {
        const { product, isOpen, hideProductDetails, loading } = this.props;

        return (
            <React.Fragment>
                <Modal
                    isOpen={isOpen}
                    size="lg"
                    className="product-details"
                    backdropClassName="product-details__backdrop"
                    toggle={hideProductDetails}
                >
                    <ModalBody className={loading ? 'modal-body--loading' : ''}>
                        {loading ? (
                            <Spinner
                                className="spinner--brand"
                                width={50}
                                height={50}
                            />
                        ) : (
                            <ProductDetails
                                product={product}
                                isOpen={isOpen}
                                closeModal={hideProductDetails}
                                inAssortment={this.state.inAssortment}
                                handleDownloadProductSpecificationsPdf={
                                    this.handleDownloadProductSpecificationsPdf
                                }
                                handleRemoveFromAssortment={
                                    this.handleRemoveFromAssortment
                                }
                                fetchProducts={this.props.fetchProducts}
                                handleTagClick={this.handleTagClick}
                            />
                        )}
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

ProductDetailsContainer.propTypes = {
    fetchProductData: PropTypes.func,
    fetchProducts: PropTypes.func,
    hideProductDetails: PropTypes.func,
    id: PropTypes.any,
    isOpen: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    product: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        product: state.productDetails.data,
        loading: state.productDetails.loading,
        isOpen: state.productDetailsModal.isOpen,
        id: state.productDetailsModal.id,
        deliveryDate: state.deliveryDatepicker.deliveryDate,
    };
};

const mapDispatchToProps = {
    fetchProductData,
    hideProductDetails,
    fetchProducts,
};

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ProductDetailsContainer)
);
