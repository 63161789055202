import request from './request';

const User = {
    setCurrentCustomer: (customerId) => {
        return request({
            url: `/api/user/active_customer`,
            method: 'PUT',
            data: {
                customerId,
            },
        });
    },
    getDetails: () => {
        return request({
            url: `/api/auth/user`,
            method: 'GET',
        });
    },
};

export default User;
