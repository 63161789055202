import PropTypes from "prop-types";
import React from "react";
import ButtonWithSpinner from "../../../../../../Utils/ButtonWithSpinner";
import Spinner from "../../../../../../Utils/Spinner";

const FilterBox = ({
    name,
    itemsCount,
    loading,
    onSelectFilter,
    downloadPDf,
    isProductCountLoading,
    myList
}) => {
    return (
        <div
            className={`filter-box ${
                myList === 0 || myList === 1 ? "__active" : ""
            }`}
        >
            <p className="filter-box__name">{name}</p>
            <p className="filter-box__items-count">
                {isProductCountLoading ? (
                    <Spinner width={30} height={30} className="brand-color" />
                ) : (
                    itemsCount
                )}
            </p>
            <div>
                {downloadPDf && (
                    <ButtonWithSpinner
                        label="fair.filter_box.pdf"
                        isLoading={loading}
                        className="btn-flat btn-red"
                        onClick={downloadPDf}
                        style={{minWidth: '77px'}}
                    />
                )}
                <ButtonWithSpinner
                    label="fair.filter_box.select_filter"
                    isLoading={loading}
                    className="btn-flat btn-success"
                    onClick={onSelectFilter}
                    style={{minWidth: '77px'}}
                />
            </div>
        </div>
    );
};

FilterBox.propTypes = {
    itemsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    loading: PropTypes.bool,
    name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onSelectFilter: PropTypes.func
};

export default FilterBox;
