(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("react"), require("classnames"), require("react-popper"), require("react-dom"), require("react-transition-group"), require("react-router-dom"), require("react-toastify"), require("material-ui-pickers"), require("@material-ui/core"), require("react-autosuggest"), require("react-numeric-input"), require("outy"), require("@date-io/moment"), require("moment"), require("perfect-scrollbar"), require("raf"));
	else if(typeof define === 'function' && define.amd)
		define("mdbreact", ["prop-types", "react", "classnames", "react-popper", "react-dom", "react-transition-group", "react-router-dom", "react-toastify", "material-ui-pickers", "@material-ui/core", "react-autosuggest", "react-numeric-input", "outy", "@date-io/moment", "moment", "perfect-scrollbar", "raf"], factory);
	else if(typeof exports === 'object')
		exports["mdbreact"] = factory(require("prop-types"), require("react"), require("classnames"), require("react-popper"), require("react-dom"), require("react-transition-group"), require("react-router-dom"), require("react-toastify"), require("material-ui-pickers"), require("@material-ui/core"), require("react-autosuggest"), require("react-numeric-input"), require("outy"), require("@date-io/moment"), require("moment"), require("perfect-scrollbar"), require("raf"));
	else
		root["mdbreact"] = factory(root["PropTypes"], root["React"], root["classnames"], root["react-popper"], root["ReactDOM"], root["reactTransitionGroup"], root["react-router-dom"], root["react-toastify"], root["material-ui-pickers"], root["@material-ui/core"], root["react-autosuggest"], root["react-numeric-input"], root["outy"], root["@date-io/moment"], root["moment"], root["perfect-scrollbar"], root["raf"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__19__, __WEBPACK_EXTERNAL_MODULE__22__, __WEBPACK_EXTERNAL_MODULE__23__, __WEBPACK_EXTERNAL_MODULE__28__, __WEBPACK_EXTERNAL_MODULE__29__, __WEBPACK_EXTERNAL_MODULE__30__, __WEBPACK_EXTERNAL_MODULE__31__, __WEBPACK_EXTERNAL_MODULE__32__, __WEBPACK_EXTERNAL_MODULE__33__, __WEBPACK_EXTERNAL_MODULE__34__) {
return 