import { FormattedMessage as ReactIntlFormattedMessage } from "react-intl";

export const FormattedHtmlMessage = ({values = {}, ...props}) =>
  <ReactIntlFormattedMessage
    values={{
      br: <br/>,
      ...values,
    }}
    {...props}
  />;
