import 'react-app-polyfill/ie11';
import 'core-js';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/nl';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/nl';
import '@formatjs/intl-relativetimeformat/locale-data/fr';

import * as React from 'react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './store';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ErrorHandler from './components/ErrorHandler';
import { Router, Route } from 'react-router-dom';
import history from './history';
import App from './components/App';
import ReduxConnectedIntlProvider from './ReduxConnectedIntlProvider';

import { userLoggedIn } from './actions/security';
import { selectedLocale } from './actions';

const urlLang = window.location.host.split('.')[0];

if (['nl', 'fr'].includes(urlLang)) {
    localStorage.setItem('lang', urlLang);
    store.dispatch(selectedLocale(localStorage.lang));
} else {
    localStorage.setItem('lang', 'nl');
}

if (localStorage.token) {
    const token = { access_token: localStorage.token };
    store.dispatch(userLoggedIn(token));
}

const root = createRoot(document.getElementById('root'));

root.render(
    <ErrorHandler>
        <Provider store={store}>
            <ReduxConnectedIntlProvider>
                <Router history={history}>
                    <Route component={App} />
                </Router>
            </ReduxConnectedIntlProvider>
        </Provider>
    </ErrorHandler>
);

serviceWorkerRegistration.unregister();
