import React from 'react';
import NewPassword from "./../components/NewPassword";
import {connect} from "react-redux";
import {sendNewPasswordAccessTokenPromiseCreator} from "../../../../../actions/routines";
import bindPromiseCreators from "redux-saga-routines/es/bindPromiseCreators";
import history from '../../../../../history';

class NewPasswordAccessTokenContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }

    onSubmit = (password, passwordRepeat) => {

        this.setState({
            isLoading: true
        });

        this.props.sendNewPasswordAccessTokenPromiseCreator({password: password, passwordRepeat: passwordRepeat})
            .then(response => {
                localStorage.removeItem('needPasswordReset');
                history.push('/')
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                });
            })
    };

    render() {
        return <NewPassword email={this.props.email} submit={this.onSubmit} isLoading={this.state.isLoading}/>;
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.userDetails.data ? state.userDetails.data.email : ''
    }
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindPromiseCreators({
            sendNewPasswordAccessTokenPromiseCreator
        }, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordAccessTokenContainer);
