export const TRANSPORT_COST_WARNING_CLOSE = 'TRANSPORT_COST_WARNING_CLOSE';

export const selectedLocale = locale => {
  return {
    type: 'LOCALE_SELECTED',
    locale
  };
};

export const openDeliveryDatepicker = () => ({
    type: 'DELIVERY_DATEPICKER_OPEN',
});

export const closeDeliveryDatepicker = () => ({
    type: 'DELIVERY_DATEPICKER_CLOSE',
});

export const setDeliveryDate = (deliveryDate) => ({
    type: 'DELIVERY_DATE_SET',
    deliveryDate
});

export const closeTransportCostWarning = () => ({
    type: TRANSPORT_COST_WARNING_CLOSE,
});