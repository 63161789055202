import { fetchDownloadCenterFolders, fetchDownloadCenterFiles, searchDownloadCenterFiles } from '../actions/routines';
import {call, put} from "redux-saga/effects";
import DownloadCenter from "../services/api/DownloadCenter";

export function* fetchDownloadCenterFoldersSaga() {
    try {
        yield put(fetchDownloadCenterFolders.request());

        const response = yield call(DownloadCenter.getFolders);

        yield put(fetchDownloadCenterFolders.success(response.data));
    } catch (error) {
        yield put(fetchDownloadCenterFolders.failure(error.message));
    } finally {
        yield put(fetchDownloadCenterFolders.fulfill());
    }
}

export function* fetchDownloadCenterFilesSaga(action) {
    try {
        yield put(fetchDownloadCenterFiles.request());

        const response = yield call(DownloadCenter.getFiles, action.payload);

        yield put(fetchDownloadCenterFiles.success(response.data));
    } catch (error) {
        yield put(fetchDownloadCenterFiles.failure(error.message));
    } finally {
        yield put(fetchDownloadCenterFiles.fulfill());
    }
}

export function* searchDownloadCenterFilesSaga(action) {
    try {
        yield put(searchDownloadCenterFiles.request());

        const response = yield call(DownloadCenter.searchFile, action.payload);

        yield put(searchDownloadCenterFiles.success(response.data));
    } catch (error) {
        yield put(searchDownloadCenterFiles.failure(error.message));
    } finally {
        yield put(searchDownloadCenterFiles.fulfill());
    }
}