import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'mdbreact';
import { FormattedMessage } from 'react-intl';
import ItemVariant from './ItemVariant';
import PointsWithoutLink from '../PointsWithoutLink';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FormattedMessageWithClass from '../../../../Utils/FormattedMessageWithClass';
import campaignLogo from '../../../../../assets/images/campaign.png';

const Item = ({
    id,
    name,
    image,
    pdf,
    activeFrom,
    activeTo,
    variants,
    description,
    userPoints,
    color,
}) => {
    return (
        <div className="campaign__item">
            <div className="campaign__item__container">
                <Col sm="4" className="campaign__item__left-column">
                    {image && (
                        <div className="campaign__item__image-wrapper">
                            <img src={campaignLogo} alt={`${name}`} />
                        </div>
                    )}
                    <h2 className="campaign__item__name">{name}</h2>
                </Col>
                <Col sm="4" className="campaign__item__center">
                    <div className="campaign__item__center__top">
                        <PointsWithoutLink color={color} points={userPoints} />
                        <FormattedMessageWithClass
                            id="campaign.points_add_remark"
                            className="campaign__item__remark"
                        />
                    </div>
                    <div className="campaign__item__bottom-part">
                        <div className="campaign__item__bottom-part__info__top-title">
                            <p className="campaign__item__active-date">
                                <FormattedMessage
                                    id="campaign.active_date"
                                    values={{
                                        activeFrom:
                                            moment(activeFrom).format(
                                                'DD/MM/YYYY'
                                            ),
                                        activeTo:
                                            moment(activeTo).format(
                                                'DD/MM/YYYY'
                                            ),
                                    }}
                                />
                            </p>
                            <p className="campaign__item__description">
                                {description}
                            </p>
                        </div>
                    </div>
                </Col>
                <Col sm="4" className="campaign__item__right-column">
                    {variants.map((variant) => (
                        <ItemVariant
                            key={id}
                            id={id}
                            points={variant.points}
                            name={variant.name}
                            color={color}
                        />
                    ))}
                    <div className="campaign__item__pdf-image-container">
                        <img src={image} alt={`${name}`} />
                    </div>
                    {pdf && (
                        <div className="campaign__item__btn-wrapper">
                            <a
                                href={pdf}
                                className="campaign__item__download-pdf btn-red btn-pdf"
                            >
                                <FormattedMessage id="btn.pdf" />
                            </a>
                        </div>
                    )}
                </Col>
            </div>
            <Link
                to={{
                    pathname: `/products`,
                    search: `?page=1&filtered=1&c_campaigns[]=${id}`,
                    state: { searchCampaign: true },
                }}
            >
                <Button className="btn-gradient">
                    <FormattedMessage id="campaign.see_product_btn" />
                </Button>
            </Link>
        </div>
    );
};

Item.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    activeFrom: PropTypes.string.isRequired,
    activeTo: PropTypes.string.isRequired,
    variants: PropTypes.array.isRequired,
    description: PropTypes.string.isRequired,
    userPoints: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
};

export default Item;
