import React from "react";
import packageJson from "../package.json";
global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB ? versionB.split(/\./g) : '0.0.0'.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

class CacheBuster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            refreshCacheAndReload: () => {
                console.log("Clearing cache and hard reloading...");
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    caches.keys().then(function(names) {
                        for (let name of names) caches.delete(name);
                    });
                }
                // hard reload
                window.location.reload(true);
            }
        };
    }

    componentDidMount() {
        const localStorageVersion = localStorage.getItem("version");
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(
            currentVersion,
            localStorageVersion
        );
        if (shouldForceRefresh) {
            console.log(
                `We have a new version - ${currentVersion}. Should force refresh`
            );
            this.setState({ loading: false });
        } else {
            console.log(
                `You already have the latest version - ${currentVersion}. No cache refresh needed.`
            );
            this.setState({ loading: false });
        }
    }
    render() {
        const { loading, refreshCacheAndReload } = this.state;
        return this.props.children({
            loading,
            refreshCacheAndReload
        });
    }
}

export default CacheBuster;
