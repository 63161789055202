import React from 'react';
import {FormattedMessage} from "react-intl";
import ModalBottom from "./../Modal/Bottom/ModalBottom";
import {Button} from "mdbreact";
import {Link} from "react-router-dom";

class CookieInformation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: !(!!this.getCookie())
        };
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    accept = () => {
        const d = new Date();
        d.setTime(d.getTime() + (6000*24*60*60*1000)); //Expires in 6000 days
        const expires = "expires="+ d.toUTCString();
        document.cookie = `cookieInfo=1;${expires}`;

        this.setState({
            isOpen: false,
        });
    };

    getCookie = () => {
        const match = document.cookie.match(new RegExp('(^| )cookieInfo=([^;]+)'));
        if (match) {
            return match[2];
        }
    };

    render() {
        return (
            <ModalBottom isOpen={this.state.isOpen}>
                <div className="cookie-information">
                    <span>
                    <FormattedMessage id="cookie.text"/>{' '}
                    <Link to="/cookie-policy"><FormattedMessage id="footer.cookie_policy"/></Link>
                    </span>
                    <Button size="md" className="cookie-information__btn btn btn-brand" onClick={this.accept}>
                        <FormattedMessage id="cookie.accept"/>
                    </Button>
                </div>
            </ModalBottom>
        );
    }
}
export default CookieInformation;