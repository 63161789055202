import React from 'react';
import PropTypes from 'prop-types';
import {closeWhite, arrowRightWhite} from '../../../../assets/images/icons';

const Notification = ({text, url, handleAnimationEnd, handleClose, display, show}) => {
    return (
        <div
            onAnimationEnd={handleAnimationEnd}
            className={`dashboard__notification animated ${!display ? 'd-none': ''} ${!show ? 'fadeOutUp': ''}`}
        >
            {url &&
            <a className="dashboard__notification__url" href={url}/>
            }
            <div className="dashboard__notification__close">
                <img onClick={handleClose} src={closeWhite} alt="close notification"/>
            </div>
            <div className="dashboard__notification__body">
                {text}
            </div>

            {url &&
            <div className="dashboard__notification__arrow">
                <img src={arrowRightWhite} alt="notification url"/>
            </div>
            }
        </div>
    );
}

Notification.propTypes = {
    text: PropTypes.string.isRequired,
    url: PropTypes.string,
    display: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    handleAnimationEnd: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default Notification;
