import {
    createOrUpdateActiveCart as createOrUpdateActiveCartRoutine,
    deleteCart as deleteCartRoutine,
    addItemCart as addItemCartRoutine,
    addItemsCart as addItemsCartRoutine,
    removeItemCart as removeItemCartRoutine,
    completeCart as removeCartRoutine,
    fetchActiveCartData,
    fetchUserData,
    fetchFairCarts,
} from '../actions/routines';
import routineReducerFactory from './routineReducerFactory';
import * as R from 'ramda';
import { TRANSPORT_COST_WARNING_CLOSE } from '../actions';

export const activeCart = routineReducerFactory(fetchActiveCartData);
export const createOrUpdateActiveCart = routineReducerFactory(
    createOrUpdateActiveCartRoutine,
    { loading: false }
);
export const deleteCart = routineReducerFactory(deleteCartRoutine, {
    loading: false,
});
export const addItemCart = routineReducerFactory(addItemCartRoutine, {
    loading: false,
});
export const addItemsCart = routineReducerFactory(addItemsCartRoutine, {
    loading: false,
});
export const removeItemCart = routineReducerFactory(removeItemCartRoutine, {
    loading: false,
});
export const completeCart = routineReducerFactory(removeCartRoutine, {
    loading: false,
});

export const cart = (
    state = { data: [], total: 0, transportCostAmount: 0 },
    action
) => {
    switch (action.type) {
        case createOrUpdateActiveCartRoutine.SUCCESS:
            if (action.payload.order_type !== 0) {
                return state;
            }

            return {
                ...state,
                data: action.payload,
                total: !R.isEmpty(action.payload)
                    ? action.payload.basket_items.length
                    : 0,
            };
        case fetchActiveCartData.SUCCESS:
            let cart = action.payload.data;

            return {
                ...state,
                data: cart,
                total: cart.basket_items.length,
                transportCostAmount: action.payload.transportCostAmount,
            };
        case addItemCartRoutine.SUCCESS:
        case addItemsCartRoutine.SUCCESS:
            if (action.payload.order_type !== 0) {
                return state;
            }

            return {
                ...state,
                data: action.payload,
                total: action.payload.basket_items.length,
            };
        case deleteCartRoutine.SUCCESS:
        case removeCartRoutine.SUCCESS: //TODO change this when multiple carts will be implemented
            return {
                ...state,
                data: [],
                total: 0,
            };
        case fetchUserData.SUCCESS:
            const activeBasket = action.payload.activeBasket;

            return {
                ...state,
                data: activeBasket ? activeBasket : [],
                total: activeBasket ? activeBasket.basket_items.length : 0,
            };
        case removeItemCartRoutine.SUCCESS:
            if (action.payload.order_type !== 0) {
                return state;
            }

            return {
                ...state,
                // data: action.payload,
                total: action.payload.basket_items.length,
            };
        case TRANSPORT_COST_WARNING_CLOSE:
            return {
                ...state,
                transportCostAmount: 0,
            };
        default:
            return state;
    }
};

export const fairCart = (state = { data: [] }, action) => {
    switch (action.type) {
        case fetchFairCarts.SUCCESS:
            let carts = action.payload.data;

            return {
                ...state,
                data: carts,
            };
        case addItemCartRoutine.SUCCESS:
            if (action.payload.order_type !== 1) {
                return state;
            }

            return {
                ...state,
                data: state.data.map((c) =>
                    c.id === action.payload.id ? action.payload : c
                ),
            };
        default:
            return state;
    }
};
