import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardTitle } from 'mdbreact';
import QuantityContainer from '../../Quantity/QuantityContainer';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import * as R from 'ramda';
import placeholderImage from '../../../assets/images/image-placeholder.svg';

const ProductBox = ({ product, openProductDetails }) => {
    const {
        id,
        name,
        images,
        items_in_package: itemsInPackage,
        order_as_package: orderAsPackage,
        promotions,
        amount,
    } = product;

    const mainImage = R.filter(R.propEq('image_type', 0))(images);

    return (
        <Card className="product-slider__item-box">
            <div
                onClick={() => openProductDetails(id)}
                className="product-slider__item-box__image-wrapper"
            >
                {mainImage.length > 0 ? (
                    <img
                        src={mainImage[0].url}
                        alt={name}
                        className="img-fluid"
                    />
                ) : (
                    <div className="product-slider__item-box__image-placeholder">
                        <img
                            src={placeholderImage}
                            alt={name}
                            className="img-fluid"
                        />
                    </div>
                )}
            </div>
            <CardBody>
                <div className="product-slider__item-box__body-wrapper">
                    <CardTitle
                        onClick={() => openProductDetails(id)}
                        className="product-slider__item-box__title"
                    >
                        {name}
                    </CardTitle>
                    <QuantityContainer
                        product={product}
                        productId={id}
                        quantity={amount}
                        itemsInPackage={itemsInPackage}
                        orderAsPackage={orderAsPackage}
                        tabIndex={9292929292 + id} //Small hack to omit toplist in tab-ing
                    />
                </div>
                {promotions && promotions.length ? (
                    <div className="product-slider__item-box__promotion">
                        <span className="product-slider__item-box__promotion__text">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: promotions[0].promotion,
                                }}
                            ></span>{' '}
                        </span>
                        <p className="product-slider__item-box__promotion__available-date">
                            <FormattedMessage id="product_slider.item_box.valid_date" />{' '}
                            {moment(promotions[0].start_date).format(
                                'DD/MM/YYYY'
                            )}{' '}
                            -{' '}
                            {moment(promotions[0].end_date).format(
                                'DD/MM/YYYY'
                            )}
                        </p>
                    </div>
                ) : (
                    ''
                )}
            </CardBody>
        </Card>
    );
};

ProductBox.propTypes = {
    openProductDetails: PropTypes.func,
};

export default ProductBox;
