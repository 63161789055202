const security = (state = {}, action) => {
    switch (action.type) {
        case 'USER_LOGGED_IN':
            return action.data;
        case 'USER_LOGGED_OUT':
            return {};
        case 'NEW_PASSWORD_REQUEST':
            return action.login;
        case 'NEW_PASSWORD_SEND_RESET_TOKEN':
            return action.data;
        default:
            return state
    }
};

export default security;