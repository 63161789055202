import PropTypes from 'prop-types'
import React from "react";
import FilterBox from "./components/FilterBox/FilterBox";
import {FormattedMessage} from "react-intl";

const Filters = ({allItemsCount, clientItemsCount, cartItemsCount, onSelectFilter, downloadPDf, isProductCountLoading, myList}) => (
    <React.Fragment>
        <FilterBox
            key="all"
            name={<FormattedMessage id="fair.filters.all_items"/>}
            itemsCount={allItemsCount}
            onSelectFilter={() => onSelectFilter('all')}
            downloadPDf={() => downloadPDf({myList:0})}
            isProductCountLoading={isProductCountLoading}
            myList={myList === 0 && myList}
        />
        <FilterBox
            key="client"
            name={<FormattedMessage id="fair.filters.client_items"/>}
            itemsCount={clientItemsCount}
            onSelectFilter={() => onSelectFilter('client')}
            downloadPDf={() => downloadPDf({myList:1})}
            isProductCountLoading={isProductCountLoading}
            myList={myList === 1 && myList}
        />
        <FilterBox
            key="cart"
            name={<FormattedMessage id="fair.filters.cart_items"/>}
            itemsCount={cartItemsCount}
            onSelectFilter={() => onSelectFilter('cart')}
        />
    </React.Fragment>
);

Filters.propTypes = {
    allItemsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cartItemsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    clientItemsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onSelectFilter: PropTypes.func,
    downloadPDf: PropTypes.func,
    isProductCountLoading: PropTypes.bool
};

export default Filters;