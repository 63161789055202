import React from 'react';
import PropTypes from 'prop-types'

const LanguageSwitcherHeader = ({locale, selectLocale}) => (
    <div className="language-switcher language-switcher--header">
        <span onClick={() => selectLocale('nl')} className={locale !== 'nl' ? 'language-switcher--active': ''}>NL</span>
        |
        <span onClick={() => selectLocale('fr')} className={locale !== 'fr' ? 'language-switcher--active': ''}>FR</span>
    </div>
);

LanguageSwitcherHeader.propTypes = {
    locale: PropTypes.string.isRequired,
    selectLocale: PropTypes.func.isRequired
};

export default LanguageSwitcherHeader;
