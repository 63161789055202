import React from 'react';
import Customers from './Customers';
import { connect } from 'react-redux';
import UserService from '../../services/api/User';
import { bindActionCreators } from 'redux';
import {
    fetchActiveCartData,
    fetchFairCarts,
    fetchUserData,
} from '../../actions/routines';
import PropTypes from 'prop-types';
class CustomersContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentCustomer: null,
        };
    }

    changeCustomerHandler = (customer) => {
        UserService.setCurrentCustomer(customer.id).then((response) => {
            this.setState({
                currentCustomer: customer,
            });

            if (this.props.userDetails) {
                this.props.fetchUserData();
            }

            this.props.fetchActiveCartData();
            //this.props.fetchFairCarts();
        });
    };

    render() {
        const { userDetails } = this.props;

        return (
            <Customers
                userDetails={userDetails}
                currentCustomer={
                    this.state.currentCustomer || userDetails?.current_customer
                }
                customerHandler={this.changeCustomerHandler}
            />
        );
    }
}

CustomersContainer.propTypes = {
    fetchActiveCartData: PropTypes.func,
    fetchFairCarts: PropTypes.func,
    fetchUserData: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchActiveCartData,
                fetchFairCarts,
                fetchUserData,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersContainer);
