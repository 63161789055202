import PropTypes from 'prop-types'
import React from "react";
import {Container, Modal, ModalBody} from "mdbreact";

const ModalBottom = ({ isOpen, backdrop, children, toggle }) => {
    return (
        <Modal isOpen={isOpen} fullHeight position="bottom" backdrop={backdrop} toggle={toggle} className="z-depth-5">
            <ModalBody>
                <Container>
                    {children}
                </Container>
            </ModalBody>
        </Modal>
    );
};

export default ModalBottom;

ModalBottom.propTypes = {
  backdrop: PropTypes.bool,
  children: PropTypes.any,
  isOpen: PropTypes.bool
};

ModalBottom.defaultProps = {
  backdrop: false,
  toggle: () => {}
};