import React from "react";
import store from "../../store";
import {userLoggedIn} from "../../actions/security";
import history from '../../history';

class AuthComponent extends React.Component {

    componentDidMount() {
        const { match: { params: { token, refreshToken } } } = this.props;
        localStorage.token = token;
        localStorage.refreshToken = refreshToken;
        store.dispatch(userLoggedIn({access_token: token}));

        history.push('/dashboard')
    }

    render() {
        return 'Logging in...';
    }
}

export default AuthComponent;
