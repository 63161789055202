import request from './request';

const Ping = {
    ping: () => {
        return request({
            url: `/ping/`,
            method: 'GET'
        });
    },
};

export default Ping;