import PropTypes from 'prop-types';
import React from 'react';
import {
    listGreyIcon,
    listWhiteIcon,
    tileGreyIcon,
    tileWhiteIcon,
} from '../../../assets/images/icons';

const ViewModeButton = ({
    handleViewMode,
    viewMode,
    loading,
    isActive,
    size = 30,
}) => {
    let icon;
    if (viewMode === 'table') {
        icon = isActive ? listGreyIcon : listWhiteIcon;
    } else {
        icon = isActive ? tileGreyIcon : tileWhiteIcon;
    }

    return (
        <a href="#" onClick={() => handleViewMode(viewMode)}>
            <img
                src={icon}
                className="icon"
                alt={viewMode}
                width={size}
                height={30}
            />
        </a>
    );
};

ViewModeButton.propTypes = {
    handleViewMode: PropTypes.func,
    downloadFormat: PropTypes.string,
    loading: PropTypes.bool,
};

export default ViewModeButton;
