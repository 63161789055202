import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from 'mdbreact';
import Spinner from '../../../Utils/Spinner';
import { isDateAvailable } from '../../../../services/deliveryDateTimeBreakChecker';
import moment from 'moment';

const OrderButton = ({ date, deliveryDates, onClick, loading }) => {
    return (
        <Button
            disabled={
                !isDateAvailable(
                    moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    deliveryDates
                )
            }
            onClick={!loading ? onClick : null}
            className="order-button btn-brand btn-brand--with-shadow buttonOrder"
        >
            {loading ? (
                <Spinner className="spinner--white" width={40} height={40} />
            ) : (
                <React.Fragment>
                    <p>
                        <FormattedMessage id="cart.order_btn.make_order" />
                    </p>
                    {/* <p><FormattedMessage id="cart.order_btn.delivery_date" values={{date: date}}/></p> */}
                </React.Fragment>
            )}
        </Button>
    );
};

OrderButton.propTypes = {
    date: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
};

export default OrderButton;
