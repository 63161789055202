import React from "react";
import { bindActionCreators } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Button, Modal, ModalBody, ModalHeader } from "mdbreact";
import swal from "sweetalert2";
import * as R from "ramda";
import * as qs from "qs";
import orderStatus from "../../../../const/orderStatus.json";
import { sendRetourFormPromiseCreator, fetchDelivers } from "../../../../actions/routines";
import RetourForm from "./RetourForm";
import history from "../../../../history";

class Retour extends React.Component {
  state = {
    isOpen: false,
    reason: "",
    reasonContent: "",
    reasonError: false,
    amount: "",
  };

  componentDidUpdate(prevProps) {
    if (!R.equals(prevProps, this.props)) {
      this.showSentRetourConfirmation();
    }
  }

  showSentRetourConfirmation = () => {
    const { retour, intl } = this.props;

    if (retour.loading || !retour.data || retour.error) {
      return;
    }

    swal.fire({
      text: intl.formatMessage({ id: "delivery.details.retour_success" }),
      type: "success",
    });
    this.setState({
      isOpen: false,
    });
  };

  onRetourButtonClick = () => {
    this.props.resetRetourError();
    this.setState({
      isOpen: true,
      reason: "",
      reasonContent: "",
      amount: "",
    });
  };

  toggleRetourModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleUpdateAmount = (amount) => {
    this.setState({
      amount,
    });
  };

  handleUpdateReason = (reason) => {
    this.setState({
      reason,
      reasonError: false,
    });
  };

  handleUpdateReasonContent = (reasonContent) => {
    this.setState({
      reasonContent,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (!this.validateForm(e.target)) {
      return;
    }

    this.sendRetour();
  };

  validateForm = (formHandle) => {
    if (false === formHandle.reportValidity()) {
      return false;
    }

    // reason is a custom dropdown field so custom validation is required
    if (!this.state.reason) {
      this.setState({
        reasonError: true,
      });
      return false;
    }

    return true;
  };

  sendRetour = () => {
    const { orderItem, sendRetourFormPromiseCreator } = this.props;
    const { reason, reasonContent, amount } = this.state;

    let orderNumber = this.props.orderNumber;
    let orderItemNumber = orderItem.item_number;

    sendRetourFormPromiseCreator({orderNumber, orderItemNumber, amount, reason, reasonContent})
      .then(() => {
        this.props.onUpdateOrderStatus(orderNumber, orderStatus.RETOUR);

        const queryParams = qs.parse(history.location.search.slice(1));
        this.props.fetchDelivers(queryParams);
      })
    ;
  }

  render() {
    const { retour, orderItem } = this.props;
    const { isOpen, reason, reasonError, reasonContent, amount } = this.state;

    return (
      <React.Fragment>
        <Button
          type="submit"
          className="btn-brand btn-retour"
          onClick={this.onRetourButtonClick}
        >
          Retour
        </Button>
        <Modal
          isOpen={isOpen}
          toggle={this.toggleRetourModal}
          className="modal-retour"
        >
          <span onClick={this.toggleRetourModal} className="modal-close" />
          <ModalHeader>Retour</ModalHeader>
          <ModalBody>
            {orderItem && (
              <RetourForm
                orderItem={orderItem}
                retour={retour}
                amount={amount}
                reason={reason}
                reasonError={reasonError}
                reasonContent={reasonContent}
                onSubmit={this.handleSubmit}
                onUpdateAmount={this.handleUpdateAmount}
                onUpdateReason={this.handleUpdateReason}
                onUpdateReasonContent={this.handleUpdateReasonContent}
              />
            )}
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ fetchDelivers }, dispatch),
    ...bindPromiseCreators({ sendRetourFormPromiseCreator }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Retour));
