import imagePlaceholder from '../assets/images/image-placeholder.svg'
import FileIcon from "../components/Utils/FileIcon";
import React from "react";

const getIconByFileType = (type) => {
    switch (type) {
        case 'png':
        case 'jpg':
            return {path: imagePlaceholder};
        default:
            return <FileIcon type={type}/>

    }
};

export default getIconByFileType;