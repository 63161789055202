import React from "react";
import PropTypes from "prop-types";

const WeekColumn = ({week_name, quantity, is_current}) => {
    return (
        <div className={`order-history-week ${ is_current ? 'order-history-is-current' : '' }`}>
            <div className="order-history-name">{ week_name }</div>
            <div className="order-history-current">{ is_current && '|' }</div>
            <div className="order-history-quantity">{ quantity || '-' }</div>
        </div>
    );
}

WeekColumn.propTypes = {
    week_name: PropTypes.string,
    quantity: PropTypes.number,
    is_current: PropTypes.bool
};

export default WeekColumn;
