import React from 'react';
import PropTypes from 'prop-types';
import FileBox from "./FileBox";
import saveFile from "../../../../../services/fileSaver";
import DownloadCenter from "../../../../../services/api/DownloadCenter";

class FileBoxContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }

    handleDownload = () => {
        this.setState({
            isLoading: true
        });
        DownloadCenter.getFile(this.props.id).then((data) => {
            saveFile(data, this.props.title)
            this.setState({
                isLoading: false
            });
        })

    };

    render() {
        const {image, imageHover, title, pdfViewLink, text, index, fileImage} = this.props;

        return (
            <FileBox
                index={index}
                image={image}
                fileImage={fileImage}
                imageHover={imageHover}
                title={title}
                pdfViewLink={pdfViewLink}
                text={text}
                isLoading={this.state.isLoading}
                handleDownload={this.handleDownload}
            />
        );
    }
}

FileBoxContainer.propTypes = {
    id: PropTypes.number,
    index: PropTypes.number,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.shape({
        path: PropTypes.string.isRequired,
        height: PropTypes.number,
        width: PropTypes.number
    })]),
    imageHover: PropTypes.shape({
        path: PropTypes.string.isRequired,
        height: PropTypes.number,
        width: PropTypes.number
    }),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    title: PropTypes.string,
    pdfViewLink: PropTypes.string,
};

export default FileBoxContainer;
