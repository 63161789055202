import React from "react";
import Filters from "./Filters";
import history from "../../../../../history";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import FairService from "../../../../../services/api/Fair";
import saveFile from "../../../../../services/fileSaver";

class FiltersContainer extends React.Component {
    state = {
        myList: 1
    };
    onSelectFilter = type => {
        if (type === "cart") {
            history.push("/cart");
        }

        if (type === "all") {
            this.props.handleProductTypeSelect("0");
            this.setState({ myList: 0 });
        }

        if (type === "client") {
            this.props.handleProductTypeSelect("1");
            this.setState({ myList: 1 });
        }
    };

    downloadPDf = params => {
        FairService.getPdf(params).then(data =>
            saveFile(data, `products-list.pdf`)
        );
    };

    render() {
        const {
            countAll,
            countUser,
            isProductCountLoading,
            fairCartProducts
        } = this.props;

        return (
            <Filters
                allItemsCount={countAll}
                clientItemsCount={countUser}
                myList={this.state.myList}
                cartItemsCount={Object.keys(fairCartProducts).length}
                onSelectFilter={this.onSelectFilter}
                downloadPDf={this.downloadPDf}
                isProductCountLoading={isProductCountLoading}
            />
        );
    }
}

FiltersContainer.propTypes = {
    handleProductTypeSelect: PropTypes.func
};

const mapStateToProps = state => {
    return {
        countAll: state.fairProducts.countAll,
        countUser: state.fairProducts.countUser,
        fairCart: state.fairCart.data,
        isProductCountLoading:
            state.fairProducts.loading && state.fairProducts.data.length === 0
    };
};

export default connect(mapStateToProps)(FiltersContainer);
