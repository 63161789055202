import { fetchPromotions } from '../actions/routines';
import { call, put } from 'redux-saga/effects';
import Promotions from '../services/api/Promotions';

export function* fetchPromotionsSaga(action) {
    try {
        yield put(fetchPromotions.request());

        const response = yield call(Promotions.get, action.payload);

        const products = [];

        response.data.forEach((product) => {
            const promotions = product.promotions.filter((e, i) => {
                return (
                    product.promotions.findIndex((x) => {
                        return (
                            x.start_date === e.start_date &&
                            x.end_date === e.end_date &&
                            x.promotion === e.promotion
                        );
                    }) === i
                );
            });

            product.promotions = promotions;

            products.push(product);
        });

        const currentPage = parseInt(response.headers['x-current-page']);

        yield put(
            fetchPromotions.success({
                data: products,
                total: response.headers['x-total-count'],
                hasMore: !!+response.headers['x-has-more'],
                currentPage: currentPage > 0 ? currentPage : 1,
            })
        );
    } catch (error) {
        yield put(fetchPromotions.failure(error.message));
    } finally {
        yield put(fetchPromotions.fulfill());
    }
}
