import PropTypes from 'prop-types'
import React from "react";
import {MDBSpinner} from 'mdbreact';

const Spinner = ({width, height, className}) => {
    return (
        <div style={{width: width, height: height, display: 'inline-flex'}}>
            <MDBSpinner className={`custom-spinner ${className}`}/>
        </div>
    );
};

Spinner.propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Spinner.defaultProps = {
  className: "brand-color",
  height: 50,
  width: 50,
};

export default Spinner;