import PropTypes from 'prop-types'
import React from "react";
import {connect} from "react-redux"
import HeaderFooterRoute from "./HeaderFooterRoute";

const UnprotectedRoute = ({ component: Component, withHeader, withFooter, isAuthenticated,  ...rest }) => {
    if (withHeader || withFooter) {
        return <HeaderFooterRoute component={Component} withFooter={withFooter} withHeader={withHeader} {...rest}/>
    }

    return <Component {...rest} />
};

UnprotectedRoute.propTypes = {
    isAuthenticated: PropTypes.bool,
    withFooter: PropTypes.bool,
    withHeader: PropTypes.bool
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.security.access_token
    }
};

export default connect(mapStateToProps, null)(UnprotectedRoute);