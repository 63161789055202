import React from 'react';
import { callendarGrey } from '../../../../../assets/images/icons';
import { Button, Container } from 'mdbreact';
import DeliveryDatepickerContainer from '../../../../Datepicker/DeliveryDatepickerContainer';
import FormattedMessageWithClass from '../../../../Utils/FormattedMessageWithClass';
import FiltersContainer from '../Filters/FiltersContainer';
import SearchContainer from '../Search/SearchContainer';
import ProductSliderContainer from '../../../../ProductSlider/ProductSliderContainer';
import { FormattedMessage } from 'react-intl';
import DownloadAssortmentButton from '../../../../Table/components/DownloadAssortmentButton';
import ViewModeButton from '../../../../Table/components/ViewModeButton';

const MasterTableHeader = ({
    openDeliveryDatepicker,
    handleDownloadAssortment,
    loadingDownloadAssortment,
    handleDownloadAssortmentExcel,
    loadingDownloadAssortmentExcel,
    handleProductTypeSelect,
    showMyListButton,
    currentViewMode,
    handleViewMode,
    isFairActive,
}) => (
    <Container fluid className="full-width">
        <div className="master-table-header">
            <Container fluid className="row ml-0 pl-0">
                <div className="col">
                    <div className="master-table-header__datepicker-wrapper">
                        <img
                            onClick={openDeliveryDatepicker}
                            src={callendarGrey}
                            className="callendar icon"
                            alt="callendar ico"
                        />
                        <FormattedMessageWithClass
                            className="master-table-header__label"
                            id={'table.delivery_datepicker.label'}
                        />
                        <div className="master-table-header__datepicker">
                            <DeliveryDatepickerContainer />
                        </div>
                    </div>
                </div>
                {/*<div>
                    <ProductSliderContainer />
                </div>*/}
                {/*<div>
                    <SearchContainer withoutCategories={true} />
                </div>
                <div>
                    <FiltersContainer />
                </div>*/}
                <div className="col m-0 pr-0">
                    <div className="row mr-0 alignHeader">
                        <div className="col text-right">
                            {isFairActive && (
                                <div className="ml-4 exhibition-wrapper">
                                    <Button
                                        className="btn btn-red"
                                        onClick={() =>
                                            (document.location = '/fair')
                                        }
                                    >
                                        <FormattedMessage id="menu.exhibition_module" />
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className="text-right productTypeHeader">
                            {handleProductTypeSelect && (
                                <React.Fragment>
                                    {showMyListButton && (
                                        <div className="table__header__actions-col--right text-right">
                                            <div className="download-lbl">
                                                <FormattedMessage id="table.download_assortment.label" />
                                            </div>
                                            <div className="download-btn">
                                                <DownloadAssortmentButton
                                                    handleDownloadAssortment={
                                                        handleDownloadAssortment
                                                    }
                                                    downloadFormat="pdf"
                                                    loading={
                                                        loadingDownloadAssortment
                                                    }
                                                />
                                            </div>
                                            <div className="download-btn">
                                                <DownloadAssortmentButton
                                                    handleDownloadAssortment={
                                                        handleDownloadAssortmentExcel
                                                    }
                                                    downloadFormat="excel"
                                                    loading={
                                                        loadingDownloadAssortmentExcel
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                        {/* TODO get a more meaningful classname here*/}
                        {/*<div className="view-mode-wrapper-wrapper">*/}
                        {/* removed top alignement */}
                        {/* <div className="view-mode-wrapper d-flex align-items-end">
                            <ViewModeButton
                                viewMode="box"
                                isActive={currentViewMode === 'box'}
                                handleViewMode={handleViewMode}
                            />
                            <ViewModeButton
                                viewMode="table"
                                isActive={currentViewMode === 'table'}
                                handleViewMode={handleViewMode}
                                size={26}
                            />
                        </div> */}
                        {/*</div>*/}
                    </div>
                </div>
            </Container>
        </div>
    </Container>
);

export default MasterTableHeader;
