import PropTypes from 'prop-types'
import React from "react";
import ButtonWithSpinner from "../../../../Utils/ButtonWithSpinner";

const DownloadDocuments = ({orderNumber, downloadFile, isDownloadingFile}) => {
    return (
        <div className="delivery__documents">
            <ButtonWithSpinner
                width={11}
                height={11}
                onClick={() => downloadFile('pdf', orderNumber)}
                className="delivery__documents__btn btn btn-red btn-sm btn-flat"
                isLoading={isDownloadingFile.pdf === orderNumber}
                label="PDF"
            />
            <ButtonWithSpinner
                width={11}
                height={11}
                onClick={() => downloadFile('excel', orderNumber)}
                className="delivery__documents__btn btn btn-success btn-sm btn-flat"
                isLoading={isDownloadingFile.excel === orderNumber}
                label="EXCEL"
            />
            <ButtonWithSpinner
                width={11}
                height={11}
                onClick={() => downloadFile('xml', orderNumber)}
                className="delivery__documents__btn btn btn-blue btn-sm btn-flat"
                isLoading={isDownloadingFile.xml === orderNumber}
                label="XML"
            />
        </div>
    );
};

DownloadDocuments.propTypes = {
  downloadFile: PropTypes.func,
  orderNumber: PropTypes.number,
  isDownloadingFile: PropTypes.object
};

export default DownloadDocuments;
