import React from 'react';
import PopoverBadge from '../Table/components/PopoverBadge';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

class CampaignTooltip extends React.Component {
    render() {
        const { id, wrapperClassName, campaigns } = this.props;

        if (!campaigns) {
            return '';
        }

        return campaigns.map(
            (campaign) =>
                campaign.campaign && (
                    <PopoverBadge
                        id={id}
                        type="promotion"
                        badgeText={`i`}
                        backgroundColor={campaign.campaign.color}
                        fontColor={campaign.type === 1 ? 'darkblue' : 'white'}
                        popoverHeader={campaign.points_label}
                        popoverBody={`
                    <br> <small>${moment(campaign.campaign.start_date).format(
                        'DD/MM/YYYY'
                    )} - ${moment(campaign.campaign.end_date).format(
                            'DD/MM/YYYY'
                        )}</small>
                `}
                        wrapperClassName={wrapperClassName}
                    />
                )
        );
    }
}

CampaignTooltip.propTypes = {
    deliveryDate: PropTypes.any,
    forceSearch: PropTypes.bool,
    id: PropTypes.number,
    campaigns: PropTypes.array,
    campaign: PropTypes.object,
    wrapperClassName: PropTypes.string,
};

CampaignTooltip.defaultProps = {
    forceSearch: true,
};

const mapStateToProps = (state) => {
    return {
        deliveryDate: state.deliveryDatepicker.deliveryDate,
        lang: state.locale.lang,
    };
};

export default connect(mapStateToProps, null)(CampaignTooltip);
