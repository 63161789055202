import {fetchRecipeDetails, fetchRecipeListByCategory, fetchFavouritesRecipeList, searchRecipes} from "../actions/routines";
import routineReducerFactory from "./routineReducerFactory";

export const recipeDetails = routineReducerFactory(fetchRecipeDetails, {loading: true});

const initialState = {
    data: [],
    hasMore: true,
    loading: false,
    error: null,
};

export const recipeListByCategory = (state = initialState, action) => {
    switch (action.type) {
        case fetchRecipeListByCategory.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case fetchRecipeListByCategory.SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                hasMore: action.payload.hasMore === 1,
            };
        case fetchRecipeListByCategory.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchRecipeListByCategory.FULFILL:
            return {
                ...state,
                loading: false,
            };
        case 'RESET_HAS_MORE_RECIPE':
            return {
                ...state,
                hasMore: true,
            };
        default:
            return state;
    }
};

export const favouriteRecipesList = (state = initialState, action) => {
    switch (action.type) {
        case fetchFavouritesRecipeList.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case fetchFavouritesRecipeList.SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                hasMore: action.payload.hasMore === 1,
            };
        case fetchFavouritesRecipeList.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchFavouritesRecipeList.FULFILL:
            return {
                ...state,
                loading: false,
            };
        case 'RESET_HAS_MORE_RECIPE':
            return {
                ...state,
                hasMore: true,
            };
        default:
            return state;
    }
};

export const recipeSearch = (state = initialState, action) => {
    switch (action.type) {
        case searchRecipes.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case searchRecipes.SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                hasMore: action.payload.hasMore === 1,
            };
        case searchRecipes.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case searchRecipes.FULFILL:
            return {
                ...state,
                loading: false,
            };
        case 'RESET_HAS_MORE_RECIPE':
            return {
                ...state,
                hasMore: true,
            };
        default:
            return state;
    }
};
