import { sendRetourForm } from '../actions/routines';
import {call, put} from "redux-saga/effects";
import Retour from "../services/api/Retour";

export function* sendRetourFormSaga(action) {
    try {
        yield put(sendRetourForm.request());

        const response = yield call(Retour.sendRetourForm, action.payload);

        yield put(sendRetourForm.success(response.data));
    } catch (error) {
        yield put(sendRetourForm.failure(error.message));
    } finally {
        yield put(sendRetourForm.fulfill());
    }
}