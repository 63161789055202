import request from './request';

const Campaign = {
    get: () => {
        return request({
            url: '/api/campaign/',
            method: 'GET',
        });
    },
};

export default Campaign;