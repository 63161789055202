import React from 'react';

const Campaigns = ({ campaigns }) => (
    <React.Fragment>
        {campaigns.map((campaign) => (
            <div
                className="product-details__campaign"
                style={{ backgroundColor: campaign.campaign.color }}
                dangerouslySetInnerHTML={{
                    __html: campaign.points_label ?? campaign.campaign.name,
                }}
            ></div>
        ))}
    </React.Fragment>
);

export default Campaigns;
