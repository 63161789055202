import React, { Component } from 'react';
import FairWeekSubcomponent from './FairWeekSubcomponent';

class FairWeekSubcomponentContainer extends Component {
    getInputColor = (type) => {
        switch (type) {
            case 'X':
                return 'white';
            case 'S':
                return '#000';
            case 'G':
            case 'NP':
                return '#0d640073';
            case 'B':
                return 'pink';
            default:
                return '#ccc';
        }
    };

    getInputBorderColor = (type) => {
        switch (type) {
            case 'G':
            case 'NP':
                return 'darkgreen';
            default:
                return 'black';
        }
    };

    getInputBorderWidth = (type) => {
        switch (type) {
            case 'G':
                return '2px';
            default:
                return '1px';
        }
    };

    render() {
        const {
            week,
            row,
            fairProducts,
            setFairProducts,
            addProduct,
            lastWeek,
            firstWeek,
            pageSize,
            loadingState,
        } = this.props;
        return (
            <FairWeekSubcomponent
                week={week}
                row={row}
                fairProducts={fairProducts}
                setFairProducts={setFairProducts}
                addProduct={addProduct}
                getInputBorderWidth={this.getInputBorderWidth}
                getInputBorderColor={this.getInputBorderColor}
                getInputColor={this.getInputColor}
                lastWeek={lastWeek}
                firstWeek={firstWeek}
                pageSize={pageSize}
                loadingState={loadingState}
            />
        );
    }
}

export default FairWeekSubcomponentContainer;
