import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeDeliveryData } from '../../Utils/makeFakeData';
import * as qs from 'qs';
import history from '../../../history';
import Invoice from './Invoice';
import { fetchInvoices } from '../../../actions/routines';

class InvoiceContainer extends React.Component {
    componentDidMount() {
        const queryParams = qs.parse(history.location.search.slice(1));

        this.props.fetchInvoices(queryParams);
    }

    render() {
        return <Invoice loading={this.props.loading} data={this.props.data} />;
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.invoices.data,
        loading: state.invoices.loading,
    };
};

export default connect(mapStateToProps, { fetchInvoices })(InvoiceContainer);

InvoiceContainer.propTypes = {
    data: PropTypes.array,
    fetchInvoices: PropTypes.func,
};

InvoiceContainer.defaultProps = {
    data: makeDeliveryData(5),
};
