import React from "react";
import {FormattedHtmlMessage } from '../../Utils/FormattedHtmlMessage';
import Section from "./components/Section";

const data = [
    {
        title: 'disclaimer_page.site_access.title',
        text: 'disclaimer_page.site_access.text'
    },
    {
        title: 'disclaimer_page.site_use.title',
        text: 'disclaimer_page.site_use.text'
    },
    {
        title: 'disclaimer_page.site_safety.title',
        text: 'disclaimer_page.site_safety.text'
    },
    {
        title: 'disclaimer_page.hyperlinks_and_references.title',
        text: 'disclaimer_page.hyperlinks_and_references.text'
    },
    {
        title: 'disclaimer_page.personal_data_protection.title',
        text: 'disclaimer_page.personal_data_protection.text'
    },
    {
        title: 'disclaimer_page.applicable_law.title',
        text: 'disclaimer_page.applicable_law.text'
    }
];

const DisclaimerPage = () => {
    return (
        <div className="disclaimer-page">
            <h1><FormattedHtmlMessage id="disclaimer_page.title"/></h1>
            {data.map(section => (
                <Section title={<FormattedHtmlMessage id={section.title}/>} text={<FormattedHtmlMessage id={section.text}/>}/>
            ))}
        </div>
    );
};

export default DisclaimerPage;
