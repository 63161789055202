export const addFrankyWidget = () => {
    const frankyWidget = document.getElementById('franky-widget');
    const frankyWidgetDiv = document.getElementById('div');

    if (!frankyWidgetDiv && !frankyWidget) {
        const scriptWidget = document.createElement('script');
        scriptWidget.id = 'franky-widget';
        scriptWidget.src =
            'https://f1stageaccountstorage.blob.core.windows.net/wholesalers/franky/widget.js';
        document.head.appendChild(scriptWidget);
    }
};

export const destroyFrankyWidget = () => {
    const frankyWidgetJs = document.getElementById('franky-widget');
    if (frankyWidgetJs) {
        frankyWidgetJs.remove();
    }

    let frankyWidgetDiv = null;
    do {
        frankyWidgetDiv = document.getElementById('div');
        if (frankyWidgetDiv) {
            document.getElementById('div').remove();
        }
    } while (frankyWidgetDiv);
};
