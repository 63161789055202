import { fetchRecipeListByCategory, toggleRecipeFavourites, fetchRecipeDetails, fetchFavouritesRecipeList, searchRecipes} from '../actions/routines';
import {call, put} from "redux-saga/effects";
import Recipe from "../services/api/Recipe";

export function* fetchRecipeDetailsSaga(action) {
    try {
        yield put(fetchRecipeDetails.request());

        const response = yield call(Recipe.getDetails, action.payload);

        yield put(fetchRecipeDetails.success(response.data));
    } catch (error) {
        yield put(fetchRecipeDetails.failure(error.message));
    } finally {
        yield put(fetchRecipeDetails.fulfill());
    }
}

export function* fetchRecipesByCategorySaga(action) {
    try {
        yield put(fetchRecipeListByCategory.request());

        const response = yield call(Recipe.getListByCategory, action.payload);

        yield put(fetchRecipeListByCategory.success({data: response.data, hasMore: JSON.parse(response.headers['x-has-more'])}));
    } catch (error) {
        yield put(fetchRecipeListByCategory.failure(error.message));
    } finally {
        yield put(fetchRecipeListByCategory.fulfill());
    }
}

export function* toggleRecipeFavouritesSaga(action) {
    try {
        yield put(toggleRecipeFavourites.request());

        const response = yield call(Recipe.toggleFavourites, action.payload);

        yield put(toggleRecipeFavourites.success(response.data));
    } catch (error) {
        yield put(toggleRecipeFavourites.failure(error.message));
    } finally {
        yield put(toggleRecipeFavourites.fulfill());
    }
}

export function* fetchFavouriteRecipesSaga(action) {
    try {
        yield put(fetchFavouritesRecipeList.request());

        const response = yield call(Recipe.getFavouritesList, action.payload);

        yield put(fetchFavouritesRecipeList.success({data: response.data, hasMore: JSON.parse(response.headers['x-has-more'])}));
    } catch (error) {
        yield put(fetchFavouritesRecipeList.failure(error.message));
    } finally {
        yield put(fetchFavouritesRecipeList.fulfill());
    }
}

export function* searchRecipesSaga(action) {
    try {
        yield put(searchRecipes.request());

        const response = yield call(Recipe.searchRecipes, action.payload);

        yield put(searchRecipes.success({data: response.data, hasMore: JSON.parse(response.headers['x-has-more'])}));
    } catch (error) {
        yield put(searchRecipes.failure(error.message));
    } finally {
        yield put(searchRecipes.fulfill());
    }
}