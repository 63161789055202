import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

const Currency = ({ value, currency = 'EUR' }) => (
    <FormattedNumber
        value={value}
        /* eslint-disable-next-line react/style-prop-object */
        style="currency"
        currency={currency}
        minimumFractionDigits={0}
        maximumFractionDigits={2}
    />
);

Currency.propTypes = {
    value: PropTypes.number.isRequired,
    currency: PropTypes.oneOf(['EUR']), // for future multi-currency support
};

export default Currency;
