import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";

class InputDefault extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: false,
        };
    }

    onFocus = () => this.setState({ isActive: true });
    onBlur = (e) => {
        if (!e.target.value) {
            this.setState({ isActive: false });
        }
    };

    render() {
        const {
            value,
            type,
            onChange,
            name,
            label,
            placeholder,
            className,
            invalidFeedback,
            onBlur,
            intl,
            disabled,
            required,
            valueToCheck,
            isError,
            max,
            min,
        } = this.props;

        return (
            <div className="form-group input-default" key={label}>
                {label && (
                    <label htmlFor={name}>
                        {label}
                    </label>
                )}
                <input
                    id={name}
                    value={value}
                    onChange={onChange}
                    onBlur={(e) => {
                        if (onBlur) {
                            onBlur(e);
                        }
                        this.onBlur(e);
                    }}
                    onFocus={this.onFocus}
                    type={type}
                    max={max}
                    min={min}
                    name={name}
                    disabled={disabled || false}
                    className={`form-control ${className}`}
                    placeholder={
                        placeholder
                            ? intl.formatMessage({ id: placeholder })
                            : ""
                    }
                    required={required}
                />
                {invalidFeedback && (
                    <div className="invalid-feedback">
                        <FormattedMessage id={invalidFeedback} />
                    </div>
                )}
                {isError && required && !valueToCheck && (
                    <span
                        style={{
                            color: "red",
                            fontSize: "9px",
                        }}
                    >
                        <FormattedMessage id="form.validation.required" />
                    </span>
                )}
            </div>
        );
    }
}

InputDefault.propTypes = {
    className: PropTypes.string,
    intl: PropTypes.object,
    invalidFeedback: PropTypes.string,
    label: PropTypes.node,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.node.isRequired,
    max: PropTypes.number,
    min: PropTypes.number,
};

InputDefault.defaultProps = {
    className: '',
    max: null,
    min: null,
    type: 'text',
};

export default injectIntl(InputDefault);
