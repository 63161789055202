import React from 'react';
import { connect } from 'react-redux';
import TopProduct from './TopProduct';
import PropTypes from 'prop-types';
import { fetchProductTopList } from '../../actions/routines';
import { showProductDetails } from '../../actions/productDetails';
import * as R from 'ramda';
import Spinner from '../Utils/Spinner';
import { toggleProductFavorite } from '../../actions/routines';

class TopProductContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            loading: false,
            windowWidth: 0,
            showItems: 5,
        };
    }
    componentDidMount() {
        this.props.fetchProductTopList();
    }

    componentDidUpdate(prevProps) {
        if (
            (!R.equals(prevProps.products, this.props.products) ||
                this.state.products.length === 0) &&
            this.props.products.length > 0
        ) {
            this.setState({
                products: this.props.products,
            });
        }
    }

    openProductDetails = (id) => {
        const { showProductDetails, deliveryDate } = this.props;

        showProductDetails(id, deliveryDate);
    };

    toggleProductFavorite = (id) => {
        const { toggleProductFavorite } = this.props;

        toggleProductFavorite(id);

        const products = this.state.products.map((product) => {
            if (product.id === id) {
                product.isFavourite = !product.isFavourite;
            }

            return product;
        });

        this.setState({ products });
    };

    render() {
        if (this.props.loading) {
            return (
                <div className="d-flex justify-content-center my-4">
                    <Spinner className="brand-color" />
                </div>
            );
        }

        if (this.props.products.length === 0) {
            return '';
        }

        return (
            <TopProduct
                products={this.state.products}
                openProductDetails={this.openProductDetails}
                toggleProductFavorite={this.toggleProductFavorite}
            />
        );
    }
}

TopProductContainer.protoTypes = {
    fetchProductTopList: PropTypes.func,
    hasMore: PropTypes.bool,
    loading: PropTypes.bool,
};

TopProductContainer.defaultProps = {
    hasMore: true,
};

const mapStateToProps = (state) => {
    return {
        products: state.productTopList.data,
        loading: state.productTopList.loading,
        deliveryDate: state.deliveryDatepicker.deliveryDate,
    };
};

export default connect(mapStateToProps, {
    fetchProductTopList,
    showProductDetails,
    toggleProductFavorite,
})(TopProductContainer);
