import React from 'react';
import RecipesCategories from "./RecipesCategories";
import {connect} from "react-redux";
import {fetchRecipeCategories} from "../../../../actions/routines";

class RecipesCategoriesContainer extends React.Component {
    componentDidMount() {
        this.props.fetchRecipeCategories();
    }

    render() {
        return (
            <RecipesCategories categories={this.props.categories}/>
        );
    }
}
const mapStateToProps = state => {
    return {
        categories: state.recipeCategories.data,
        loading: state.recipeCategories.loading
    }
};

export default connect(mapStateToProps, {fetchRecipeCategories})(RecipesCategoriesContainer);
