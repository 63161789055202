import { fetchProductSuppliers } from '../actions/routines';
import { call, put } from 'redux-saga/effects';
import Product from '../services/api/Products';
import * as R from 'ramda';

export function* fetchProductSuppliersSaga(action) {
    try {
        yield put(fetchProductSuppliers.request());

        const response = yield call(Product.getAllSuppliers, action.payload);

        yield put(fetchProductSuppliers.success(response.data));
    } catch (error) {
        yield put(fetchProductSuppliers.failure(error.message));
    } finally {
        yield put(fetchProductSuppliers.fulfill());
    }
}
