import PropTypes from 'prop-types'
import React from 'react';
import PromotionalModal from "./PromotionalModal";
import {connect} from "react-redux";
import {fetchPromotionalModal} from "../../actions/routines";

class PromotionalModalContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        }

    };

    componentDidMount() {
        this.props.fetchPromotionalModal();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.promotions.length === 0 && this.props.promotions.length > 0) {
            this.setState({
                isOpen: true
            })
        }
    }

    closeModal = () => {
        this.setState({
            isOpen: false
        })
    };


    render() {
        if (this.props.promotions.length === 0) {
            return ''
        }

        return <PromotionalModal promotions={this.props.promotions} isOpen={this.state.isOpen} closeModal={this.closeModal}/>
    }
}

const mapStateToProps = state => {
    return {
        promotions: state.promotionalModal.data,
    }
};

PromotionalModalContainer.propTypes = {
  promotions: PropTypes.array
};

PromotionalModalContainer.defaultProps = {
  promotions: []
};

export default connect(mapStateToProps, {fetchPromotionalModal})(PromotionalModalContainer);
