import PropTypes from 'prop-types'
import React from 'react';
import {connect} from "react-redux";

import FairDynamicWeeksModal from "./FairDynamicWeeksModal";

class FairDynamicWeeksModalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDynamicWeek: null
    };
  }

  handleSelectDynamicWeek = (event) => {
    this.setState({
      selectedDynamicWeek: event[0]
    })
  };

  render() {
    if (this.props.dynamicWeeksOptions.length === 0) {
      return ''
    }

    return <FairDynamicWeeksModal dynamicWeeksOptions={this.props.dynamicWeeksOptions}
                                  isOpen={this.props.isOpen}
                                  closeModal={this.props.closeModal}
                                  addDynamicWeekHandler={this.props.addDynamicWeekHandler}
                                  selectedDynamicWeek={this.state.selectedDynamicWeek}
                                  handleSelectDynamicWeek={this.handleSelectDynamicWeek} />
  }
}

const mapStateToProps = state => {
  const dynamicWeeksData = state.fairDynamicWeeks.data;
  return {
    dynamicWeeksOptions: (dynamicWeeksData && dynamicWeeksData.availableWeeksToSelect) || [],
  }
};

FairDynamicWeeksModalContainer.propTypes = {
  dynamicWeeksOptions: PropTypes.array,
  isOpen: PropTypes.bool,
  addDynamicWeekHandler: PropTypes.func.isRequired,
};

FairDynamicWeeksModalContainer.defaultProps = {
  dynamicWeeksOptions: [],
  isOpen: false,
};

export default connect(mapStateToProps, null)(FairDynamicWeeksModalContainer);
