import request from './request';

const Novelty = {
    getNoveltiesProducts: ({ params }) => {
        return request({
            url: `/api/novelty/`,
            method: 'GET',
            params: params,
        });
    },
};

export default Novelty;
