import React from 'react';
import { injectIntl } from 'react-intl';
import { Input } from 'mdbreact';

class PreferencesPhoto extends React.Component {
    constructor(props) {
        super(props);
    }

    handleFilterClick = (e) => {
        const { handleToggleColumn } = this.props;

        handleToggleColumn(e);
    };

    render() {
        const { columns } = this.props;
        const column = columns.find((c) => c.id === 'productPhoto');

        if (!column) {
            return '';
        }

        return (
            <div className="preferences-photo-wrapper">
                <Input
                    onClick={this.handleFilterClick}
                    name={column.id}
                    checked={column.show}
                    label={column.preferencesLabel}
                    filled
                    type="checkbox"
                    id={column.id}
                    className="my-0"
                />
            </div>
        );
    }
}

export default injectIntl(PreferencesPhoto);
