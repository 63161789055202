import React from 'react';
import { Button, Col, Container, Modal, ModalBody, Row } from 'mdbreact';
import filterGrey from '../../../../../assets/images/filter-grey.svg';
import { FormattedMessage } from 'react-intl';
import AllergenList from './components/AllergenList';
import CheckBoxFilterList from './components/CheckBoxFilterList';
import SelectedFilter from './components/SelectedFilter/SelectedFilter';
import { trashGrey } from '../../../../../assets/images/icons';
import * as R from 'ramda';
import chevronSvg from '../../../../../assets/images/chevron-icon.svg';

const Filters = ({
    campaigns,
    promotions,
    allergens,
    selected,
    selectedPromotions,
    isOpened,
    handleFilterClick,
    handleRemoveFilter,
    handleOpen,
    handleClose,
    clearSectionFilters,
    applyFilters,
    handleResetFilters,
    isPartFilters,
    filterAllergensTitle,
    showAllergens,
    filterCampaignsTitle,
    showCampaigns,
}) => {
    return (
        <div className="filters">
            <div
                className={!isPartFilters ? 'filters__opener' : ''}
                onClick={handleOpen}
            >
                {showCampaigns && (
                    <div className="select-wrapper md-form mb-0">
                        <div className="chevron">
                            <img src={chevronSvg} alt="toggle dropdown" />
                        </div>
                        <input
                            type="text"
                            readOnly=""
                            className="select-dropdown"
                            value={filterCampaignsTitle}
                        />
                    </div>
                )}

                {showAllergens && (
                    <div className="select-wrapper md-form mb-0">
                        <span className="chevron">
                            <img src={chevronSvg} alt="toggle dropdown" />
                        </span>
                        <input
                            type="text"
                            readOnly=""
                            className="select-dropdown"
                            value={filterAllergensTitle}
                        />
                    </div>
                )}

                {!isPartFilters && (
                    <div>
                        <img
                            className="filters__icon"
                            src={filterGrey}
                            alt="filters"
                        />
                        <FormattedMessage id="filters.title" />
                    </div>
                )}
            </div>
            {((showAllergens && selected.allergens.length > 0) ||
                (showCampaigns && selected.campaigns.length > 0) ||
                (selected.promotions.length > 0 && !isPartFilters)) && (
                <div className="filters__selected">
                    {(!isPartFilters || showAllergens) &&
                        selected.allergens.map((filter) => (
                            <SelectedFilter
                                key={filter.id}
                                filter={filter}
                                handleRemoveFilter={handleRemoveFilter}
                            />
                        ))}
                    {(!isPartFilters || showCampaigns) &&
                        selected.campaigns.map((filter) => (
                            <SelectedFilter
                                key={filter.id}
                                filter={filter}
                                handleRemoveFilter={handleRemoveFilter}
                            />
                        ))}
                    {!isPartFilters &&
                        selected.promotions.map((filter) => (
                            <SelectedFilter
                                key={filter.id}
                                filter={filter}
                                handleRemoveFilter={handleRemoveFilter}
                            />
                        ))}
                </div>
            )}
            <Modal isOpen={isOpened} className="filters__modal" size="fluid">
                <Container>
                    <ModalBody>
                        <span
                            onClick={handleClose}
                            className="modal-close modal-close--grey"
                        />

                        {(!isPartFilters || showAllergens) && (
                            <div className="filters__modal__box">
                                <Row>
                                    <Col className="filters__modal__section filters__modal__section__header">
                                        <h2 className="filters__modal__section__title">
                                            <FormattedMessage id="filters.allergens.title" />
                                            <img
                                                src={trashGrey}
                                                onClick={() =>
                                                    clearSectionFilters(
                                                        'allergens'
                                                    )
                                                }
                                                alt="remove filters"
                                                className="filters__modal__section__remove pull-right"
                                            />
                                        </h2>
                                        <small className="filters__modal__section__subtext">
                                            <FormattedMessage id="filters.allergens.subtext" />
                                        </small>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        size="6"
                                        className="filters__modal__section filters__modal__allergens--mandatory"
                                    >
                                        {allergens && allergens.mandatory && (
                                            <AllergenList
                                                allergens={allergens.mandatory}
                                                selected={selected.allergens}
                                                handleFilterClick={
                                                    handleFilterClick
                                                }
                                            />
                                        )}
                                    </Col>
                                    <Col
                                        size="6"
                                        className="filters__modal__section filters__modal__allergens--non-mandatory"
                                    >
                                        <p className="filters__modal__section-title">
                                            <FormattedMessage id="filters.allergens.non_mandatory_list_title" />
                                        </p>
                                        {allergens &&
                                            allergens.nonMandatory &&
                                            R.splitEvery(
                                                8,
                                                allergens.nonMandatory
                                            ).map((allergensGroup, index) => (
                                                <div
                                                    key={index}
                                                    className="filters__modal__allergens--non-mandatory__group"
                                                >
                                                    <CheckBoxFilterList
                                                        type="allergens"
                                                        filters={allergensGroup}
                                                        selected={
                                                            selected.allergens
                                                        }
                                                        onChange={
                                                            handleFilterClick
                                                        }
                                                        translateLabel={true}
                                                    />
                                                </div>
                                            ))}
                                    </Col>
                                </Row>
                            </div>
                        )}

                        <Row>
                            {(!isPartFilters || showCampaigns) && (
                                <Col
                                    size={isPartFilters ? '12' : '6'}
                                    className="filters__modal__box filters__modal__box--with-border"
                                >
                                    <div className="">
                                        <h2 className="filters__modal__section__title">
                                            <FormattedMessage id="filters.campaigns.title" />
                                            <img
                                                src={trashGrey}
                                                onClick={() =>
                                                    clearSectionFilters(
                                                        'campaigns'
                                                    )
                                                }
                                                alt="remove filters"
                                                className="filters__modal__section__remove pull-right"
                                            />
                                        </h2>
                                        <small className="filters__modal__section__subtext">
                                            <FormattedMessage id="filters.campaigns.subtext" />
                                        </small>
                                        <div className="mt-3">
                                            <CheckBoxFilterList
                                                type="campaigns"
                                                filters={campaigns}
                                                selected={selected.campaigns}
                                                onChange={handleFilterClick}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            )}

                            {!isPartFilters && (
                                <Col
                                    size="6"
                                    className="filters__modal__box filters__modal__box--with-border"
                                >
                                    <div>
                                        <h2 className="filters__modal__section__title">
                                            <FormattedMessage id="filters.promotions.title" />
                                            <img
                                                src={trashGrey}
                                                onClick={() =>
                                                    clearSectionFilters(
                                                        'promotions'
                                                    )
                                                }
                                                alt="remove filters"
                                                className="filters__modal__section__remove pull-right"
                                            />
                                        </h2>
                                        <small className="filters__modal__section__subtext">
                                            <FormattedMessage id="filters.promotions.subtext" />
                                        </small>
                                        <div className="d-inline-flex mt-3">
                                            {promotions &&
                                                promotions.map(
                                                    (promotionGroup, index) => (
                                                        <CheckBoxFilterList
                                                            key={index}
                                                            type="promotions"
                                                            filters={
                                                                promotionGroup
                                                            }
                                                            selected={
                                                                selected.promotions
                                                            }
                                                            onChange={
                                                                handleFilterClick
                                                            }
                                                            translateLabel={
                                                                true
                                                            }
                                                        />
                                                    )
                                                )}
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>
                        <Button
                            className="btn-brand btn-flat"
                            onClick={applyFilters}
                        >
                            <FormattedMessage id="filters.apply" />
                        </Button>

                        <Button
                            className="lnk-filter-reset ml-5"
                            onClick={() =>
                                handleResetFilters(showAllergens, showCampaigns)
                            }
                        >
                            <FormattedMessage id="filters.reset" />
                        </Button>
                    </ModalBody>
                </Container>
            </Modal>
        </div>
    );
};

export default Filters;
