import { fetchPromotionalModal } from '../actions/routines';
import {call, put} from "redux-saga/effects";
import Promotions from "../services/api/Promotions";

export function* fetchPromotionalModalSaga() {
    try {
        yield put(fetchPromotionalModal.request());

        const response = yield call(Promotions.getPromotionalModal);

        yield put(fetchPromotionalModal.success(response.data));
    } catch (error) {
        yield put(fetchPromotionalModal.failure(error.message));
    } finally {
        yield put(fetchPromotionalModal.fulfill());
    }
}