import React from 'react';
import PropTypes from 'prop-types';
import DownloadDocuments from './DownloadDocuments';
import InvoiceAPI from '../../../../../services/api/Invoice';
import saveFile from '../../../../../services/fileSaver';
import { connect } from 'react-redux';

class DownloadDocumentsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDownloadingFile: {
                pdf: false,
            },
        };
    }

    downloadFile = (type, invoiceId) => {
        switch (type) {
            case 'pdf':
                this.setState({
                    isDownloadingFile: {
                        ...this.state.isDownloadingFile,
                        ...{ pdf: invoiceId },
                    },
                });
                InvoiceAPI.getPdf(invoiceId).then((data) => {
                    saveFile(data, `invoice-${invoiceId}.pdf`);
                    this.setState({
                        isDownloadingFile: {
                            ...this.state.isDownloadingFile,
                            ...{ pdf: false },
                        },
                    });
                });
                break;
            default:
                break;
        }
    };

    render() {
        const { invoiceId, loading } = this.props;

        if (loading) {
            return null;
        }

        return (
            <DownloadDocuments
                invoiceId={invoiceId}
                downloadFile={this.downloadFile}
                isDownloadingFile={this.state.isDownloadingFile}
            />
        );
    }
}

DownloadDocumentsContainer.propTypes = {
    invoiceId: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        loading: state.invoices.loading,
    };
};

export default connect(mapStateToProps, null)(DownloadDocumentsContainer);
