import PropTypes from 'prop-types'
import React from 'react';
import Footer from "../../../../Footer/Footer";
import {Col, Container, Row} from "mdbreact";
import {FormattedMessage} from "react-intl";
import Header from "../../Header";
import NewPasswordFieldsContainer from "../../../../Security/NewPasswordFields/NewPasswordFieldsContainer";
import NewPasswordFields from "./../NewPasswordFields";

const NewPassword = ({email, submit, errors, isLoading}) => {
    return (
            <div className="security new-password container-fluid">
                <Header/>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="new-password__title"><FormattedMessage id="security.new_password.title"/></h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="4">
                            <p className="new-password__email">{email}</p>
                            <p className="new-password__disclaimer"><FormattedMessage id="security.new_password.disclaimer"/></p>
                            <NewPasswordFieldsContainer submit={submit}>
                                <NewPasswordFields isLoading={isLoading}/>
                            </NewPasswordFieldsContainer>
                            {errors && errors}
                        </Col>
                    </Row>
                </Container>
                <Footer inverted/>
            </div>
    );
};

NewPassword.propTypes = {
  email: PropTypes.string,
  errors: PropTypes.string,
  isLoading: PropTypes.bool,
  submit: PropTypes.func
};

export default NewPassword;
