import request from './request';

const Cart = {
    createOrUpdateActive: (date) => {
        return request({
            url: `/api/basket/active`,
            method: 'POST',
            data: {
                deliveryDate: date,
            },
        });
    },
    getActive: () => {
        return request({
            url: `/api/basket/active`,
            method: 'GET',
        });
    },
    delete: () => {
        return request({
            url: `/api/basket`,
            method: 'DELETE',
        });
    },
    addProduct: ({ productId, amount }) => {
        return request({
            url: `/api/basket/add/${productId}/${Number(amount)}`,
            method: 'PUT',
        });
    },
    addProducts: ({ formattedDraftCartList }) => {
        return request({
            url: `/api/basket/items`,
            method: 'PUT',
            data: formattedDraftCartList,
        });
    },
    removeProduct: ({ productId }) => {
        return Cart.addProduct({ productId, amount: 0 });
    },
    complete: () => {
        return request({
            url: `/api/basket/complete`,
            method: 'PATCH',
            data: {
                customerOrderNumber: sessionStorage.getItem(
                    'customerOrderNumber'
                ),
            },
        });
    },
};

export default Cart;
