/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 *
 */

import axios from 'axios';
import store from '../../store';
import Security from './Security';
import history from '../../history';
import { logoutUser } from '../../actions/security';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import swal from 'sweetalert2';

let isFetchingToken = false,
    subscribers = [];

let refreshTokenInterval;

const logoutWithRedirect = () => {
    localStorage.removeItem('token');
    store.dispatch(logoutUser());
    /*history.push({
        pathname: '/',
    });*/

    document.location = '/';
};

const sendToastMessage = (error) => {
    let message = '';

    //Setup Error Message
    if (typeof error !== 'undefined' && error.hasOwnProperty('message')) {
        message = error.message;
    }

    if (typeof error !== 'undefined' && error.hasOwnProperty('error')) {
        message = error.error;
    }

    if (typeof error.response !== 'undefined') {
        //Setup Generic Response Messages
        if (error.response.status === 404) {
            message = 'API Route is Missing or Undefined';
        } else if (error.response.status === 405) {
            message = 'API Route Method Not Allowed';
        } else if (
            error.response.status === 400 &&
            history.location.pathname !== '/security/password-recovery/request'
        ) {
            message = 'API error';

            if (
                error.hasOwnProperty('response') &&
                error.response.hasOwnProperty('data')
            ) {
                if (error.response.data.error) {
                    message = error.response.data.error_description;
                } else if (error.response.data.includes('confirmation token')) {
                    message = error.response.data;
                }
            }
        } else if (error.response.status >= 500) {
            message = 'Server Error';
        }
        //Try to Use the Response Message
        if (
            error.hasOwnProperty('response') &&
            error.response.hasOwnProperty('data') &&
            error.response.data.hasOwnProperty('message') &&
            error.response.data.message.length > 0
        ) {
            message = error.response.data.message;
        }

        if (
            error.hasOwnProperty('response') &&
            error.response.hasOwnProperty('data') &&
            error.response.data.hasOwnProperty('error') &&
            error.response.data.error === 'invalid_grant'
        ) {
            if (
                error.response.data.error_description ===
                'Refresh token has expired'
            ) {
                message = 'Session has expired. You need log in again';
            }

            if (
                error.response.data.error_description ===
                'Invalid username and password combination'
            ) {
                message = <FormattedMessage id="security.login_error" />;
            }
        }

        error.response.data.error_description = message;
    }

    //Toast the Message
    if (
        message.length > 0 &&
        history.location.pathname !== '/security/password-recovery/request'
    ) {
        if (error?.response?.data?.show_popup) {
            swal.fire({
                text: message,
                type: 'warning',
                confirmButtonText: 'Ok',
            });
        } else {
            toast.error(message);
        }
    }
};

/**
 * Request Wrapper with default success/error actions
 */
const request = (options) => {
    const lang = localStorage.lang
        ? localStorage.lang
        : store.getState().locale.lang;
    const client = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}/${lang}`,
    });

    client.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.token;

    const onAccessTokenFetched = (accessToken) => {
        // eslint-disable-next-line array-callback-return
        subscribers = subscribers.filter((callback) => {
            callback(accessToken);
        });
    };

    client.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const originalRequest = error.config;

            if (error.response.status === 401) {
                if (!localStorage.getItem('refreshToken')) {
                    logoutWithRedirect();
                }

                if (!isFetchingToken) {
                    isFetchingToken = true;
                    const refreshSecurity = function () {
                        Security.refresh()
                            .then((response) => {
                                localStorage.token = response.data.access_token;
                                localStorage.refreshToken =
                                    response.data.refresh_token;
                                localStorage.expiresAt =
                                    response.data.expires_in;
                                isFetchingToken = false;

                                if (refreshTokenInterval) {
                                    clearInterval(refreshTokenInterval);
                                }

                                const expireTimeout =
                                    parseInt(response.data.expires_in) * 1_000;

                                refreshTokenInterval = setTimeout(function () {
                                    console.log('Refresh token');
                                    refreshSecurity();
                                }, expireTimeout);

                                onAccessTokenFetched(
                                    response.data.access_token
                                );
                            })
                            .catch(() => {
                                logoutWithRedirect();
                            });
                    };

                    refreshSecurity();
                }

                return new Promise((resolve) => {
                    subscribers.push((accessToken) => {
                        originalRequest.headers['Authorization'] =
                            'Bearer ' + accessToken;
                        resolve(client(originalRequest));
                    });
                });
            } else {
                sendToastMessage(error);
            }

            return Promise.reject(error);
        }
    );

    return client(options);
};

export default request;
