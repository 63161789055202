import { fetchTagData } from '../actions/routines';
import { call, put } from 'redux-saga/effects';
import Products from '../services/api/Products';

export function* fetchTagSaga(action) {
    try {
        yield put(fetchTagData.request());

        const response = yield call(Products.getTag, action.payload);

        yield put(fetchTagData.success(response.data));
    } catch (error) {
        yield put(fetchTagData.failure(error.message));
    } finally {
        yield put(fetchTagData.fulfill());
    }
}
