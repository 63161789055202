import React from "react";
import {FormattedMessage} from "react-intl";
import CheckboxFilter from "./CheckboxFilter";
import * as R from "ramda";

const AllergenList = ({allergens, selected, handleFilterClick}) => {
    return (
        <div className="filters__modal__allergens-list">
            <p className="filters__modal__section-title"><FormattedMessage id="filters.allergens.list_title"/></p>
            <div className="filters__modal__allergens-list__list">
                {allergens.map(allergen => (
                    <CheckboxFilter
                        id={allergen.id}
                        key={allergen.id}
                        name={allergen.name}
                        label={allergen.icon}
                        type={"allergens"}
                        checked={R.find(R.propEq('id', allergen.id), selected)}
                        onChange={handleFilterClick}
                        imageLabel={true}
                    />
                ))}
            </div>
        </div>
    );
};

export default AllergenList;
