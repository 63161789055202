import React from 'react';
import PropTypes from 'prop-types'
import {Modal, ModalBody, ModalHeader} from "mdbreact";

const Disclaimer = ({title, isOpen, toggle, children}) => (
    <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
            {title}
        </ModalHeader>
        <ModalBody>
            {children}
        </ModalBody>
    </Modal>
);

Disclaimer.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
};

export default Disclaimer;
