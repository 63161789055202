import React from 'react';
import MyAccount from "./MyAccount";
import {fetchUserData, sendNewPasswordAccessTokenPromiseCreator} from "../../../actions/routines";
import {connect} from "react-redux";
import bindPromiseCreators from "redux-saga-routines/es/bindPromiseCreators";
import {bindActionCreators} from "redux";

const fakeData = {
    departments: [
        {
            id: 1,
            name: "Droge voeding",
            password: "voeding@09876"
        },
        {
            id: 2,
            name: "Vlees & vis",
            password: "vleesenvis@678"
        },
        {
            id: 3,
            name: "Brood & banket",
            password: "broodenbanket9087$"
        }
    ]

};
class MyAccountContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isNewPasswordSent: false,
            isNewPasswordLoading: false
        }
    }

    componentDidMount() {
        if (!this.props.data) {
            this.props.fetchUserData();
        }

        this.setState({
            data: fakeData
        })
    }

    onNewPasswordSubmit = (password, passwordRepeat) => {
        this.setState({
            isNewPasswordLoading: true
        });

        this.props.sendNewPasswordAccessTokenPromiseCreator({password, passwordRepeat})
            .finally(() => {
                this.setState({
                    isNewPasswordSent: true,
                    isNewPasswordLoading: false
                });

                setTimeout(() => {
                    this.setState({
                        isNewPasswordSent: false
                    });
                }, 5000)
            })
    };

    render() {
        const {data, loading} = this.props;

        return <MyAccount
            data={data}
            loading={loading}
            departments={fakeData.departments}
            isNewPasswordSent={this.state.isNewPasswordSent}
            isNewPasswordLoading={this.state.isNewPasswordLoading}
            onNewPasswordSubmit={this.onNewPasswordSubmit}
        />;
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.userDetails.data,
        loading: state.userDetails.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({
            fetchUserData
        }, dispatch),
        ...bindPromiseCreators({
            sendNewPasswordAccessTokenPromiseCreator
        }, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountContainer);
