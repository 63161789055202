import request from "./request";

const Retour = {
    sendRetourForm: ({ orderNumber, orderItemNumber, amount, reason, reasonContent }) => {
        return request({
            url: `/api/retour/${orderNumber}/${orderItemNumber}`,
            method: "POST",
            data: {
                amount,
                reason,
                reasonContent,
            },
        });
    },
};

export default Retour;
