import PropTypes from 'prop-types'
import React, {Component} from "react";
import CheckboxFilter from "./CheckboxFilter";
import * as R from "ramda";
import {injectIntl} from "react-intl";

class CheckBoxFilterList extends Component {
    render() {
        const {type, filters, selected, onChange, translateLabel, intl} = this.props;
        return (
            <div>
                {filters.map(filter => (
                    <CheckboxFilter
                        key={filter.id}
                        id={filter.id}
                        name={filter.name}
                        label={translateLabel ? intl.formatMessage({id: `allergens.${filter.name}.full`}) : filter.name}
                        type={type}
                        checked={!!R.find(R.propEq('id', filter.id), selected)}
                        onChange={onChange}
                    />
                ))}
            </div>
        );
    }
}

CheckBoxFilterList.propTypes = {
  filters: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  translateLabel: PropTypes.bool,
  type: PropTypes.string.isRequired
};

CheckBoxFilterList.defaultProps = {
  translateLabel: false
};

export default injectIntl(CheckBoxFilterList);