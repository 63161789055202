import React, { useMemo } from "react";
import { ReactComponent as Icon } from '../../../../assets/images/allergens/nl/almonds.svg';
import importAllAllergensIcons from "../../../../services/allergensIconsImporter";
import { injectIntl } from "react-intl";

const AllergenList = ({ getProperContainInfoClass, intl }) => {

    const images = useMemo(
      () => importAllAllergensIcons(intl.locale),
      [intl.locale]
    )

    return (
        <div className="product-details__allergens__list">
            {images.map(({ name, Component }) => (
                <Component key={name} className={`${getProperContainInfoClass(name)} product-details__allergens__list__allergen`}/>
            ))}
        </div>
    );
}

export default injectIntl(AllergenList);
