import React, {Component} from "react";
import {FormattedMessage} from "react-intl";
import AllergenList from "./AllergenList";
import * as R from "ramda";
import * as PropTypes from "prop-types";

class Allergens extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contains: []
        }
    }

    componentDidMount()
    {
        const {allergens} = this.props;
        const contains = [];

        const findContainingAllergens = (value, key) => {
            if (!['id', 'ingredients', 'has_sheet', 'ingredients', 'verified', 'fiche_date', 'kj', 'kcal'].includes(key)) {
                contains.push({
                    key,
                    value
                });
            }
        };

        R.forEachObjIndexed(findContainingAllergens, allergens);

        this.setState({
            contains
        })

    }

    getProperContainInfoClass = (icon) => {
        if (!this.state.contains.length || !icon) {
            return false;
        }

        const allergenInfo = R.find(R.propEq('key', icon.toLowerCase()))(this.state.contains);

        const value = allergenInfo ? allergenInfo.value : 0;

        switch(value) {
            case 1:
                return "product-details__allergens__list__allergen--not-contain";
            case 2:
                return "product-details__allergens__list__allergen--can-contain";
            case 3:
                return "product-details__allergens__list__allergen--contain";
            default:
                return "product-details__allergens__list__allergen--no-info";
        }
    };

    isGlutenAndLactoseFree = () => {
        const gluten = R.find(R.propEq('key', 'gluten'))(this.state.contains);
        const lactose = R.find(R.propEq('key', 'lactose'))(this.state.contains);

        return gluten && gluten.value === 1 && lactose && lactose.value === 1;
    };

    render() {
        return (
            <React.Fragment>
                {this.isGlutenAndLactoseFree() &&
                <p><FormattedMessage id="product_details.allergens.gluten_and_lactose_free"/></p>
                }

                <div className="product-details__allergens__legend">
                    <span className="product-details__allergens__legend__icon allergens__not-contain"/> <FormattedMessage id="allergens.legend.not_contain"/>
                    <span className="product-details__allergens__legend__icon allergens__can-contain"/> <FormattedMessage id="allergens.legend.can_contain"/>
                    <span className="product-details__allergens__legend__icon allergens__contain"/> <FormattedMessage id="allergens.legend.contain"/>
                </div>

                <AllergenList getProperContainInfoClass={this.getProperContainInfoClass}/>
            </React.Fragment>
        );
    }
}

Allergens.propTypes = {
    allergens: PropTypes.array
};

export default Allergens;
