import React from 'react';
import {FormattedMessage} from "react-intl";
import KSicon from "./../../../../assets/images/keurslager-icon.png";
import {connect} from "react-redux";

class KeurslagerLabel extends React.Component {
    render() {
        const {lang} = this.props;

        return (
            lang == 'fr'
                ? <React.Fragment>
                    <FormattedMessage id={'table.keurslager_list.label'}/> {' '}
                    <img style={{width: "20px", marginTop: "-4px"}} src={KSicon} alt={"KS"}/>
                </React.Fragment>
                : <React.Fragment>
                    <img style={{width: "20px", marginTop: "-4px"}} src={KSicon} alt={"KS"}/> {' '}
                    <FormattedMessage id={'table.keurslager_list.label'}/>
                </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.locale.lang
    }
};

export default connect(mapStateToProps, null)(KeurslagerLabel);
