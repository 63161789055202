import React from 'react';
import PropTypes from 'prop-types';
import DownloadDocuments from "./DownloadDocuments";
import DeliveryAPI from "../../../../../services/api/Delivery";
import saveFile from "../../../../../services/fileSaver";
import {connect} from "react-redux";

class DownloadDocumentsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDownloadingFile: {
                pdf: false,
                excel: false,
                xml: false
            },
        }
    }


    downloadFile = (type, orderNumber) => {
        switch (type) {
            case 'pdf':
                this.setState({
                    isDownloadingFile: {...this.state.isDownloadingFile, ...{pdf: orderNumber}}
                });
                DeliveryAPI.getPdf(orderNumber).then(data => {
                    saveFile(data, `delivery-${orderNumber}.pdf`);
                    this.setState({
                        isDownloadingFile: {...this.state.isDownloadingFile, ...{pdf: false}}
                    });
                });
                break;
            case 'excel':
                this.setState({
                    isDownloadingFile: {...this.state.isDownloadingFile, ...{excel: orderNumber}}
                });
                DeliveryAPI.getExcel(orderNumber).then(data => {
                    saveFile(data, `delivery-${orderNumber}.xls`);
                    this.setState({
                        isDownloadingFile: {...this.state.isDownloadingFile, ...{excel: false}}
                    });
                });
                break;
            case 'xml':
                this.setState({
                    isDownloadingFile: {...this.state.isDownloadingFile, ...{xml: orderNumber}}
                });
                DeliveryAPI.getXml(orderNumber).then(data => {
                    saveFile(data, `delivery-${orderNumber}.xml`);

                    this.setState({
                        isDownloadingFile: {...this.state.isDownloadingFile, ...{xml: false}}
                    });
                });
                break;
            default:
                break;
        }

    };


    render() {
        const {orderNumber, loading} = this.props;

        if (loading) {
            return null
        }

        return <DownloadDocuments
            orderNumber={orderNumber}
            downloadFile={this.downloadFile}
            isDownloadingFile={this.state.isDownloadingFile}
        />;
    }
}

DownloadDocumentsContainer.propTypes = {
    orderNumber: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        loading: state.delivers.loading
    }
};

export default connect(mapStateToProps, null)(DownloadDocumentsContainer);
