import PropTypes from 'prop-types'
import React from 'react';
import {Card, CardBody, CardTitle} from "mdbreact";
import {FormattedMessage} from "react-intl";

const CardPopupList = ({title, description, children, visible, handleClose}) => {
    return (
        <Card className={`card-popup ${visible ? 'show' : ''}`}>
            <span onClick={handleClose} className="card-popup__close"/>
            <CardBody className="card-popup__body">
                {title &&
                <CardTitle className="card-popup__body__title">
                    <FormattedMessage id={title}/>
                </CardTitle>
                }
                {description &&
                    <p className="card-popup__body__description"><FormattedMessage id={description}/></p>
                }
                <ul className="card-popup__body__list">
                    {children}
                </ul>
            </CardBody>
        </Card>
    );
};

CardPopupList.propTypes = {
  children: PropTypes.any,
  description: PropTypes.string,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  visible: PropTypes.bool
};

export default CardPopupList;
