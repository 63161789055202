import { loadingStart, loadingStop } from '../actions/loadingState';
import { call, put } from 'redux-saga/effects';
import Products from '../services/api/Products';
import {
    productsFetched,
    productsFetchedFailure,
} from '../actions/masterTable';
import { toggleProductFavorite } from '../actions/routines';

export function* toggleProductFavouriteSaga(action) {
    try {
        yield put(toggleProductFavorite.request());

        const response = yield call(Products.toggleFavourite, action.payload);

        yield put(toggleProductFavorite.success(response.data));
    } catch (error) {
        yield put(toggleProductFavorite.failure(error.message));
    } finally {
        yield put(toggleProductFavorite.fulfill());
    }
}

export function* fetchProductsSaga(action) {
    yield put(loadingStart());
    try {
        const response = yield call(Products.getProducts, action.data);

        const currentPage = parseInt(response.headers['x-current-page']);

        yield put(
            productsFetched({
                products: response.data,
                total: parseInt(response.headers['x-total-count'] ?? 0),
                myTotal: parseInt(
                    response.headers['x-total-my-products-count'] ?? 0
                ),
                myFavorite: parseInt(
                    response.headers['x-total-my-favorite-count'] ?? 0
                ),
                totalWithPromotionFilter: parseInt(
                    response.headers['x-total-with-promotion-filter-count'] ?? 0
                ),
                totalWithoutPromotionFilter: parseInt(
                    response.headers[
                        'x-total-without-promotion-filter-count'
                    ] ?? 0
                ),
                hasMyList: response.headers['x-has-my-list'],
                hasMore: !!+response.headers['x-has-more'],
                currentPage: currentPage > 0 ? currentPage : 1,
            })
        );
    } catch (err) {
        yield put(productsFetchedFailure(err.message));
    }
    yield put(loadingStop());
}
