import {removeFromAssortment} from '../actions/routines';
import {call, put} from "redux-saga/effects";
import Products from "../services/api/Products";

export function* removeFromAssortmentSaga(action) {
    try {
        yield put(removeFromAssortment.request());

        const response = yield call(Products.removeFromAssortiment, action.payload);

        yield put(removeFromAssortment.success({
            data: response.data,
            total: response.headers["x-total-count"],
            hasMyList: response.headers["x-has-my-list"]
        }));
    } catch (error) {
        yield put(removeFromAssortment.failure(error.message));
    } finally {
        yield put(removeFromAssortment.fulfill());
    }
}
