import React from 'react';
import PropTypes from 'prop-types';
import Inline from "./Inline";
import Dialog from "./Dialog";
import {connect} from "react-redux";
import {setAsReadNotifications} from "../../actions/routines";

class NotificationContainer extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            show: this.props.show,
            display: true, //Triggers display none css when false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState({
                show: this.props.show
            })
        }
    }

    handleClose = () => {
        this.setState({
            show: false
        });

        if (this.props.notificationId !== undefined) {
            this.props.setAsReadNotifications(this.props.notificationId);
        }
    };

    handleAnimationEnd = () => {
        this.setState({
            display: false
        })
    };

    render() {
        const {type, title, text, url, status, icon, handleClose} = this.props;

        if (type === 'inline') {
            return <Inline
                title={title}
                text={text}
                url={url}
                show={this.state.show}
                display={this.state.display}
                onAnimationEnd={this.handleAnimationEnd}
                handleClose={handleClose ? handleClose : this.handleClose}
            />
        }

        return <Dialog
            status={status}
            title={title}
            text={text}
            icon={icon}
            handleClose={handleClose ? handleClose : this.handleClose}
            isOpen={this.state.show}
        />
    }
}

NotificationContainer.propTypes = {
    type: PropTypes.oneOf(['inline', 'dialog']).isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    url: PropTypes.string,
    status: PropTypes.oneOf(['success', 'error', 'warning']),
    handleClose: PropTypes.func,
};

NotificationContainer.defaultProps = {
    type: 'dialog'
};

export default connect(null, {setAsReadNotifications})(NotificationContainer);
