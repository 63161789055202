import React, { Component } from 'react';
import FairCart from './FairCart';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { completeFairCartPromiseCreator } from '../../../../../actions/routines';
import FairService from '../../../../../services/api/Fair';

class FairCartContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fairProducts: [],

            showDialog: false,
            orderStatus: 'success',
        };
    }

    componentWillReceiveProps(nextProps) {
        const { fairData, fairCartProducts } = nextProps;

        const fairProducts = [];

        fairData.map((it) =>
            fairCartProducts[it.product.id] ? fairProducts.push(it) : null
        );

        if (this.props !== nextProps) {
            fairProducts.forEach(
                (item) =>
                    fairCartProducts[item.product.id] &&
                    item.fair_product_promotions.forEach((it) =>
                        fairCartProducts[item.product.id][it.week_date]
                            ? (it.value =
                                  fairCartProducts[item.product.id][
                                      it.week_date
                                  ])
                            : null
                    )
            );
            this.setState({ fairProducts: fairProducts });
        }
    }

    makeOrder = () => {
        this.props
            .completeFairCartPromiseCreator()
            .then((a) => {
                this.setState(
                    {
                        showDialog: true,
                        status: 'success',
                    },
                    () => {
                        FairService.getFairProductsList().then((response) =>
                            this.setState({ fairProducts: response.data })
                        );
                    }
                );
            })
            .catch((a) => {
                this.setState({
                    showDialog: true,
                    status: 'error',
                });
            });
    };

    setFairProducts = (newState) => {
        this.setState({
            fairProducts: newState,
        });
    };

    handleCloseNotification = () => {
        this.setState({
            showDialog: false,
        });
    };

    render() {
        const { loading, fairCartProducts, setFairCartProducts, addProduct } =
            this.props;

        return (
            <FairCart
                loading={loading}
                fairProducts={this.state.fairProducts}
                setFairProducts={this.setFairProducts}
                fairCartProducts={fairCartProducts}
                setFairCartProducts={setFairCartProducts}
                addProduct={addProduct}
                makeOrder={this.makeOrder}
                handleCloseNotification={this.handleCloseNotification}
                orderStatus={this.state.orderStatus}
                showDialog={this.state.showDialog}
                loadingState={this.props.loadingState}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindPromiseCreators(
            {
                completeFairCartPromiseCreator,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FairCartContainer);
