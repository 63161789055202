import Preferences from './Preferences';
import React from 'react';

const TablePreferences = ({ columns, handleToggleColumn }) => {
    return (
        <Preferences
            title="product.search_preferences"
            hideIcon={true}
            isHidden={false}
            columns={columns}
            handleToggleColumn={handleToggleColumn}
        />
    );
};

export default TablePreferences;
