import PropTypes from 'prop-types';
import React from 'react';
import Search from '../../../Search/Search';
import { injectIntl } from 'react-intl';
import { fetchPromotions } from '../../../../actions/routines';
import { connect } from 'react-redux';
import SearchWithFilters from '../../../Search/SearchWithFilters';
import history from '../../../../history';
import * as qs from 'qs';
import * as R from 'ramda';

class SearchContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            commonSearchValue: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!R.equals(prevState, this.state)) {
            const queryParams = qs.parse(history.location.search.slice(1));
            this.search(queryParams);
        }
    }

    intervalHandler = null;
    search = (params) => {
        if (this.intervalHandler) {
            clearInterval(this.intervalHandler);
        }

        this.intervalHandler = setTimeout(() => {
            const searchTerm = this.state.commonSearchValue;
            if (searchTerm) {
                params['s_all'] = searchTerm;
            } else {
                delete params['s_all'];
            }

            params['page'] = 1;

            history.push({
                ...this.props.location,
                search: qs.stringify(params),
            });

            this.props.fetchPromotions(params);
        }, 1_000);
    };

    setCommonSearch = (e) => {
        this.setState({
            commonSearchValue: e.target.value,
        });
    };

    render() {
        const {
            intl,
            withFilters,
            loading,
            withoutCategories,
            withoutFilterCampaigns,
            withoutFilterAllergens,
        } = this.props;

        if (withFilters) {
            return (
                <SearchWithFilters
                    withSelect={!withoutCategories}
                    withoutFilterCampaigns={withoutFilterCampaigns}
                    withoutFilterAllergens={withoutFilterAllergens}
                    loading={loading}
                    commonSearchValue={this.state.commonSearchValue}
                    setCommonSearch={this.setCommonSearch}
                    placeholder={intl.formatMessage({
                        id: 'table.search.placeholder',
                    })}
                    searchHandler={this.search}
                />
            );
        }

        return (
            <Search
                placeholder={intl.formatMessage({
                    id: 'promotions.search.placeholder',
                })}
                label="promotions.search.btn"
                onSubmit={this.search}
            />
        );
    }
}

SearchContainer.propTypes = {
    intl: PropTypes.object.isRequired,
};

export default injectIntl(connect(null, { fetchPromotions })(SearchContainer));
