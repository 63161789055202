import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import InputDefault from "../../../Form/InputDefault";
import moment from "moment";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "mdbreact";

const RetourForm = ({
  intl,
  orderItem,
  retour,
  amount,
  reason,
  reasonError,
  reasonContent,
  onSubmit,
  onUpdateAmount,
  onUpdateReason,
  onUpdateReasonContent,
}) => {
  const reasonItems = [
    intl.formatMessage({ id: "delivery.retour_reasons.damaged" }),
    intl.formatMessage({ id: "delivery.retour_reasons.quality" }),
    intl.formatMessage({ id: "delivery.retour_reasons.ordered_wrong" }),
    intl.formatMessage({ id: "delivery.retour_reasons.shelf_life_too_short" }),
    intl.formatMessage({
      id: "delivery.retour_reasons.wrong_warehouse_or_item",
    }),
  ];

  const updateAmount = (e) => {
    onUpdateAmount(Number(e.target.value));
  };

  return (
    <form onSubmit={onSubmit} className="modal-retour__form">
      <div className="modal-retour__inputs">
        {retour.error && (
          <span className={"modal-retour__span-error"}>
            <FormattedMessage id="form.validation.invalid_fields" />
          </span>
        )}
        <InputDefault
          label={<FormattedMessage id="delivery.details.article_nr" />}
          value={orderItem.product.article_nr || ''}
          disabled={true}
        />
        <InputDefault
          label={<FormattedMessage id="delivery.details.name_product" />}
          value={orderItem.product.name || ''}
          disabled={true}
        />
        <InputDefault
          label={<FormattedMessage id="delivery.details.lot_number" />}
          value={orderItem.parcel_nr || "- - -"}
          disabled={true}
        />
        <InputDefault
          label={<FormattedMessage id="delivery.details.expiry_date" />}
          value={
            orderItem.expiry_date
              ? moment(orderItem.expiry_date).format("DD/MM/YYYY")
              : "- - -"
          }
          disabled={true}
        />
        <InputDefault
          label={
            intl.formatMessage({ id: "delivery.details.amount" }) +
            ` (max. ${orderItem.amount})`
          }
          onChange={updateAmount}
          value={amount}
          required={true}
          valueToCheck={amount}
          isError={retour.error}
          type="number"
          max={orderItem.amount}
          min={1}
        />
        <Dropdown>
          <DropdownToggle nav caret className={"modal-retour__dropdown-reason"}>
            {reason || <FormattedMessage id="delivery.details.reason" />}
          </DropdownToggle>
          <DropdownMenu>
            {reasonItems.map((reason, index) => (
              <DropdownItem
                key={index}
                onClick={() => onUpdateReason(reason)}
              >
                {reason}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        {(retour.error || reasonError) && !reason && (
          <span className={"modal-retour__span-error"}>
            <FormattedMessage id="form.validation.required" />
          </span>
        )}
        {reason && (
          <div>
            <InputDefault
              label=""
              onChange={(e) => onUpdateReasonContent(e.target.value)}
              value={reasonContent}
              isError={retour.error}
              required={true}
            />
          </div>
        )}
      </div>
      <div className="modal-retour__submit">
        <Button
          size="md"
          type="submit"
          className="btn btn-brand modal-retour__btn-submit"
        >
          <FormattedMessage id="btn.send" />
        </Button>
      </div>
    </form>
  );
};

export default injectIntl(RetourForm);
