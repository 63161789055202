import React from 'react';
import PropTypes from 'prop-types'
import {Modal, ModalBody} from "mdbreact";
import {errorWhite, checkWhite, closeWhite} from "../../assets/images/icons";

const Dialog = ({status, title, text, icon, isOpen, handleClose}) => (
    <Modal className={`notification__dialog notification__dialog--${status}`} isOpen={isOpen} centered toggle={handleClose}>
        <div className="modal-header notification__dialog__header">
            <span className="notification__dialog__header__close">
                <img src={closeWhite} alt="close modal" className="notification__dialog__header__close__icon" onClick={handleClose}/>
            </span>
        </div>
        <ModalBody className="notification__dialog__body">
            {icon ?
            <img className="notification__dialog__body__icon" src={icon} alt="icon"/>
                :
            <img className="notification__dialog__body__icon" src={status === 'error' ? errorWhite : checkWhite} alt="icon"/>
            }
            {title && <div className="notification__dialog__body__title">{title}</div>}
            <div className="notification__dialog__body__text">{text}</div>
        </ModalBody>
    </Modal>
);

Dialog.propTypes = {
    status: PropTypes.oneOf(['success', 'error', 'warning']),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    icon: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default Dialog;
