// FREE
import Animation from "./components/Animation";
import Alert from "./components/Alert";
import Badge from "./components/Badge";
import Breadcrumb from "./components/Breadcrumb";
import BreadcrumbItem from "./components/BreadcrumbItem";
import Button from "./components/Button";
import ButtonGroup from "./components/ButtonGroup";
import ButtonToolbar from "./components/ButtonToolbar";
import Card from "./components/Card";
import CardBody from "./components/CardBody";
import CardFooter from "./components/CardFooter";
import CardGroup from "./components/CardGroup";
import CardHeader from "./components/CardHeader";
import CardImage from "./components/CardImage";
import CardText from "./components/CardText";
import CardTitle from "./components/CardTitle";
import Carousel from "./components/Carousel";
import CarouselCaption from "./components/CarouselCaption";
import CarouselControl from "./components/CarouselControl";
import CarouselInner from "./components/CarouselInner";
import CarouselItem from "./components/CarouselItem";
import CarouselIndicators from "./components/CarouselIndicators";
import CarouselIndicator from "./components/CarouselIndicator";
import Col from "./components/Col";
import Collapse from "./components/Collapse";
import Container from "./components/Container";
import DataTable from "./components/DataTable";
import DropdownItem from "./components/DropdownItem";
import DropdownMenu from "./components/DropdownMenu";
import DropdownToggle from "./components/DropdownToggle";
import EdgeHeader from "./components/EdgeHeader";
import Fa from "./components/Fa";
import FormInline from "./components/FormInline";
import Footer from "./components/Footer";
import FreeBird from "./components/FreeBird";
import Input from "./components/Input";
import InputNumeric from "./components/InputNumeric";
import Jumbotron from "./components/Jumbotron";
import ListGroup from "./components/ListGroup";
import ListGroupItem from "./components/ListGroupItem";
import Mask from "./components/Mask";
import Media from "./components/Media";
import Modal from "./components/Modal";
import ModalBody from "./components/ModalBody";
import ModalFooter from "./components/ModalFooter";
import ModalHeader from "./components/ModalHeader";
import Nav from "./components/Nav";
import Navbar from "./components/Navbar";
import NavbarBrand from "./components/NavbarBrand";
import NavbarNav from "./components/NavbarNav";
import NavbarToggler from "./components/NavbarToggler";
import NavItem from "./components/NavItem";
import NavLink from "./components/NavLink";
import Pagination from "./components/Pagination";
import PageItem from "./components/PageItem";
import PageLink from "./components/PageLink";
import Popover from "./components/Popover";
import PopoverBody from "./components/PopoverBody";
import PopoverHeader from "./components/PopoverHeader";
import Progress from "./components/Progress";
import Waves from "./components/Waves";
import Row from "./components/Row";
import Table from "./components/Table";
import TableBody from "./components/TableBody";
import TableHead from "./components/TableHead";
import TableFoot from "./components/TableFoot";
import TextField from "./components/TextField";
import Tooltip from "./components/Tooltip";
import View from "./components/View";
import { toast, ToastContainer, cssTransition } from "react-toastify";
import HamburgerToggler from "./components/HamburgerToggler";
import Iframe from "./components/Iframe";

// PRO
import Autocomplete from "./components/pro/Autocomplete";
import Avatar from "./components/pro/Avatar";
import ButtonFixed from "./components/pro/ButtonFixed";
import ButtonFixedItem from "./components/pro/ButtonFixedItem";
import CardUp from "./components/pro/CardUp";
import Chip from "./components/pro/Chip";
import ChipsInput from "./components/pro/ChipsInput";
import CollapseHeader from "./components/pro/CollapseHeader";
import DatePicker from "./components/pro/DatePicker";
import ExportToCSV from "./components/pro/ExportToCSV";
import FlippingCard from "./components/pro/FlippingCard";
import InputFile from "./components/pro/InputFile";
import InputRange from "./components/pro/InputRange";
import InputSwitch from "./components/pro/InputSwitch";
import LightboxStyles from "./components/pro/Lightbox.css";
import PerfectScrollbar from "./components/pro/PerfectScrollbar";
import ScrollSpyBox from "./components/pro/ScrollSpyBox";
import ScrollSpyList from "./components/pro/ScrollSpyList";
import ScrollSpyListItem from "./components/pro/ScrollSpyListItem";
import ScrollSpyText from "./components/pro/ScrollSpyText";
import SideNav from "./components/pro/SideNav";
import SideNavCat from "./components/pro/SideNavCat";
import SideNavItem from "./components/pro/SideNavItem";
import SideNavLink from "./components/pro/SideNavLink";
import SideNavNav from "./components/pro/SideNavNav";
import SimpleChart from './components/pro/SimpleChart';
import Select from "./components/pro/Select";
import SelectInput from "./components/pro/SelectInput";
import SelectOptions from "./components/pro/SelectOptions";
import SelectOption from "./components/pro/SelectOption";
import Spinner from "./components/pro/Spinner";
import Step from "./components/pro/Step";
import Stepper from "./components/pro/Stepper";
import TableEditable from "./components/pro/TableEditable";
import TabPane from "./components/pro/TabPane";
import TabContent from "./components/pro/TabContent";
import Sticky from "./components/pro/Sticky";
import StickyContainer from "./components/pro/StickyContainer";
import Testimonial from "./components/pro/Testimonial";
import Timeline from "./components/pro/Timeline";
import TimelineStep from "./components/pro/TimelineStep";
import TimePicker from './components/pro/TimePicker/TimePicker';

// MDB FREE
import {MDBBadge} from "./components/Badge";
import {MDBAlert} from "./components/Alert";
import {MDBBreadcrumb} from "./components/Breadcrumb";
import {MDBBreadcrumbItem} from "./components/BreadcrumbItem";
import {MDBBtn} from "./components/Button";
import {MDBBtnGroup} from "./components/ButtonGroup";
import {MDBBtnToolbar} from "./components/ButtonToolbar";
import {MDBCard} from "./components/Card";
import {MDBCardBody} from "./components/CardBody";
import {MDBCardFooter} from "./components/CardFooter";
import {MDBCardGroup} from "./components/CardGroup";
import {MDBCardHeader} from "./components/CardHeader";
import {MDBCardImage} from "./components/CardImage";
import {MDBCardText} from "./components/CardText";
import {MDBCardTitle} from "./components/CardTitle";
import {MDBCarousel} from "./components/Carousel";
import {MDBCarouselCaption} from "./components/CarouselCaption";
import {MDBControl} from "./components/CarouselControl";
import {MDBCarouselInner} from "./components/CarouselInner";
import {MDBCarouselItem} from "./components/CarouselItem";
import {MDBCarouselIndicators} from "./components/CarouselIndicators";
import {MDBCarouselIndicator} from "./components/CarouselIndicator";
import {MDBCol} from "./components/Col";
import {MDBCollapse} from "./components/Collapse";
import {MDBContainer} from "./components/Container";
import {MDBDataTable} from "./components/DataTable";
import {MDBDropdownItem} from "./components/DropdownItem";
import {MDBDropdownMenu} from "./components/DropdownMenu";
import {MDBDropdownToggle} from "./components/DropdownToggle";
import {MDBEdgeHeader} from "./components/EdgeHeader";
import {MDBIcon} from "./components/Fa";
import {MDBFormInline} from "./components/FormInline";
import {MDBFooter} from "./components/Footer";
import {MDBFreeBird} from "./components/FreeBird";
import {MDBInput} from "./components/Input";
import {MDBInputSelect} from "./components/InputNumeric";
import {MDBJumbotron} from "./components/Jumbotron";
import {MDBListGroup} from "./components/ListGroup";
import {MDBListGroupItem} from "./components/ListGroupItem";
import {MDBMask} from "./components/Mask";
import {MDBMedia} from "./components/Media";
import {MDBModal} from "./components/Modal";
import {MDBModalBody} from "./components/ModalBody";
import {MDBModalFooter} from "./components/ModalFooter";
import {MDBModalHeader} from "./components/ModalHeader";
import {MDBNav} from "./components/Nav";
import {MDBNavbar} from "./components/Navbar";
import {MDBNavbarBrand} from "./components/NavbarBrand";
import {MDBNavbarNav} from "./components/NavbarNav";
import {MDBNavbarToggler} from "./components/NavbarToggler";
import {MDBNavItem} from "./components/NavItem";
import {MDBNavLink} from "./components/NavLink";
import {MDBPagination} from "./components/Pagination";
import {MDBPageItem} from "./components/PageItem";
import {MDBPageNav} from "./components/PageLink";
import {MDBPopover} from "./components/Popover";
import {MDBPopoverBody} from "./components/PopoverBody";
import {MDBPopoverHeader} from "./components/PopoverHeader";
import {MDBProgress} from "./components/Progress";
import {MDBWaves} from "./components/Waves";
import {MDBRow} from "./components/Row";
import {MDBTable} from "./components/Table";
import {MDBTableBody} from "./components/TableBody";
import {MDBTableHead} from "./components/TableHead";
import {MDBTableFoot} from "./components/TableFoot";
import {MDBTextarea} from "./components/TextField";
import {MDBTooltip} from "./components/Tooltip";
import {MDBView} from "./components/View";

// MDB PRO
// import {MDBAnimation} from './components/Animation';
import {MDBAutocomplete} from "./components/pro/Autocomplete";
import {MDBAvatar} from "./components/pro/Avatar";
import {MDBBtnFixed} from "./components/pro/ButtonFixed";
import {MDBBtnFixedItem} from "./components/pro/ButtonFixedItem";
import {MDBCardUp} from "./components/pro/CardUp";
import {MDBChip} from "./components/pro/Chip";
import {MDBChipsInput} from "./components/pro/ChipsInput";
import {MDBCollapseHeader} from "./components/pro/CollapseHeader";
import {MDBDatePicker} from "./components/pro/DatePicker";
import {MDBExportToCSV} from "./components/pro/ExportToCSV";
import {MDBTimePicker} from "./components/pro/TimePicker/TimePicker";
import {MDBRotatingCard} from "./components/pro/FlippingCard";
import {MDBFileInput} from "./components/pro/InputFile";
import {MDBRangeInput} from "./components/pro/InputRange";
import {MDBSwitch} from "./components/pro/InputSwitch";
import {MDBLightboxStyles} from "./components/pro/Lightbox.css";
import {MDBScrollbar} from "./components/pro/PerfectScrollbar";
import {MDBScrollspyBox} from "./components/pro/ScrollSpyBox";
import {MDBScrollspyList} from "./components/pro/ScrollSpyList";
import {MDBScrollspyListItem} from "./components/pro/ScrollSpyListItem";
import {MDBScrollspyText} from "./components/pro/ScrollSpyText";
import {MDBSideNav} from "./components/pro/SideNav";
import {MDBSideNavCat} from "./components/pro/SideNavCat";
import {MDBSideNavItem} from "./components/pro/SideNavItem";
import {MDBSideNavLink} from "./components/pro/SideNavLink";
import {MDBSideNavNav} from "./components/pro/SideNavNav";
import {MDBSimpleChart} from './components/pro/SimpleChart';
import {MDBSelect} from "./components/pro/Select";
import {MDBSelectInput} from "./components/pro/SelectInput";
import {MDBSelectOptions} from "./components/pro/SelectOptions";
import {MDBSelectOption} from "./components/pro/SelectOption";
import {MDBSpinner} from "./components/pro/Spinner";
import {MDBStep} from "./components/pro/Step";
import {MDBStepper} from "./components/pro/Stepper";
import {MDBTableEditable} from "./components/pro/TableEditable";
import {MDBTabPane} from "./components/pro/TabPane";
import {MDBTabContent} from "./components/pro/TabContent";
import {MDBSticky} from "./components/pro/Sticky";
import {MDBStickyContent} from "./components/pro/StickyContainer";
import {MDBTestimonial} from "./components/pro/Testimonial";
import {MDBTimeline} from "./components/pro/Timeline";
import {MDBTimelineStep} from "./components/pro/TimelineStep";
import MDBStreak from './components/pro/Streak';

let Dropdown = require("./components/Dropdown").default;
let DropdownMenuComponent = require("./components/DropdownMenuComponent")
  .default;
DropdownMenuComponent = require("./components/pro/DropdownMenuProComponent")
  .default;
let MDBDropdown = require("./components/Dropdown").default;
let MDBDropdownMenuComponent = require("./components/DropdownMenuComponent")
  .default;
MDBDropdownMenuComponent = require("./components/pro/DropdownMenuProComponent")
  .default;

export  {
  // FREE
  Animation,
  Alert,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardGroup,
  CardHeader,
  CardImage,
  CardText,
  CardTitle,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselInner,
  CarouselItem,
  CarouselIndicators,
  CarouselIndicator,
  Col,
  Collapse,
  Container,
  DataTable,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownMenuComponent,
  DropdownToggle,
  EdgeHeader,
  Fa,
  FormInline,
  Footer,
  FreeBird,
  Input,
  InputNumeric,
  Jumbotron,
  ListGroup,
  ListGroupItem,
  Mask,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarNav,
  NavbarToggler,
  NavItem,
  NavLink,
  Pagination,
  PageItem,
  PageLink,
  Popover,
  PopoverBody,
  PopoverHeader,
  Progress,
  Waves,
  Row,
  Table,
  TableBody,
  TableHead,
  TableFoot,
  TextField,
  Tooltip,
  View,
  toast,
  ToastContainer,
  cssTransition,
  HamburgerToggler,
  Iframe,
  // PRO
  Autocomplete,
  Avatar,
  ButtonFixed,
  ButtonFixedItem,
  CardUp,
  Chip,
  ChipsInput,
  CollapseHeader,
  DatePicker,
  ExportToCSV,
  FlippingCard,
  InputFile,
  InputRange,
  InputSwitch,
  LightboxStyles,
  PerfectScrollbar,
  ScrollSpyBox,
  ScrollSpyList,
  ScrollSpyListItem,
  ScrollSpyText,
  SideNav,
  SideNavCat,
  SideNavItem,
  SideNavLink,
  SideNavNav,
  SimpleChart,
  Select,
  SelectInput,
  SelectOptions,
  SelectOption,
  Spinner,
  Step,
  Stepper,
  TableEditable,
  TabPane,
  TabContent,
  Sticky,
  StickyContainer,
  Testimonial,
  Timeline,
  TimelineStep,
  TimePicker,
  // MDB FREE
  MDBBadge,
  MDBAlert,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBBtnGroup,
  MDBBtnToolbar,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardGroup,
  MDBCardHeader,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBCarousel,
  MDBCarouselCaption,
  MDBControl,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCarouselIndicators,
  MDBCarouselIndicator,
  MDBCol,
  MDBCollapse,
  MDBContainer,
  MDBDataTable,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownMenuComponent,
  MDBDropdownToggle,
  MDBEdgeHeader,
  MDBIcon,
  MDBFormInline,
  MDBFooter,
  MDBFreeBird,
  MDBInput,
  MDBInputSelect,
  MDBJumbotron,
  MDBListGroup,
  MDBListGroupItem,
  MDBMask,
  MDBMedia,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBNav,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBNavItem,
  MDBNavLink,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBProgress,
  MDBWaves,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBTableFoot,
  MDBTextarea,
  MDBTooltip,
  MDBView,
  // MDB PRO
  MDBAutocomplete,
  MDBAvatar,
  MDBBtnFixed,
  MDBBtnFixedItem,
  MDBCardUp,
  MDBChip,
  MDBChipsInput,
  MDBCollapseHeader,
  MDBExportToCSV,
  MDBDatePicker,
  MDBTimePicker,
  MDBRotatingCard,
  MDBFileInput,
  MDBRangeInput,
  MDBSwitch,
  MDBLightboxStyles,
  MDBScrollbar,
  MDBScrollspyBox,
  MDBScrollspyList,
  MDBScrollspyListItem,
  MDBScrollspyText,
  MDBSideNav,
  MDBSideNavCat,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavNav,
  MDBSimpleChart,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBSpinner,
  MDBTableEditable,
  MDBTabPane,
  MDBTabContent,
  MDBStep,
  MDBStepper,
  MDBSticky,
  MDBStickyContent,
  MDBTestimonial,
  MDBTimeline,
  MDBTimelineStep,
  MDBStreak
};
