import { fetchProductOrderHistory } from '../actions/routines';
import {call, put} from "redux-saga/effects";
import Products from "../services/api/Products";

export function* fetchProductOrderHistorySaga(action) {
    try {
        yield put(fetchProductOrderHistory.request());

        const response = yield call(Products.getOrderHistory, action.payload);

        yield put(fetchProductOrderHistory.success(response.data));
    } catch (error) {
        yield put(fetchProductOrderHistory.failure(error.message));
    } finally {
        yield put(fetchProductOrderHistory.fulfill());
    }
}
