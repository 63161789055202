import React from 'react';

export default class ErrorHandler extends React.Component {
    componentDidCatch(error) {
        switch (error.name) {
            case 'ChunkLoadError':
                if (window) {
                    // when chunk cannot be found (usually after deployment new version)
                    // then refresh the page.
                    window.location.reload();
                }
                break;
            default:
                throw error;
        }
    }

    render() {
        return this.props.children;
    }
}
