import React from 'react';
import moment from 'moment';
import Allergens from "./Allergens";
import {FormattedMessage} from "react-intl";
import AllergenCell from "./components/AllergenCell";
import GreenCheckIcon from "../../Utils/GreenCheckIcon";
import {toggleFilterable} from "../../../actions/table";
import {connect} from "react-redux";
import {fetchAllergensData} from "../../../actions/routines";
import Products from "../../../services/api/Products";
import saveFile from "../../../services/fileSaver";

const getColumns = (hoveredCol) => {
    return [{
        Header: "",
        fixed: 'left',
        columns: [
            {
                id: 'article_nr',
                Header: <FormattedMessage id="table.header.product_no"/>,
                preferencesLabel: <FormattedMessage id="table.header.product_no"/>,
                filterPlaceholder: "table.filters.input.article_nr",
                accessor: 'article_nr',
                width: 80,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__id',
                className: 'table__rt__col table__rt__col--fix-width table__rt__id',
                fixed: 'left',
                showInPreferences: false,
                sortable: true,
                Cell: row => (row.value)
            }, {
                id: 'name',
                Header: <FormattedMessage id="table.header.product_name"/>,
                preferencesLabel: <FormattedMessage id="table.header.product_name"/>,
                filterPlaceholder: "table.filters.input.product_name",
                accessor: 'name',
                width: 250,
                style: {overflow: 'visible', whiteSpace: 'normal'},
                headerClassName: 'table__rt__col table__rt__product-name',
                className: 'table__rt__col table__rt__product-name',
                fixed: 'left',
                showInPreferences: false,
                sortable: true
            }, {
                id: 'fiche_date',
                Header: <FormattedMessage id="table.header.date"/>,
                preferencesLabel: <FormattedMessage id="table.header.date"/>,
                accessor: d => d.allergens.fiche_date,
                width: 80,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__date',
                className: 'table__rt__col table__rt__col--fix-width table__rt__date',
                fixed: 'left',
                filterable: false,
                showInPreferences: false,
                Cell: row => (moment(row.value).format('DD/MM/YYYY'))
            }, {
                id: 'verified',
                Header: <FormattedMessage id="table.header.is_checked_by_franky"/>,
                accessor: d => d.allergens.verified,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__checked-by-franky',
                className: 'table__rt__col table__rt__col--fix-width table__rt__checked-by-franky',
                fixed: 'left',
                showInPreferences: false,
                filterable: false,
                Cell: row => {
                    if (row.value === true) {
                        return <GreenCheckIcon width={14} height={14}/>;
                    }

                    return '';
                }
            }
        ]
    }, {
        Header: "",
        columns: [
            {
                id: 'gluten',
                Header: <FormattedMessage id="allergens.gluten.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.gluten.full"/>,
                accessor: d => d.allergens.gluten,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'gluten' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'wheat',
                Header: <FormattedMessage id="allergens.wheat.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.wheat.full"/>,
                accessor: d => d.allergens.wheat,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'wheat' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'rye',
                Header: <FormattedMessage id="allergens.rye.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.rye.full"/>,
                accessor: d => d.allergens.rye,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'rye' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'barley',
                Header: <FormattedMessage id="allergens.barley.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.barley.full"/>,
                accessor: d => d.allergens.barley,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'barley' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'oats',
                Header: <FormattedMessage id="allergens.oats.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.oats.full"/>,
                accessor: d => d.allergens.oats,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'oats' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'spelled',
                Header: <FormattedMessage id="allergens.spelled.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.spelled.full"/>,
                accessor: d => d.allergens.spelled,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'spelled' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'khorasan_wheat',
                Header: <FormattedMessage id="allergens.khorasan_wheat.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.khorasan_wheat.full"/>,
                accessor: d => d.allergens.khorasan_wheat,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'khorasan_wheat' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'crustaceans',
                Header: <FormattedMessage id="allergens.crustaceans.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.crustaceans.full"/>,
                accessor: d => d.allergens.crustaceans,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'crustaceans' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'eggs',
                Header: <FormattedMessage id="allergens.eggs.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.eggs.full"/>,
                accessor: d => d.allergens.eggs,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'eggs' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'fish',
                Header: <FormattedMessage id="allergens.fish.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.fish.full"/>,
                accessor: d => d.allergens.fish,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'fish' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'groundnuts',
                Header: <FormattedMessage id="allergens.groundnuts.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.groundnuts.full"/>,
                accessor: d => d.allergens.groundnuts,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'groundnuts' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'soy',
                Header: <FormattedMessage id="allergens.soy.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.soy.full"/>,
                accessor: d => d.allergens.soy,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'soy' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'milk',
                Header: <FormattedMessage id="allergens.milk.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.milk.full"/>,
                accessor: d => d.allergens.milk,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'milk' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'scale_fruits',
                Header: <FormattedMessage id="allergens.scale_fruits.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.scale_fruits.full"/>,
                accessor: d => d.allergens.scale_fruits,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'scale_fruits' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'almonds',
                Header: <FormattedMessage id="allergens.almonds.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.almonds.full"/>,
                accessor: d => d.allergens.almonds,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'almonds' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'hazelnuts',
                Header: <FormattedMessage id="allergens.hazelnuts.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.hazelnuts.full"/>,
                accessor: d => d.allergens.hazelnuts,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'hazelnuts' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'walnuts',
                Header: <FormattedMessage id="allergens.walnuts.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.walnuts.full"/>,
                accessor: d => d.allergens.walnuts,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'walnuts' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'cashew_nuts',
                Header: <FormattedMessage id="allergens.cashew_nuts.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.cashew_nuts.full"/>,
                accessor: d => d.allergens.cashew_nuts,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'cashew_nuts' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'pecans',
                Header: <FormattedMessage id="allergens.pecans.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.pecans.full"/>,
                accessor: d => d.allergens.pecans,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'pecans' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'brazil_nuts',
                Header: <FormattedMessage id="allergens.brazil_nuts.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.brazil_nuts.full"/>,
                accessor: d => d.allergens.brazil_nuts,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'brazil_nuts' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'pistachios',
                Header: <FormattedMessage id="allergens.pistachios.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.pistachios.full"/>,
                accessor: d => d.allergens.pistachios,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'pistachios' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'macadamia_nuts',
                Header: <FormattedMessage id="allergens.macadamia_nuts.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.macadamia_nuts.full"/>,
                accessor: d => d.allergens.macadamia_nuts,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'macadamia_nuts' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'celery',
                Header: <FormattedMessage id="allergens.celery.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.celery.full"/>,
                accessor: d => d.allergens.celery,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'celery' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'mustard',
                Header: <FormattedMessage id="allergens.mustard.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.mustard.full"/>,
                accessor: d => d.allergens.mustard,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'mustard' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'sesame',
                Header: <FormattedMessage id="allergens.sesame.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.sesame.full"/>,
                accessor: d => d.allergens.sesame,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'sesame' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'sulfur',
                Header: <FormattedMessage id="allergens.sulfur.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.sulfur.full"/>,
                accessor: d => d.allergens.sulfur,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'sulfur' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'lupine',
                Header: <FormattedMessage id="allergens.lupine.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.lupine.full"/>,
                accessor: d => d.allergens.lupine,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'lupine' ? 'table__rt_col--hovered': ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }, {
                id: 'mollusks',
                Header: <FormattedMessage id="allergens.mollusks.abv"/>,
                preferencesLabel: <FormattedMessage id="allergens.mollusks.full"/>,
                accessor: d => d.allergens.mollusks,
                width: 40,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator',
                className: `${hoveredCol === 'mollusks' ? 'table__rt_col--hovered' : ''} table__rt__col table__rt__col--fix-width table__rt__allergen disable-sort-indicator`,
                show: true,
                showInPreferences: true,
                filterable: false,
                Cell: row => <AllergenCell value={row.value}/>
            }
        ]
    }, {
        Header: "",
        fixed: 'right',
        columns: [
            {
                id: 'kcal',
                Header: <FormattedMessage id="allergens.kcal"/>,
                accessor: d => d.allergens.kcal,
                width: 50,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__kcal',
                className: 'table__rt__col table__rt__col--fix-width table__rt__kcal',
                fixed: 'right',
                showInPreferences: false,
                filterable: false
            }, {
                id: 'kj',
                Header: <FormattedMessage id="allergens.kj"/>,
                accessor: d => d.allergens.kj,
                width: 45,
                headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__kj',
                className: 'table__rt__col table__rt__col--fix-width table__rt__kj',
                fixed: 'right',
                showInPreferences: false,
                filterable: false
            }
        ]
    }];

};

class AllergensContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hoveredCol: '',
            data: [],
            columns: [],
            isDownloadLoading: false
        }
    }

    componentDidMount() {
        this.setState({
            columns: getColumns(this.state.hoveredCol)
        })
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.hoveredCol !== this.state.hoveredCol) {
            this.setState({
                columns: getColumns(this.state.hoveredCol)
            })
        }
    };

    fetchData = (params) => {
        params.mylist = '1';

        this.props.fetchAllergensData({params});
    };

    addHoverClassOnColumn = (id) => {
        this.setState({
            hoveredCol: id
        });
    };

    handleDownloadAllergenListPdf = () => {
        this.setState({
            isDownloadLoading: true
        });
        Products.getAllergenListPdf().then((response) => {
            saveFile(response, 'allergen-list.pdf');

            this.setState({
                isDownloadLoading: false
            })
        })
    };

    render() {
        const {data, filterable, toggleFilterable, total, loading} = this.props;
        const {columns, isDownloadLoading} = this.state;

        return <Allergens
            data={data}
            columns={columns}
            isFilterable={filterable}
            toggleFilterable={toggleFilterable}
            addHoverClassOnColumn={this.addHoverClassOnColumn}
            handleFetchData={this.fetchData}
            itemsCount={total}
            loading={loading}
            handleDownloadAllergenListPdf={this.handleDownloadAllergenListPdf}
            isDownloadLoading={isDownloadLoading}
        />
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.allergens.data,
        total: state.allergens.total,
        loading: state.allergens.loading,
        filterable: state.table.filterable
    }
};

const mapDispatchToProps = {toggleFilterable, fetchAllergensData};

export default connect(mapStateToProps, mapDispatchToProps)(AllergensContainer);
