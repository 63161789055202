import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Points = ({ points, color, shape }) => {
    if (shape === 'rectangle') {
        return (
            <div
                className="campaign__item__points rectangle"
                style={{ backgroundColor: color }}
            >
                <p className="campaign__item__points__points">
                    <a href={'/campaign'} style={{ color: 'white' }}>
                        {points}
                    </a>
                </p>
                <p className="campaign__item__points__label">
                    <a href={'/campaign'} style={{ color: 'white' }}>
                        <FormattedMessage id="campaign.item.detail" />
                    </a>
                </p>
            </div>
        );
    }
    return (
        <div
            className="campaign__item__points"
            style={{ backgroundColor: color }}
        >
            <p className="campaign__item__points__label">
                <a href={'/campaign'} style={{ color: 'white' }}>
                    <FormattedMessage id="campaign.item.points" />
                </a>
            </p>
            <p className="campaign__item__points__points">
                <a href={'/campaign'} style={{ color: 'white' }}>
                    {points}
                </a>
            </p>
        </div>
    );
};

Points.propTypes = {
    color: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
};

export default Points;
