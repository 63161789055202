const table = (state = {filterable: false}, action) => {
    switch (action.type) {
        case 'TOGGLE_FILTERABLE':
            return {filterable: !state.filterable};
        case 'OPEN_FILTERABLE':
            return {filterable: true};
        case 'HIDE_FILTERABLE':
            return {filterable: false};
        default:
            return state
    }
};

export default table;