import {fetchFairProducts} from "../actions/routines";

const initialState = {
    data: [], 
    loading: false
};

const fairProducts = (state = initialState, action) => {
    switch (action.type) {
        case 'FAIR_PRODUCTS_SET':
            return {...state}
        case fetchFairProducts.TRIGGER:
            return {
                ...state,
                loading: false,
            };
        case fetchFairProducts.SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total,
                countAll: action.payload.countAll,
                countUser: action.payload.countUser
            };
        case fetchFairProducts.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchFairProducts.FULFILL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default fairProducts;
