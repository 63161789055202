import React from 'react';
import { FormattedMessage } from 'react-intl';
import QuantityContainerForCart from '../../components/Quantity/QuantityContainerForCart';
import MyOrderListContainer from './components/MyOrderList/MyOrderListContainer';
import PromotionTooltip from '../PromotionTooltip/PromotionTooltip';
import CampaignPointsBadgeContainer from './components/CampaignPointsBadge/CampaignPointsBadgeContainer';
import OrdersHistory from './components/OrdersHistory/OrdersHistory';
import TotalPriceColor from './TotalPriceColor';
import moment from 'moment/moment';
import ProductPhoto from './components/ProductPhoto';

const getColumnsForCart = (
    hideByDefault,
    deliveryDate,
    doNotAdd,
    showPrice
) => {
    let windowWidth = window.innerWidth;
    let width = 'auto';
    let nameWidth = 255;
    let supplierWidth = 150;

    /*if (windowWidth > 991) {
        width = 'auto';
        supplierWidth = 100;
    } else if (windowWidth >= 768) {
        nameWidth = 100;
        supplierWidth = 90;
    }*/
    let columns = showPrice
        ? [
              {
                  id: 'productPhoto',
                  Header: <FormattedMessage id="table.header.product_photo" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.product_photo" />
                  ),
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__product-photo disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__product-photo disable-sort-indicator',
                  show: false,
                  width: 150,
                  sortable: false,
                  showInPreferences: false,
                  Cell: (row) => {
                      return <ProductPhoto product={row.original} />;
                  },
              },
              {
                  id: 'article_nr',
                  Header: <FormattedMessage id="table.header.product_no" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.id" />
                  ),
                  filterPlaceholder: 'table.filters.input.article_nr',
                  accessor: 'article_nr',
                  width: 105,
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__id',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__id bold',
                  show: true,
                  Cell: (row) =>
                      row.value ? row.value.toString().padStart(7, '0') : '',
              },
              {
                  id: 'name',
                  Header: <FormattedMessage id="table.header.product_name" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.product_name" />
                  ),
                  filterPlaceholder: 'table.filters.input.product_name',
                  accessor: 'name',
                  width: windowWidth > 1200 ? 270 : nameWidth,
                  style: { overflow: 'visible', whiteSpace: 'normal' },
                  headerClassName: 'table__rt__col table__rt__product-name',
                  className: 'table__rt__col table__rt__product-name bold',
                  show: true,
                  Cell: (row) => {
                      return (
                          <div>
                              {row.original.isNoAvailableRefund && (
                                  <div className="product-novelty__item-box__no-refund-small-title">
                                      <FormattedMessage
                                          id={'product.no_refund'}
                                      />
                                  </div>
                              )}
                              {row.value}
                          </div>
                      );
                  },
              },
              {
                  id: 'supplier',
                  Header: <FormattedMessage id="table.header.supplier" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.supplier" />
                  ),
                  filterPlaceholder: 'table.filters.input.supplier',
                  accessor: 'supplier',
                  width: windowWidth > 1200 ? width : supplierWidth,
                  style: { whiteSpace: 'normal' },
                  className: 'table__rt__col table__rt__supplier',
                  show: true,
              },
              {
                  id: 'deliveryDate',
                  Header: <FormattedMessage id="table.header.delivery_date" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.delivery_date" />
                  ),
                  accessor: 'deliveryDate',
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__available disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__available disable-sort-indicator',
                  show: true,
                  style: { whiteSpace: 'normal' },
                  width: windowWidth > 1200 ? width : 100,
                  filterable: false,
                  sortable: false,
                  Cell: (row) => {
                      return moment(row.original.delivery_date).format(
                          'DD/MM/YYYY'
                      );
                  },
              },
              {
                  id: 'promotion',
                  Header: <FormattedMessage id="table.header.promotion" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.promotion" />
                  ),
                  accessor: 'promotion',
                  filterable: false,
                  sortable: false,
                  headerClassName:
                      'table__rt__col--fix-width table__rt__promotion disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__promotion disable-sort-indicator',
                  show: true,
                  width: windowWidth > 1200 ? 65 : width,
                  Cell: (row) => {
                      if (
                          row.original.promotions &&
                          row.original.promotions.length > 0
                      ) {
                          return (
                              <PromotionTooltip
                                  id={row.original.id}
                                  promotions={row.original.promotions}
                              />
                          );
                      }
                  },
              },
              // {
              //     id: 'price',
              //     Header: <FormattedMessage id="table.header.price"/>,
              //     preferencesLabel: <FormattedMessage id="table.preferences.price"/>,
              //     accessor: 'prices',
              //     headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__available',
              //     className: 'table__rt__col table__rt__col--fix-width table__rt__available',
              //     show: true,
              //     filterable: false,
              //     width,
              //     Cell: row => (
              //         row.original.price && <span>{row.original.price.label}</span>
              //     )
              // },
              {
                  id: 'weight',
                  Header: <FormattedMessage id="table.header.package_size" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.package_size" />
                  ),
                  accessor: 'weight',
                  filterable: false,
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__package-size',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__package-size',
                  show: true,
                  width: 80,
              },
              {
                  id: 'pricePrice',
                  Header: <FormattedMessage id="table.header.price" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.price" />
                  ),
                  accessor: 'price',
                  width: 100,
                  style: { overflow: 'visible', whiteSpace: 'normal' },
                  headerClassName: 'table__rt__col table__rt__product-name',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__id',
                  show: true,
                  Cell: (row) =>
                      row.original.price +
                      (row.original.price_unit
                          ? `/${row.original.price_unit}`
                          : ''),
              },
              {
                  id: 'quantity',
                  Header: <FormattedMessage id="table.header.quantity" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.quantity" />
                  ),
                  accessor: 'quantity',
                  filterable: false,
                  sortable: false,
                  width: windowWidth > 1200 ? 150 : 85,
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__quantity disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__quantity disable-sort-indicator',
                  show: true,
                  Cell: (row) => {
                      const product = row.original;
                      return (
                          <QuantityContainerForCart
                              product={product}
                              productId={product.id}
                              quantity={product.amount ? product.amount : 0}
                              tabIndex={row.viewIndex + 1}
                              deliveryDate={deliveryDate}
                              itemsInPackage={product.items_in_package}
                              orderAsPackage={product.order_as_package}
                          />
                      );
                  },
              },
              {
                  id: 'points',
                  Header: <FormattedMessage id="table.header.points" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.points" />
                  ),
                  accessor: (d) => (d.campaign ? d.campaign.points : d.id),
                  filterable: false,
                  sortable: false,
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__campaign disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__campaign disable-sort-indicator',
                  show: true,
                  width: windowWidth <= 991 ? 45 : width,
                  Cell: (row) => {
                      if (row.original.campaigns) {
                          return row.original.campaigns.map((campaign) => {
                              return (
                                  <CampaignPointsBadgeContainer
                                      key={campaign.id}
                                      productId={row.original.id}
                                      campaign={campaign}
                                  />
                              );
                          });
                      }
                  },
              },
              {
                  id: 'ordersHistory',
                  Header: <FormattedMessage id="table.header.orders_history" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.orders_history" />
                  ),
                  filterable: false,
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__orders-history disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__orders-history disable-sort-indicator',
                  show: true,
                  width: 230,
                  sortable: false,
                  Cell: (row) => (
                      <OrdersHistory
                          weeks={row.original.weeks}
                          inDelivery={row.original.in_delivery}
                          rowIndex={row.viewIndex}
                          providerError={row.original.has_orders_provider_error}
                      />
                  ),
              },
              {
                  id: 'onOrderList',
                  Header: <FormattedMessage id="table.header.on_order_list" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.on_order_list" />
                  ),
                  accessor: 'onOrderList',
                  filterable: false,
                  sortable: false,
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__on-order-list disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__on-order-list disable-sort-indicator',
                  show: true,
                  width,
                  Cell: (row) =>
                      row.original.in_assortment && (
                          <MyOrderListContainer
                              id={row.original.id}
                              onOrderList={row.original.in_assortment}
                          />
                      ),
              },
              {
                  id: 'totalPrice',
                  Header: <FormattedMessage id="cart.total_price_of_item" />,
                  preferencesLabel: (
                      <FormattedMessage id="cart.total_price_of_item" />
                  ),
                  filterPlaceholder: 'table.filters.input.product_name',
                  accessor: 'price',
                  filterable: false,
                  sortable: false,
                  width: 80,
                  style: { overflow: 'visible', whiteSpace: 'normal' },
                  headerClassName:
                      'table__rt__col table__rt__product-name disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__id disable-sort-indicator',
                  show: true,
                  Cell: (row) => <TotalPriceColor rowOriginal={row.original} />,
              },
          ]
        : [
              {
                  id: 'productPhoto',
                  Header: <FormattedMessage id="table.header.product_photo" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.product_photo" />
                  ),
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__product-photo disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__product-photo disable-sort-indicator',
                  show: false,
                  width: 210,
                  sortable: false,
                  showInPreferences: false,
                  Cell: (row) => {
                      return <ProductPhoto product={row.original} />;
                  },
              },
              {
                  id: 'article_nr',
                  Header: <FormattedMessage id="table.header.product_no" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.id" />
                  ),
                  filterPlaceholder: 'table.filters.input.article_nr',
                  accessor: 'article_nr',
                  width: 85,
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__id',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__id bold',
                  show: true,
                  Cell: (row) => (row.value ? row.value : ''),
              },
              {
                  id: 'name',
                  Header: <FormattedMessage id="table.header.product_name" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.product_name" />
                  ),
                  filterPlaceholder: 'table.filters.input.product_name',
                  accessor: 'name',
                  width: windowWidth > 1200 ? 270 : nameWidth,
                  style: { overflow: 'visible', whiteSpace: 'normal' },
                  headerClassName: 'table__rt__col table__rt__product-name',
                  className: 'table__rt__col table__rt__product-name bold',
                  show: true,
              },
              {
                  id: 'supplier',
                  Header: <FormattedMessage id="table.header.supplier" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.supplier" />
                  ),
                  filterPlaceholder: 'table.filters.input.supplier',
                  accessor: 'supplier',
                  width: windowWidth > 1200 ? width : supplierWidth,
                  style: { whiteSpace: 'normal' },
                  className: 'table__rt__col table__rt__supplier',
                  show: true,
              },
              {
                  id: 'deliveryDate',
                  Header: <FormattedMessage id="table.header.delivery_date" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.delivery_date" />
                  ),
                  accessor: 'deliveryDate',
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__available disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__available disable-sort-indicator',
                  show: true,
                  style: { whiteSpace: 'normal' },
                  width: windowWidth > 1200 ? width : 100,
                  filterable: false,
                  sortable: false,
                  Cell: (row) => {
                      return moment(row.original.delivery_date).format(
                          'DD/MM/YYYY'
                      );
                  },
              },
              {
                  id: 'promotion',
                  Header: <FormattedMessage id="table.header.promotion" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.promotion" />
                  ),
                  accessor: 'promotion',
                  filterable: false,
                  sortable: false,
                  headerClassName:
                      'table__rt__col--fix-width table__rt__promotion disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__promotion disable-sort-indicator',
                  show: true,
                  width: windowWidth > 1200 ? 65 : width,
                  Cell: (row) => {
                      if (
                          row.original.promotions &&
                          row.original.promotions.length > 0
                      ) {
                          return (
                              <PromotionTooltip
                                  id={row.original.id}
                                  promotions={row.original.promotions}
                              />
                          );
                      }
                  },
              },
              // {
              //     id: 'price',
              //     Header: <FormattedMessage id="table.header.price"/>,
              //     preferencesLabel: <FormattedMessage id="table.preferences.price"/>,
              //     accessor: 'prices',
              //     headerClassName: 'table__rt__col table__rt__col--fix-width table__rt__available',
              //     className: 'table__rt__col table__rt__col--fix-width table__rt__available',
              //     show: true,
              //     filterable: false,
              //     width,
              //     Cell: row => (
              //         row.original.price && <span>{row.original.price.label}</span>
              //     )
              // },
              {
                  id: 'weight',
                  Header: <FormattedMessage id="table.header.package_size" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.package_size" />
                  ),
                  accessor: 'weight',
                  filterable: false,
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__package-size',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__package-size',
                  show: true,
                  width: 70,
              },
              {
                  id: 'quantity',
                  Header: <FormattedMessage id="table.header.quantity" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.quantity" />
                  ),
                  accessor: 'quantity',
                  filterable: false,
                  sortable: false,
                  width: windowWidth > 1200 ? 150 : 85,
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__quantity disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__quantity disable-sort-indicator',
                  show: true,
                  Cell: (row) => {
                      const product = row.original;
                      return (
                          <QuantityContainerForCart
                              product={product}
                              productId={product.id}
                              quantity={product.amount ? product.amount : 0}
                              tabIndex={row.viewIndex + 1}
                              deliveryDate={deliveryDate}
                              itemsInPackage={product.items_in_package}
                              orderAsPackage={product.order_as_package}
                          />
                      );
                  },
              },
              {
                  id: 'points',
                  Header: <FormattedMessage id="table.header.points" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.points" />
                  ),
                  accessor: (d) => (d.campaign ? d.campaign.points : d.id),
                  filterable: false,
                  sortable: false,
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__campaign disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__campaign disable-sort-indicator',
                  show: true,
                  width: windowWidth <= 991 ? 45 : width,
                  Cell: (row) => {
                      if (row.original.campaigns) {
                          return row.original.campaigns.map((campaign) => {
                              return (
                                  <CampaignPointsBadgeContainer
                                      key={campaign.id}
                                      productId={row.original.id}
                                      campaign={campaign}
                                  />
                              );
                          });
                      }
                  },
              },
              {
                  id: 'ordersHistory',
                  Header: <FormattedMessage id="table.header.orders_history" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.orders_history" />
                  ),
                  filterable: false,
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__orders-history disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__orders-history disable-sort-indicator',
                  show: true,
                  width: 230,
                  sortable: false,
                  Cell: (row) => (
                      <OrdersHistory
                          weeks={row.original.weeks}
                          inDelivery={row.original.in_delivery}
                          rowIndex={row.viewIndex}
                          providerError={row.original.has_orders_provider_error}
                      />
                  ),
              },
              {
                  id: 'onOrderList',
                  Header: <FormattedMessage id="table.header.on_order_list" />,
                  preferencesLabel: (
                      <FormattedMessage id="table.preferences.on_order_list" />
                  ),
                  accessor: 'onOrderList',
                  filterable: false,
                  sortable: false,
                  headerClassName:
                      'table__rt__col table__rt__col--fix-width table__rt__on-order-list disable-sort-indicator',
                  className:
                      'table__rt__col table__rt__col--fix-width table__rt__on-order-list disable-sort-indicator',
                  show: true,
                  width,
                  Cell: (row) =>
                      row.original.in_assortment && (
                          <MyOrderListContainer
                              id={row.original.id}
                              onOrderList={row.original.in_assortment}
                          />
                      ),
              },
          ];

    if (hideByDefault) {
        columns.forEach((column, index) => {
            if (hideByDefault.includes(column.id)) {
                columns[index].show = false;
            }
        });
    }

    if (doNotAdd) {
        columns = columns.filter((column) => !doNotAdd.includes(column.id));
    }

    return columns;
};

export default getColumnsForCart;
