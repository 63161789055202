import request from './request';

const Notifications = {
    getAllRead: () => {
        return request({
            url: '/api/notification/all/read',
            method: 'GET',
        });
    },
    getAllUnread: () => {
        return request({
            url: '/api/notification/all/unread',
            method: 'GET',
        });
    },
    getNormalUnread: () => {
        return request({
            url: '/api/notification/normal/unread',
            method: 'GET',
        });
    },
    getServiceUnread: () => {
        return request({
            url: '/api/notification/service/unread',
            method: 'GET',
        });
    },
    setAsRead: (id) => {
        return request({
            url: `/api/notification/${id}`,
            method: 'PUT',
        });
    },
};

export default Notifications;