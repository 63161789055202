import PropTypes from 'prop-types'
import React from "react";
import {FormattedMessage} from "react-intl";

const Specification = ({label, value}) => (
    <p className="product-details__specifications__specification">
        <FormattedMessage id={label}/>: <strong>{value}</strong>
    </p>
);

Specification.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any
};

export default Specification;
