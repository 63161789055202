import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
} from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import NestedOption from '../Pages/MasterTable/components/Search/NestedOption';
import ButtonWithSpinner from '../Utils/ButtonWithSpinner';
import history from '../../history';
import searchGrey from '../../assets/images/search-grey.svg';
import * as qs from 'qs';

class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
        };
    }

    onChange = (e) => {
        this.setState({
            searchTerm: e.target.value,
        });
    };

    componentDidMount() {
        const queryParams = qs.parse(history.location.search.slice(1));
        if (queryParams['s_all']) {
            this.setState({
                searchTerm: queryParams['s_all'],
            });
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const searchTerm = this.state.searchTerm;

        const queryParams = qs.parse(history.location.search.slice(1));
        if (searchTerm) {
            queryParams['s_all'] = searchTerm;
        } else {
            delete queryParams['s_all'];
        }

        queryParams['page'] = 1;

        history.push({
            ...this.props.location,
            search: qs.stringify(queryParams),
        });

        this.props.onSubmit(queryParams);
    };

    fixLabel = (node) => {
        if (node && this.props.withSelect) {
            const label = node.querySelector(
                '.select-wrapper ul.select-dropdown input + label'
            );

            if (!label) {
                return;
            }

            label.textContent = this.props.intl.formatMessage({
                id: 'search.select.input_placeholder',
            });

            label.onclick = () => {
                label.classList.add('active');
            };
        }
    };

    render() {
        const {
            placeholder,
            label,
            loading,
            withSelect,
            options,
            selectedValue,
            getSelectedValue,
            isWithIcon,
        } = this.props;
        const queryParams = qs.parse(history.location.search.slice(1));
        return (
            <div className="main-search-wrapper">
                <form id="main-search" onSubmit={this.onSubmit}>
                    <div className="search-elements-wrapper">
                        <div
                            ref={this.fixLabel}
                            className={`search ${
                                withSelect ? 'search--with-select' : ''
                            }`}
                        >
                            {withSelect && (
                                <MDBSelect getValue={getSelectedValue}>
                                    <MDBSelectInput />
                                    <MDBSelectOptions search={true}>
                                        <MDBSelectOption
                                            className="all-categories"
                                            value="all-categories"
                                            selected={
                                                selectedValue === null &&
                                                !queryParams['s_category']
                                            }
                                        >
                                            <FormattedMessage id="search.select.option.all_categories" />
                                        </MDBSelectOption>
                                        {options.map((parent) => (
                                            <NestedOption
                                                key={parent.name}
                                                parent={parent}
                                                selected={selectedValue}
                                            />
                                        ))}
                                    </MDBSelectOptions>
                                </MDBSelect>
                            )}
                            <input
                                onChange={this.onChange}
                                value={this.state.searchTerm}
                                type="text"
                                className="search__input"
                                placeholder={placeholder}
                            />
                            {isWithIcon ? (
                                <img
                                    src={searchGrey}
                                    alt="search"
                                    className="search__icon"
                                />
                            ) : (
                                <ButtonWithSpinner
                                    type="submit"
                                    className="search__btn btn-brand"
                                    label={label}
                                    isLoading={
                                        !!(
                                            loading &&
                                            this.state.searchTerm !== ''
                                        )
                                    }
                                />
                            )}
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

Search.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
};

export default injectIntl(Search);
