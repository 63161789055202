import PropTypes from 'prop-types';
import React from 'react';
import PromoBox from './PromoBox';

const BoxesPromotion = ({ items }) => {
    return (
        <div
            className={`boxes-promotion ${
                items.length > 1 ? 'boxes-promotion--multiple' : ''
            }`}
        >
            {items.map((item) => (
                <PromoBox
                    key={item.id}
                    product={item}
                    showCount={true}
                    style={{ width: `calc((100% / ${items.length}) - 25px)` }}
                    id={item.id}
                    title={item.name}
                    image=""
                    description={item.name}
                />
            ))}
        </div>
    );
};

BoxesPromotion.propTypes = {
    items: PropTypes.array.isRequired,
};

export default BoxesPromotion;
