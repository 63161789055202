import React from 'react';
import Notifications from './Notifications';
import { FormattedMessage } from 'react-intl';
import { Button } from 'mdbreact';
import * as R from 'ramda';
import {
    fetchReadNotifications,
    fetchUnreadNotifications,
    setAsReadNotificationsPromiseCreator,
} from '../../../actions/routines';
import { connect } from 'react-redux';
import moment from 'moment';
import bindPromiseCreators from 'redux-saga-routines/es/bindPromiseCreators';
import { bindActionCreators } from 'redux';

class NotificationsContainer extends React.Component {
    constructor(props) {
        super(props);

        const baseColumns = [
            {
                id: 'notification_date',
                Header: <FormattedMessage id="table.header.date" />,
                accessor: 'notification_date',
                width: 90,
                headerClassName:
                    'table__rt__col table__rt__col--fix-width table__rt__date',
                className:
                    'table__rt__col table__rt__col--fix-width table__rt__date',
                Cell: (row) => moment(row.value).format('DD/MM/YYYY'),
            },
            {
                id: 'notification',
                Header: <FormattedMessage id="table.header.message" />,
                accessor: 'notification',
                headerClassName:
                    'table__rt__col table__rt__col--fix-width table__rt__message',
                className:
                    'table__rt__col table__rt__col--fix-width table__rt__message',
                Cell: (row) => {
                    if (row.original.link) {
                        return (
                            <a
                                href={row.original.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {row.value}
                            </a>
                        );
                    }

                    return <span>{row.value}</span>;
                },
            },
        ];

        const columnsWithBtn = baseColumns.slice();
        columnsWithBtn.push({
            id: 'is_read',
            Header: <FormattedMessage id="table.header.read" />,
            accessor: 'is_read',
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__read',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__read',
            filterable: false,
            width: 120,
            Cell: (row) => {
                return (
                    <Button
                        onClick={() => this.markAsRead(row.original.id)}
                        className="btn-brand btn-flat notifications-page__mark-as-read"
                    >
                        <FormattedMessage id="notifications.mark_as_read" />
                    </Button>
                );
            },
        });

        this.state = {
            notifications: [],
            readNotifications: [],
            notificationsPageSize: 10,
            readNotificationsPageSize: 10,
            notificationsColumns: columnsWithBtn,
            readNotificationsColumns: baseColumns,
        };
    }

    componentDidMount() {
        this.props.fetchUnreadNotifications();
        this.props.fetchReadNotifications();
        this.splitNotifications();
    }

    componentDidUpdate(prevProps) {
        if (
            !R.equals(prevProps.notifications, this.props.notifications) ||
            !R.equals(prevProps.readNotifications, this.props.readNotifications)
        ) {
            this.splitNotifications();
        }
    }

    splitNotifications = () => {
        const { notifications, readNotifications } = this.props;

        this.setState({
            notifications: this.sortDescendByCreatedAt(notifications),
            readNotifications: this.sortDescendByCreatedAt(readNotifications),
            notificationsPageSize: notifications.length,
            readNotificationsPageSize: readNotifications.length,
        });
    };

    markAsRead = (id) => {
        this.props.setAsReadNotificationsPromiseCreator(id).then(() => {
            const { notifications, readNotifications } = this.state;

            for (let i = 0; i < notifications.length; i++) {
                let notification = notifications[i];
                if (notification.id === id) {
                    readNotifications.push(notification);
                    notifications.splice(i, 1);
                    i--;
                }
            }

            this.setState({
                notifications: this.sortDescendByCreatedAt(notifications),
                readNotifications:
                    this.sortDescendByCreatedAt(readNotifications),
                notificationsPageSize: notifications.length,
                readNotificationsPageSize: readNotifications.length,
            });
        });
    };

    sortDescendByCreatedAt = (array) => {
        return array.sort((a, b) => {
            if (a.notification && a.notification.notification_date) {
                a = a.notification;
                b = b.notification;
            }
            return (
                new Date(b.notification_date) - new Date(a.notification_date)
            );
        });
    };

    render() {
        const {
            notifications,
            readNotifications,
            notificationsColumns,
            readNotificationsColumns,
            notificationsPageSize,
            readNotificationsPageSize,
        } = this.state;

        return (
            <Notifications
                notifications={notifications}
                readNotifications={readNotifications}
                notificationsColumns={notificationsColumns}
                readNotificationsColumns={readNotificationsColumns}
                notificationsPageSize={notificationsPageSize}
                readNotificationsPageSize={readNotificationsPageSize}
                loading={this.props.loading}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notifications: state.unreadNotifications.data,
        readNotifications: state.readNotifications.data,
        loading: state.notifications.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindPromiseCreators(
            {
                setAsReadNotificationsPromiseCreator,
            },
            dispatch
        ),
        ...bindActionCreators(
            {
                fetchUnreadNotifications,
                fetchReadNotifications,
            },
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsContainer);
