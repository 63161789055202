import React from 'react';
import InputDefault from "../../../Form/InputDefault";

class OrderNumberInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isValid: true
        };

        sessionStorage.removeItem('customerOrderNumber');
    }

    updateOrderNumber = (value) => {
        let isValid = value.length <= 10;

        this.setState({
            isValid: isValid
        })

        if (isValid) {
            sessionStorage.setItem('customerOrderNumber', value);
        }
    };

    render() {
        return (
            <InputDefault
                placeholder="cart.order_number.placeholder"
                className={
                    "order-number-input" + (this.state.isValid ? '' : ' is-invalid')
                }
                invalidFeedback="cart.order_number.error"
                onChange={(e) => this.updateOrderNumber(e.target.value)}
                value={null}
            />
        )
    }
};

export default OrderNumberInput;
