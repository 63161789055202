const tableData = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_TABLE_DATA_SUCCESS':
            return {
                data: action.tableData
            };
        default:
            return state
    }
};

export default tableData