import React from 'react';
import { injectIntl } from 'react-intl';
import Spinner from '../../../../../Utils/Spinner';

const getNumber = (text) => text.match(/\d+/)[0];

const FairWeekSubcomponent = ({
    week,
    row,
    fairProducts,
    addProduct,
    setFairProducts,
    getInputBorderWidth,
    getInputBorderColor,
    getInputColor,
    lastWeek,
    firstWeek,
    pageSize,
    intl,
    loadingState,
}) => {
    const loadStateKey = `${row.original.product.id}-${week.week_date}`;

    return (
        <div className="fair-subcomponent-week" key={week.week_date}>
            <div>
                <span>
                    {intl.formatMessage({ id: 'fair.week_abbreviation' }) +
                        week.week_name.slice(1)}
                </span>
                {
                    <>
                        {loadingState.hasOwnProperty(loadStateKey) &&
                            loadingState[loadStateKey] && (
                                <span style={{ position: 'absolute' }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Spinner width={13} height={13} />
                                </span>
                            )}
                        {loadingState.hasOwnProperty(loadStateKey) &&
                        loadingState[loadStateKey] ? (
                            <input
                                disabled
                                id={`${row.index}-${getNumber(week.week_name)}`}
                                key={fairProducts[row.index].product.id}
                                style={{
                                    backgroundColor: getInputColor(
                                        week.promo_type
                                    ),
                                    borderColor: getInputBorderColor(
                                        week.promo_type
                                    ),
                                    borderWidth: getInputBorderWidth(
                                        week.promo_type
                                    ),
                                }}
                                value={
                                    fairProducts[
                                        row.index
                                    ].fair_product_promotions.find(
                                        (it) => it.week_date === week.week_date
                                    ).amount
                                }
                                type="text"
                                readOnly={week.promo_type === 'S'}
                                onKeyPress={(e) => {
                                    let keynum;

                                    if (window.e) {
                                        // IE
                                        keynum = e.keyCode;
                                    } else if (e.which) {
                                        // Netscape/Firefox/Opera
                                        keynum = e.which;
                                    }

                                    if (keynum < 48 || keynum > 57) {
                                        e.preventDefault();
                                    }

                                    if (keynum === 13) {
                                        const nextInputId = `${
                                            week.week_name ===
                                            lastWeek.week_name
                                                ? `${row.index + 1}-${getNumber(
                                                      firstWeek.week_name
                                                  )}`
                                                : `${row.index}-${
                                                      parseInt(
                                                          getNumber(
                                                              week.week_name
                                                          ),
                                                          10
                                                      ) + 1
                                                  }`
                                        }`;
                                        const nextInput =
                                            document.getElementById(
                                                nextInputId
                                            );
                                        return (
                                            !(
                                                pageSize === row.index + 1 &&
                                                week.week_name ===
                                                    lastWeek.week_name
                                            ) && nextInput.focus()
                                        );
                                    }
                                }}
                                onBlur={(e) =>
                                    addProduct(
                                        week.week_date,
                                        row.original.product.id,
                                        e.target.value
                                    )
                                }
                                onChange={(e) => {
                                    row.original.fair_product_promotions.find(
                                        (it) => it.week_date === week.week_date
                                    ).amount = Number(e.target.value);
                                    setFairProducts(fairProducts);
                                }}
                            />
                        ) : (
                            <input
                                id={`${row.index}-${getNumber(week.week_name)}`}
                                key={fairProducts[row.index].product.id}
                                style={{
                                    backgroundColor: getInputColor(
                                        week.promo_type
                                    ),
                                    borderColor: getInputBorderColor(
                                        week.promo_type
                                    ),
                                    borderWidth: getInputBorderWidth(
                                        week.promo_type
                                    ),
                                }}
                                value={
                                    fairProducts[
                                        row.index
                                    ].fair_product_promotions.find(
                                        (it) => it.week_date === week.week_date
                                    ).amount
                                }
                                type="text"
                                readOnly={week.promo_type === 'S'}
                                onKeyPress={(e) => {
                                    let keynum;

                                    if (window.e) {
                                        // IE
                                        keynum = e.keyCode;
                                    } else if (e.which) {
                                        // Netscape/Firefox/Opera
                                        keynum = e.which;
                                    }

                                    if (keynum < 48 || keynum > 57) {
                                        e.preventDefault();
                                    }

                                    if (keynum === 13) {
                                        const nextInputId = `${
                                            week.week_name ===
                                            lastWeek.week_name
                                                ? `${row.index + 1}-${getNumber(
                                                      firstWeek.week_name
                                                  )}`
                                                : `${row.index}-${
                                                      parseInt(
                                                          getNumber(
                                                              week.week_name
                                                          ),
                                                          10
                                                      ) + 1
                                                  }`
                                        }`;
                                        const nextInput =
                                            document.getElementById(
                                                nextInputId
                                            );
                                        return (
                                            !(
                                                pageSize === row.index + 1 &&
                                                week.week_name ===
                                                    lastWeek.week_name
                                            ) && nextInput.focus()
                                        );
                                    }
                                }}
                                onBlur={(e) =>
                                    addProduct(
                                        week.week_date,
                                        row.original.product.id,
                                        e.target.value
                                    )
                                }
                                onChange={(e) => {
                                    row.original.fair_product_promotions.find(
                                        (it) => it.week_date === week.week_date
                                    ).amount = Number(e.target.value);
                                    setFairProducts(fairProducts);
                                }}
                            />
                        )}
                    </>
                }
            </div>
        </div>
    );
};

export default injectIntl(FairWeekSubcomponent);
