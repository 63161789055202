import { fetchUserData } from '../actions/routines';

const initialState = {
    data: null,
    loading: true,
    error: null,
};

const userDetails = (state = initialState, action) => {
    switch (action.type) {
        case fetchUserData.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case fetchUserData.SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case fetchUserData.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchUserData.FULFILL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default userDetails;
