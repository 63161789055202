import PropTypes from 'prop-types';
import React from 'react';
import TitleHeader from '../components/TitleHeader';
import { accountGrey } from '../../../assets/images/icons';
import { Col, Row } from 'mdbreact';
import SectionTitle from './components/SectionTitle';
import NewPassword from './components/NewPassword';
import { FormattedMessage } from 'react-intl';
import { FormattedHtmlMessage } from '../../Utils/FormattedHtmlMessage';
import DepartmentContainer from './components/Department/DepartmentContainer';
import NewPasswordFieldsContainer from '../../Security/NewPasswordFields/NewPasswordFieldsContainer';
import Spinner from '../../Utils/Spinner';

const MyAccount = ({
    data,
    loading,
    departments,
    isNewPasswordSent,
    onNewPasswordSubmit,
    isNewPasswordLoading,
}) => {
    const normalAddress = (data?.current_customer?.addresses || []).filter(
        (address) => address.type === 1
    );
    const invoiceAddress = (data?.current_customer?.addresses || []).filter(
        (address) => address.type === 2
    );

    return (
        <div className="my-account">
            <TitleHeader icon={accountGrey} title="my_account.title" />
            {loading ? (
                <Spinner className="spinner--brand" width={50} height={50} />
            ) : (
                <div>
                    <p className="my-account__representative">
                        <FormattedMessage id="my_account.representative" />{' '}
                        {data?.representative?.name}, M.{' '}
                        {data?.representative?.phone},{' '}
                        <a
                            className="my-account__email-clickable"
                            href={`mailto:${data?.representative?.email}`}
                        >
                            {data?.representative?.email}
                        </a>
                    </p>
                    <Row>
                        {normalAddress.length > 0 && (
                            <React.Fragment>
                                <Col>
                                    <SectionTitle title="my_account.delivery_address" />
                                    <div className="my-account__section">
                                        <p>{normalAddress[0]?.name}</p>
                                        <p>{normalAddress[0]?.street}</p>
                                        <p>
                                            {normalAddress[0]?.zipCode}{' '}
                                            {normalAddress[0]?.city}
                                        </p>
                                        <p>{normalAddress[0]?.country}</p>
                                        <br />
                                        <p>
                                            T{' '}
                                            {
                                                data?.current_customer
                                                    ?.customer_details?.phone
                                            }
                                        </p>
                                        <p>
                                            F.{' '}
                                            {
                                                data?.current_customer
                                                    ?.customer_details?.fax
                                            }
                                        </p>
                                        <br />
                                        <p>{data?.current_customer?.email}</p>
                                    </div>
                                </Col>
                                <Col>
                                    <SectionTitle title="my_account.billing_information" />
                                    {invoiceAddress.length > 0 && (
                                        <div className="my-account__section">
                                            <p>{invoiceAddress[0].name}</p>
                                            <p>{invoiceAddress[0].street}</p>
                                            <p>
                                                {invoiceAddress[0].zipCode}{' '}
                                                {invoiceAddress[0].city}
                                            </p>
                                            <p>{invoiceAddress[0].country}</p>
                                            <br />
                                            <p>{invoiceAddress[0].vat}</p>
                                        </div>
                                    )}
                                </Col>
                            </React.Fragment>
                        )}
                        <Col size="4">
                            <SectionTitle title="my_account.change_password" />
                            <NewPasswordFieldsContainer
                                submit={onNewPasswordSubmit}
                            >
                                <NewPassword
                                    isNewPasswordSent={isNewPasswordSent}
                                    isLoading={isNewPasswordLoading}
                                />
                            </NewPasswordFieldsContainer>
                        </Col>
                    </Row>
                    <p className="my-account__incorrect-data">
                        <FormattedMessage id="my_account.incorrect_data" />{' '}
                        <a
                            className="my-account__email-clickable"
                            href={`mailto:${data.representative.email}`}
                        >
                            <FormattedMessage id="my_account.incorrect_data_email_label" />
                        </a>
                    </p>
                    {/*//TODO: Implement later on. Do not remove*/}
                    {false && (
                        <Row>
                            <Col
                                size="8"
                                className="my-account__departments-access"
                            >
                                <SectionTitle title="my_account.access.title" />
                                <p className="my-account__departments-access__info">
                                    <FormattedHtmlMessage id="my_account.access.info" />
                                </p>
                                {departments.map((department) => (
                                    <DepartmentContainer
                                        key={department.id}
                                        id={department.id}
                                        name={department.name}
                                        password={department.password}
                                    />
                                ))}

                                <p className="my-account__departments-access__incorrect-data">
                                    <FormattedMessage id="my_account.access.incorrect_data" />{' '}
                                    <a
                                        className="my-account__email-clickable"
                                        href={`mailto:${data.representative.email}`}
                                    >
                                        <FormattedMessage id="my_account.incorrect_data_email_label" />
                                    </a>
                                </p>
                            </Col>
                        </Row>
                    )}
                </div>
            )}
        </div>
    );
};

MyAccount.propTypes = {
    billingInformation: PropTypes.array.isRequired,
    companyDetails: PropTypes.array.isRequired,
    departments: PropTypes.array.isRequired,
    isNewPasswordSent: PropTypes.bool.isRequired,
    onNewPasswordSubmit: PropTypes.func.isRequired,
    representative: PropTypes.array.isRequired,
};

export default MyAccount;
