import PropTypes from "prop-types";
import React from "react";
import DeliveryDetailsData from "./DeliveryDetailsData";
import { Col, Row } from "mdbreact";

const DeliveryDetails = ({
    products,
    invoice,
    deliveryAt,
    title,
    sendRetourForm,
    retour,
    resetRetourError,
    orderNumber,
    handleUpdateOrderStatus,
}) => {
    return (
        <Row className="delivery__details">
            <Col size="9">
                {products && (
                    <DeliveryDetailsData
                        delivery={products}
                        title={title}
                        deliveryAt={deliveryAt}
                        sendRetourForm={sendRetourForm}
                        retour={retour}
                        resetRetourError={resetRetourError}
                        orderNumber={orderNumber}
                        handleUpdateOrderStatus={handleUpdateOrderStatus}
                    />
                )}
                {/*@TODO F20-110 LEAVE FOR NOW*/}
                {/*{products &&*/}
                {/*<DeliveryDetailsData*/}
                {/*delivery={products}*/}
                {/*title="delivery.details.planned"*/}
                {/*/>*/}
                {/*}*/}
            </Col>
            {/*@TODO F20-110 LEAVE FOR NOW*/}
            {/*<Col size="3">*/}
            {/*<a href={invoice.url} target="_blank" className="btn btn-black btn-sm btn-flat">*/}
            {/*{invoice.name}*/}
            {/*</a>*/}
            {/*</Col>*/}
        </Row>
    );
};

DeliveryDetails.propTypes = {
    invoice: PropTypes.array,
    products: PropTypes.array,
    deliveryAt: PropTypes.string,
    title: PropTypes.string,
};

export default DeliveryDetails;
