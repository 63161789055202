import PropTypes from 'prop-types'
import React from 'react';
import {notificationGrey} from "../../../assets/images/icons";
import TitleHeader from "../components/TitleHeader";
import Table from "../../Table/Table";
import {FormattedMessage} from "react-intl";

const Notifications = ({
    notifications, readNotifications, notificationsColumns, readNotificationsColumns, notificationsPageSize,
    readNotificationsPageSize, loading
}) => {
    return (
        <div className="notifications-page">
            <TitleHeader title="notifications.title" icon={notificationGrey}/>
            {notificationsPageSize > 0 &&
            <div className="notifications-page__unread">
                <h3 className="notifications-page__table-title"><FormattedMessage id="notifications.unread"/></h3>
                <Table
                    loading={loading}
                    data={notifications}
                    columns={notificationsColumns}
                    pageSize={notificationsPageSize}
                    itemsCount={notificationsPageSize}
                    filterable={false}
                    withStickyHeader={false}
                />
            </div>
            }
            {readNotificationsPageSize > 0 &&
            <div className="notifications-page__read">
                <h3 className="notifications-page__table-title"><FormattedMessage id="notifications.read"/></h3>
                <Table
                    loading={loading}
                    data={readNotifications}
                    columns={readNotificationsColumns}
                    pageSize={readNotificationsPageSize < 50 ? readNotificationsPageSize : 50}
                    itemsCount={readNotificationsPageSize}
                    filterable={false}
                    withStickyHeader={false}
                />
            </div>
            }
        </div>
    );
};

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  notificationsColumns: PropTypes.array.isRequired,
  notificationsPageSize: PropTypes.number.isRequired,
  readNotifications: PropTypes.array.isRequired,
  readNotificationsColumns: PropTypes.array.isRequired,
  readNotificationsPageSize: PropTypes.number.isRequired
};

export default Notifications;
