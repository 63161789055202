import { fetchInvoices } from '../actions/routines';

const invoicesState = {
    data: [],
    loading: true,
    error: null,
    loaded: false,
};
export const invoices = (state = invoicesState, action) => {
    switch (action.type) {
        case fetchInvoices.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case fetchInvoices.SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loaded: true,
            };
        case fetchInvoices.FAILURE:
            return {
                ...state,
                error: action.payload.data,
            };
        case fetchInvoices.FULFILL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
