import PropTypes from 'prop-types'
import React from "react";
import {FormattedMessage} from "react-intl";

const SelectedFilterLabel = ({filter}) => {
    if (filter.type === 'allergens') {
        return <FormattedMessage id={`allergens.${filter.name}.full`}/>
    }

    return <React.Fragment><FormattedMessage id={`filters.type.${filter.type}`}/> {filter.name}</React.Fragment>
};

export default SelectedFilterLabel;

SelectedFilterLabel.propTypes = {
  filter: PropTypes.object.isRequired
}