import { localeNl } from './locales/nl';
import { localeFr } from './locales/fr';

const initialState = {
    lang: localeNl.lang,
    messages: localeNl.messages
};

export const locale = (state = initialState, action) => {
    switch (action.type) {
        case 'LOCALE_SELECTED':
            switch (action.locale) {
                case 'fr':
                    return { ...initialState, lang: localeFr.lang, messages: localeFr.messages };
                default:
                    return { ...initialState, lang: localeNl.lang, messages: localeNl.messages };
            }
        default:
            return state;
    }
};