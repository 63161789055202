import { fetchNoveltiesProducts } from '../actions/routines';
import { call, put } from 'redux-saga/effects';
import Novelty from '../services/api/Novelty';

export function* fetchNoveltiesProductsSaga(action) {
    try {
        yield put(fetchNoveltiesProducts.request());

        const response = yield call(
            Novelty.getNoveltiesProducts,
            action.payload
        );

        yield put(
            fetchNoveltiesProducts.success({
                data: response.data,
                hasMore: JSON.parse(response.headers['x-has-more']),
            })
        );
    } catch (error) {
        yield put(fetchNoveltiesProducts.failure(error.message));
    } finally {
        yield put(fetchNoveltiesProducts.fulfill());
    }
}
