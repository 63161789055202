import React from 'react';
import { connect } from 'react-redux';
import Novelty from './Novelty';
import { resetHasMore } from '../../../actions/novelty';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { fetchNoveltiesProductsPromiseCreator } from '../../../actions/routines';
import * as _ from 'lodash';
import { showProductDetails } from '../../../actions/productDetails';
import { toggleProductFavorite } from '../../../actions/routines';

class NoveltyContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            page: 1,
            limit: 10,
        };
    }
    componentDidMount() {
        this.loadMore();
    }

    loadMore = () => {
        const { limit, page } = this.state;

        const params = { limit, page };

        this.setState({
            page: this.state.page + 1,
        });

        this.props
            .fetchNoveltiesProductsPromiseCreator({ params })
            .then((data) => {
                this.setState((prevState) => {
                    return {
                        products: [...prevState.products, ...data.data],
                    };
                });
            });
    };

    openProductDetails = (id) => {
        const { showProductDetails, deliveryDate } = this.props;

        showProductDetails(id, deliveryDate);
    };

    toggleProductFavorite = (id) => {
        const { toggleProductFavorite } = this.props;

        toggleProductFavorite(id);

        const products = this.state.products.map((product) => {
            if (product.id === id) {
                product.isFavourite = !product.isFavourite;
            }

            return product;
        });

        this.setState({ products });
    };

    render() {
        const { loading, hasMore } = this.props;

        const uniqueProducts = _.uniqWith(this.state.products, _.isEqual);

        return (
            <Novelty
                products={uniqueProducts}
                hasMore={hasMore}
                isLoading={loading}
                loadMore={this.loadMore}
                title="menu.novelty"
                openProductDetails={this.openProductDetails}
                toggleProductFavorite={this.toggleProductFavorite}
            />
        );
    }
}

NoveltyContainer.protoTypes = {
    fetchNoveltiesProductsPromiseCreator: PropTypes.func,
    hasMore: PropTypes.bool,
    loading: PropTypes.bool,
};

NoveltyContainer.defaultProps = {
    hasMore: true,
};

const mapStateToProps = (state) => {
    return {
        loading: state.noveltiesProducts.loading,
        hasMore: state.noveltiesProducts.hasMore,
        deliveryDate: state.deliveryDatepicker.deliveryDate,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            { resetHasMore, showProductDetails, toggleProductFavorite },
            dispatch
        ),
        ...bindPromiseCreators(
            { fetchNoveltiesProductsPromiseCreator },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoveltyContainer);
