import styles from './ProductGroups.module.scss';

const ProductGroups = ({ selectedGroup, groups, onGroupFilter }) => {
    if (!groups || !groups.length) {
        return null;
    }

    return (
        <div className={styles.container}>
            {!!selectedGroup && (
                <div className={styles.breadcrumbs}>
                    <span className={styles.breadcrumbItem}>
                        <span className={styles.name.first}>
                            {
                                groups.filter(
                                    (group) => group.id === selectedGroup
                                )[0]?.name
                            }
                        </span>
                        [
                        <span
                            onClick={() => onGroupFilter(0)}
                            className={styles.name}
                        >
                            X
                        </span>
                        ]
                    </span>
                </div>
            )}

            <div className={styles.categoryMenu}>
                <nav className={styles.categoryItem}>
                    <ol className={styles.subcategories}>
                        {groups.map((group, index) => (
                            <li className={styles.categoryItemList}>
                                <button
                                    key={group.id}
                                    className={styles.subcategoryButton}
                                    onClick={() => onGroupFilter(group.id)}
                                >
                                    {group.name} ({group.products_count})
                                </button>
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>

            <div className={styles.productGroupsSeparator} />
        </div>
    );
};

export default ProductGroups;
