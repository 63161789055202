import PropTypes from 'prop-types'
import React from "react";
import {
  Button,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  Modal,
  ModalBody,
  ModalHeader
} from "mdbreact";
import { injectIntl } from 'react-intl';
import { FormattedHtmlMessage } from '../Utils/FormattedHtmlMessage';

const FairDynamicWeeksModal = ({
  dynamicWeeksOptions,
  isOpen,
  closeModal,
  addDynamicWeekHandler,
  selectedDynamicWeek,
  handleSelectDynamicWeek,
  intl
}) => {
  return (
    <Modal className={`fair-dynamic-weeks-modal`} isOpen={isOpen} toggle={closeModal}>
      <span onClick={closeModal} className="modal-close"/>
      <ModalHeader>
        <FormattedHtmlMessage id="fair.dynamic_weeks.modal.title" />
      </ModalHeader>
      <ModalBody>
        <FormattedHtmlMessage id="fair.dynamic_weeks.modal.text" />
        <MDBSelect getValue={handleSelectDynamicWeek} className="fair-dynamic-week-select">
          <MDBSelectInput className="fair-dynamic-week-select__input" selected={intl.formatMessage({id: 'fair.dynamic_weeks.modal.drop_down'})} />
          <MDBSelectOptions className="fair-dynamic-week-select__options">
            {dynamicWeeksOptions.map(dynamicWeeksOption => (
              <MDBSelectOption key={dynamicWeeksOption.week}
                               value={dynamicWeeksOption.year + '-' + dynamicWeeksOption.week}
                               selected={selectedDynamicWeek === dynamicWeeksOption.year + '-' + dynamicWeeksOption.week} >
                <FormattedHtmlMessage id="fair.week_abbreviation" />{dynamicWeeksOption.week + ' (' + dynamicWeeksOption.year + ')'}
              </MDBSelectOption>
            ))}
          </MDBSelectOptions>
        </MDBSelect>
        <Button size="md" className="btn btn-main" disabled={!selectedDynamicWeek} onClick={() => addDynamicWeekHandler(selectedDynamicWeek)}>
          <FormattedHtmlMessage id="fair.dynamic_weeks.modal.add_button" />
        </Button>
      </ModalBody>
    </Modal>
  );
};

FairDynamicWeeksModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  dynamicWeeksOptions: PropTypes.array.isRequired,
  addDynamicWeekHandler: PropTypes.func.isRequired
};

export default injectIntl(FairDynamicWeeksModal);
