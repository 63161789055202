import placeholderImage from '../../../assets/images/image-placeholder.svg';

const ProductPhoto = ({ product, className = '' }) => {
    const imgSrc =
        product.images && product.images.length
            ? product.images[0].url
            : placeholderImage;

    if (!imgSrc) {
        return '';
    }

    return <img className={className} src={imgSrc} alt={product.name} />;
};

export default ProductPhoto;
