import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardTitle } from 'mdbreact';

const FileBox = ({ name, link, image, handleDownload, inline = false }) => {
    return inline ? (
        <a
            href={link}
            target="_blank"
            className="product-details__file-box__link inline"
            rel="noreferrer"
            onClick={handleDownload ? handleDownload : null}
        >
            <button className="product-details__file-box">PDF</button>
            <span>{name}</span>
        </a>
    ) : (
        <Card
            className="product-details__file-box"
            onClick={handleDownload ? handleDownload : null}
        >
            {link && (
                <a
                    href={link}
                    target="_blank"
                    className="product-details__file-box__link"
                    rel="noreferrer"
                />
            )}
            <div className="product-details__file-box__image-wrapper">
                <img src={image.path} alt={name} />
            </div>
            <CardBody>
                <CardTitle className="product-details__file-box__title">
                    {name}
                </CardTitle>
            </CardBody>
        </Card>
    );
};

FileBox.propTypes = {
    image: PropTypes.object,
    link: PropTypes.string,
    name: PropTypes.string,
};

export default FileBox;
