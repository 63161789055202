import { fetchInvoices } from '../actions/routines';
import { call, put } from 'redux-saga/effects';
import Invoice from '../services/api/Invoice';

export function* fetchInvoicesSaga(action) {
    try {
        yield put(fetchInvoices.request());

        const response = yield call(Invoice.getHistory, action.payload);

        yield put(
            fetchInvoices.success({
                data: response.data,
            })
        );
    } catch (error) {
        yield put(fetchInvoices.failure(error.message));
    } finally {
        yield put(fetchInvoices.fulfill());
    }
}
