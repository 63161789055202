import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toggleProductFavorite } from '../../actions/routines';
import { bindActionCreators } from 'redux';

class FavouriteIcon extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFavourite: this.props.isFavourite,
        };
    }

    toggleFavourite = () => {
        if (this.props.type === 'product') {
            const { id, toggleProductFavorite } = this.props;

            toggleProductFavorite(id);
        }

        this.setState((prevState) => {
            return { isFavourite: !prevState.isFavourite };
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isFavourite !== this.props.isFavourite) {
            this.setState({
                isFavourite: this.props.isFavourite,
            });
        }
    }

    render() {
        const { onClick, type } = this.props;

        if (type !== 'product') {
            return '';
        }

        return (
            <div className="favourite-icon">
                {
                    <i
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            }

                            this.toggleFavourite();
                        }}
                        className={`favourite-icon__icon ${
                            this.state.isFavourite
                                ? 'favourite-icon--is-favourite'
                                : ''
                        }`}
                    />
                }
            </div>
        );
    }
}

FavouriteIcon.propTypes = {
    handleClick: PropTypes.func,
    isFavourite: PropTypes.bool,
};

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators({ toggleProductFavorite }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteIcon);
