import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const LanguageSwitcherFooter = (props) => (
    <div className="language-switcher language-switcher--footer">
        <span
            onClick={() => props.selectLocale('nl')}
            className={props.locale !== 'nl' ? 'language-switcher--active' : ''}
        >
            NEDERLANDS
        </span>
        <span className="language-switcher--footer__separator">|</span>
        <span
            onClick={() => props.selectLocale('fr')}
            className={props.locale !== 'fr' ? 'language-switcher--active' : ''}
        >
            FRANÇAIS
        </span>
        <span className="language-switcher--footer__separator">|</span>
        <span>
            <Link to="/disclaimer">
                <FormattedMessage id="footer.disclaimer" />
            </Link>
        </span>
        <span className="language-switcher--footer__separator">|</span>
        <span>
            <Link to="/cookie-policy">
                <FormattedMessage id="footer.cookie_policy" />
            </Link>
        </span>
        <span className="language-switcher--footer__separator">|</span>
        <span>
            <Link to="/responsible-disclosure-policy">
                <FormattedMessage id="footer.responsible_disclosure_policy" />
            </Link>
        </span>
    </div>
);

LanguageSwitcherFooter.propTypes = {
    locale: PropTypes.string.isRequired,
    selectLocale: PropTypes.func.isRequired,
};

export default LanguageSwitcherFooter;
