import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { invoicesGrey } from '../../../assets/images/icons';
import SearchContainer from './components/SearchContainer';
import TitleHeader from '../components/TitleHeader';
import React from 'react';
import moment from 'moment/moment';
import DownloadDocumentsContainer from './components/DownloadDocuments/DownloadDocumentsContainer';
import Table from '../../Table/Table';

const Invoice = ({ loading, data }) => {
    const getColumns = () => [
        {
            id: 'invoice_id',
            Header: <FormattedMessage id="table.header.invoice_id" />,
            accessor: 'invoice_id',
            width: 110,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__invoice-id',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__invoice-id',
        },
        {
            id: 'invoice_number',
            Header: <FormattedMessage id="table.header.invoice_number" />,
            accessor: 'invoice_number',
            width: 150,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__invoice-number',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__invoice-number',
        },
        {
            id: 'status',
            Header: <FormattedMessage id="table.header.invoice_status" />,
            accessor: 'status',
            width: 90,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__status',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__status',
        },
        {
            id: 'date',
            Header: <FormattedMessage id="table.header.date" />,
            accessor: 'formatDate',
            width: 110,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__order-date',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__order-date',
            Cell: (row) => moment(row.value).format('DD/MM/YYYY'),
        },
        {
            id: 'amount',
            Header: <FormattedMessage id="table.header.invoice_amount" />,
            accessor: 'amount',
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__amount',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__amount',
            Cell: (row) => (
                <FormattedMessage
                    id={'invoice.price_label'}
                    values={{ price: row.value }}
                />
            ),
        },
        {
            id: 'reference',
            Header: <FormattedMessage id="table.header.reference" />,
            accessor: 'reference',
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__reference',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__reference',
        },
        {
            id: 'type',
            Header: <FormattedMessage id="table.header.invoice_type" />,
            accessor: 'type',
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__customer-type',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__customer-type',
            Cell: (row) => (
                <FormattedMessage id={`invoice.type_${row.value}`} />
            ),
        },
        {
            id: 'documents',
            Header: <FormattedMessage id="table.header.documents" />,
            accessor: 'documents2',
            width: 100,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__documents',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__documents',
            Cell: (row) =>
                row.original.has_pdf && (
                    <DownloadDocumentsContainer
                        invoiceId={row.original.invoice_id}
                    />
                ),
        },
    ];

    return (
        <div className="invoice">
            <TitleHeader icon={invoicesGrey} title="invoice.title" />
            <Table
                columns={getColumns()}
                data={data}
                withStickyHeader={false}
                pageSize={data.length}
                itemsCount={data.length}
                showPagination={false}
                filterable={false}
                loading={loading}
            />
        </div>
    );
};

Invoice.propTypes = {
    data: PropTypes.array.isRequired,
};

export default injectIntl(Invoice);
