import React from 'react';
import PropTypes from 'prop-types';
import searchGrey from "../../../../assets/images/search-grey.svg";
import {FormattedMessage} from "react-intl";
import Disclaimer from "../../../Modal/Disclaimer/Disclaimer";
import {Col, Row} from "mdbreact";
import ButtonWithSpinner from "../../../Utils/ButtonWithSpinner";

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDisclaimerOpen: false
        }
    }

    toggleDisclaimer = () => {
        this.setState(prevState => {
            return {
                isDisclaimerOpen: !prevState.isDisclaimerOpen
            }
        })
    };

    render() {
        const {toggleFilterable, handleDownloadAllergenListPdf, isDownloadLoading} = this.props;
        return (
            <div className="allergens__table-header">
                <Row>
                    <Col>
                        <FormattedMessage id="allergens.legend.label"/>: <span onClick={this.toggleDisclaimer} className="allergens__table-header__disclaimer__btn">Disclaimer</span>
                        <p><FormattedMessage id="allergens.legend.allergens_abv"/></p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="allergens__table-header__col">
                            <img onClick={toggleFilterable} src={searchGrey} alt="search" className="table__header__search"/>
                        </div>
                        <ButtonWithSpinner
                            className="btn btn-brand btn-flat"
                            onClick={handleDownloadAllergenListPdf}
                            label="allergens.pdf.download_btn"
                            isLoading={isDownloadLoading}
                            appendLoader={true}
                            size="sm"
                            height="0.7rem"
                            width="0.7rem"
                        />
                    </Col>
                    <Col className="allergens__table-header__legend">
                        <span className="allergens__table-header__legend__icon allergens__not-contain"/> <FormattedMessage id="allergens.legend.not_contain"/>
                        <span className="allergens__table-header__legend__icon allergens__can-contain"/> <FormattedMessage id="allergens.legend.can_contain"/>
                        <span className="allergens__table-header__legend__icon allergens__contain"/> <FormattedMessage id="allergens.legend.contain"/>
                    </Col>
                </Row>
                <Disclaimer
                    title={<FormattedMessage id="allergens.disclaimer.title"/>}
                    toggle={this.toggleDisclaimer}
                    isOpen={this.state.isDisclaimerOpen}
                >
                    <p><FormattedMessage id="allergens.disclaimer.p1"/></p>
                    <p><FormattedMessage id="allergens.disclaimer.p2"/></p>
                    <p><FormattedMessage id="allergens.disclaimer.p3"/></p>
                    <p><FormattedMessage id="allergens.disclaimer.p5"/></p>
                </Disclaimer>
            </div>
        );
    }
}

Header.propTypes = {
    toggleFilterable: PropTypes.func
};

export default Header;
