import React from 'react';
import { Col, Row } from 'mdbreact';
import logo from '../../../assets/images/huppa_logo.svg';

const Header = () => {
    return (
        <Row>
            <Col>
                <img
                    className="login__logo"
                    src={logo}
                    alt="logo"
                    height={68}
                    width={368}
                />
            </Col>
        </Row>
    );
};

export default Header;
