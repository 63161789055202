export const loginUser = (data) => ({
    type: 'LOGIN_USER',
    data
});

export const userLoggedIn = (data) => ({
    type: 'USER_LOGGED_IN',
    data
});

export const loginUserFailure = (error) => ({
    type: 'LOGIN_USER_FAILURE',
    error
});

export const logoutUser = () => ({
    type: 'LOGOUT_USER',
});

export const userLoggedOut = () => ({
    type: 'USER_LOGGED_OUT',
});

export const requestNewPassword = (login) => ({
    type: 'NEW_PASSWORD_REQUEST',
    login
});

export const requestNewPasswordFailure = (error) => ({
    type: 'NEW_PASSWORD_REQUEST_FAILURE',
    error
});

export const sendNewPasswordResetToken = (token, password, passwordRepeat) => ({
    type: 'NEW_PASSWORD_SEND_RESET_TOKEN',
    data: {
        token, password, passwordRepeat
    }
});

export const sendNewPasswordFailure = (error) => ({
    type: 'NEW_PASSWORD_SEND_FAILURE',
    error
});