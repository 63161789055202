import PropTypes from 'prop-types'
import React from "react";

const AllergenCell = ({value}) => {
    switch(value) {
        case 1:
            return <span className="allergens__not-contain"/> ;
        case 2:
            return <span className="allergens__can-contain"/> ;
        case 3:
            return <span className="allergens__contain"/> ;
        default:
            return '';
    }
};

AllergenCell.propTypes = {
  value: PropTypes.string
};

export default AllergenCell;
