import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import React from "react";

const ItemLink = ({link, isExternal}) => {
    if (isExternal) {
      return <a className="item-box__link" href={link} target="_blank"/>
    }

    return <Link className="item-box__link" to={link}/>;
};

ItemLink.propTypes = {
  isExternal: PropTypes.bool,
  link: PropTypes.string
};

export default ItemLink;
