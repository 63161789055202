import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl';

const flattenMessages = (nestedMessages, prefix = '') => Object.keys(nestedMessages).reduce((messages, key) => {
  const value = nestedMessages[key];
  const prefixedKey = prefix ? `${prefix}.${key}` : key;

  if (typeof value === 'string') {
    messages[prefixedKey] = value;
  } else {
    Object.assign(messages, flattenMessages(value, prefixedKey));
  }

  return messages;
}, {});

const mapStateToProps = (state) => {
  const {lang, messages} = state.locale;

  return {locale: lang, key: lang, messages: flattenMessages(messages)};
};

const DefaultIntlProvider = props => (
  <IntlProvider
    {...props}
    defaultRichTextElements={{
      u: (chunks) => <u>{chunks}</u>,
      i: (chunks) => <i>{chunks}</i>,
      strong: (chunks) => <strong>{chunks}</strong>,
      b: (chunks) => <strong>{chunks}</strong>,
      // br doesnt work here, we have to provide default values (see ./components/Utils/FormattedHtmlMessage.js)
      // https://github.com/formatjs/formatjs/issues/1597
      br: () => <br/>,
    }}
  />
);

export default connect(mapStateToProps)(DefaultIntlProvider);