import PropTypes from 'prop-types'
import React from 'react';
import Downloads from "./Downloads";
import {connect} from "react-redux";
import {
    fetchDownloadCenterFoldersPromiseCreator,
    fetchDownloadCenterFilesPromiseCreator, searchDownloadCenterFiles
} from "../../../actions/routines";
import {bindPromiseCreators} from "redux-saga-routines";
import * as qs from "qs";
import history from "../../../history";
import {bindActionCreators} from "redux";

class DownloadsContainer extends React.Component {
    constructor(props) {
        super(props);

        const firstBreadcrumb = {
            name: 'START',
            path: '/downloads'
        };

        this.state = {
            items: [],
            firstBreadcrumb: firstBreadcrumb,
            breadcrumbs: [firstBreadcrumb],
            hasMore: true,
            isLoading: false
        }
    }

    componentDidMount() {
        const { match: { params: { folderId } } } = this.props;
        const queryParams = qs.parse(history.location.search.slice(1));

        if (!folderId) {
            this.fetchFolders();
        } else if (queryParams['s_all']) {
            this.searchFiles(queryParams);
        } else {
            this.fetchFiles(folderId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { match: { params: { folderId } } } = this.props;

        if (prevProps.match.params.folderId && !folderId) {
            this.fetchFolders();
        }

        if (folderId && prevProps.match.params.folderId !== folderId) {
            this.fetchFiles(folderId);
        }
    }

    fetchFolders = () => {
        this.props.fetchDownloadCenterFoldersPromiseCreator().then(() => {
            this.setState({
                breadcrumbs: [this.state.firstBreadcrumb]
            })
        });
    };

    fetchFiles = (folderId) => {
        this.props.fetchDownloadCenterFilesPromiseCreator(folderId).then((data) => {
            this.setState({
                breadcrumbs: [this.state.firstBreadcrumb, {name: data.name, path: `/downloads/${folderId}`}]
            })
        });
    };

    searchFiles = (queryParams) => {
        this.props.searchDownloadCenterFiles(queryParams)
    };

    render() {
        const {items, type, isLoading, match: { params: { folderId } } } = this.props;

        return (
            <Downloads folderId={folderId} items={items} type={type} isLoading={isLoading} breadcrumbs={this.state.breadcrumbs} />
        );
    }
}

DownloadsContainer.propTypes = {
    fetchDownloadCenterFilesPromiseCreator: PropTypes.func,
    fetchDownloadCenterFoldersPromiseCreator: PropTypes.func,
    items: PropTypes.array,
};

DownloadsContainer.defaultProps = {
    items: [],
};

const mapStateToProps = state => {
    return {
        items: state.downloadCenter.data,
        type: state.downloadCenter.type,
        isLoading: state.downloadCenter.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindPromiseCreators({
            fetchDownloadCenterFoldersPromiseCreator, fetchDownloadCenterFilesPromiseCreator
        }, dispatch),
        ...bindActionCreators({
            searchDownloadCenterFiles
        }, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadsContainer);

