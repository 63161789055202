const validatePasswordFormat = (password) => {
    // eslint-disable-next-line no-useless-escape
    return /^(?=[a-zA-Z0-9#!^&%*()\[\]\-_{};:'"\\|<>,./+=@$?]{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).*/.test(password);
};

const validatePasswordEquals = (password, passwordRepeat) => {
    return password === passwordRepeat;
};

export {
    validatePasswordFormat, validatePasswordEquals
}