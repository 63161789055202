import React from 'react';
import RequestPassword from "./RequestPassword";
import {connect} from "react-redux";
import {requestNewPassword} from "../../../../../actions/security";

class RequestPasswordContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clientId: '',
            error: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            error: nextProps.error
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.requestNewPassword(this.state.clientId);

    };

    handleOnChange = (e) => {
        this.setState({...this.state, [e.target.name]: e.target.value})
    };

    render() {
        return <RequestPassword
            clientId={this.state.clientId}
            error={this.state.error}
            isLoading={this.props.isLoading}
            onChange={this.handleOnChange}
            onSubmit={this.handleSubmit}
        />
    }
}

const mapStateToProps = (state) => {
    return {
        error: state.apiErrors.security,
        isLoading: state.loadingState.loading
    }
};

export default connect(mapStateToProps, {requestNewPassword: requestNewPassword})(RequestPasswordContainer);
