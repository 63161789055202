import React, {Component} from "react";
import {FormattedMessage} from "react-intl";
import {MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions} from "mdbreact";

class SupplierSelect extends Component {
    render() {
        const {selectedSupplier, suppliers, handleSelectSupplier} = this.props;
        return (
            <MDBSelect getValue={handleSelectSupplier} className="fair-supplier-select">
                <MDBSelectInput className="fair-supplier-select__input"/>
                <MDBSelectOptions search={true} className="fair-supplier-select__options">
                    <MDBSelectOption className="all-suppliers" value="all-suppliers"
                                     selected={selectedSupplier}>
                        <FormattedMessage id="fair.filters.select.all_suppliers"/>
                    </MDBSelectOption>
                    {suppliers.map(supplier => (
                        <MDBSelectOption key={supplier} value={supplier} selected={selectedSupplier === supplier}>
                            {supplier}
                        </MDBSelectOption>
                    ))}

                </MDBSelectOptions>
            </MDBSelect>
        );
    }
}

export default SupplierSelect;
