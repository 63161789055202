import PropTypes from 'prop-types'
import React from "react";
import {Route as BaseRoute} from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {Container} from "mdbreact";

const HeaderFooterRoute = ({ component: Component, withHeader, withFooter, fluid = false, ...props }) => (
    <BaseRoute
        {...props}
        render={renderProps => (
            <div>
                { withHeader && <Header {...renderProps}/>}
                <Container fluid={fluid} className={withHeader && 'container--fixed-header'}>
                    <Component {...renderProps} />
                    { withFooter && <Footer />}
                </Container>
            </div>
        )}
    />
);

HeaderFooterRoute.propTypes = {
  withFooter: PropTypes.bool,
  withHeader: PropTypes.bool
};

export default HeaderFooterRoute;