import React from "react";
import DeliveryDatepicker from "./DeliveryDatepicker";
import { fetchDeliverySchema } from "../../actions/routines";
import { connect } from "react-redux";

class DeliveryDatepickerContainer extends React.Component {
    componentDidMount() {
        this.props.fetchDeliverySchema();
    }

    render() {
        return (
            <DeliveryDatepicker
                enabledDates={this.props.deliveryDates}
                global={this.props.global}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        deliveryDates: state.deliverySchema.data,
        deliveryDatesLoaded: state.deliverySchema.loaded
    };
};

export default connect(
    mapStateToProps,
    { fetchDeliverySchema }
)(DeliveryDatepickerContainer);
