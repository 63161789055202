import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const fetchProductData = createRoutine('FETCH_PRODUCT_DATA');
export const fetchAllergensData = createRoutine('FETCH_ALLERGENS_DATA');
export const fetchUserData = createRoutine('FETCH_USER_DATA');
export const fetchProductCategories = createRoutine('FETCH_PRODUCT_CATEGORIES');

export const fetchActiveCartData = createRoutine('FETCH_ACTIVE_CART_DATA');
export const createOrUpdateActiveCart = createRoutine('CREATE_OR_UPDATE_CART');
export const deleteCart = createRoutine('DELETE_CART');
export const deleteCartPromiseCreator = promisifyRoutine(deleteCart);
export const addItemCart = createRoutine('ADD_ITEM_CART');
export const addItemsCart = createRoutine('ADD_ITEMS_CART');
export const removeItemCart = createRoutine('REMOVE_ITEM_CART');
export const removeItemCartPromiseCreator = promisifyRoutine(removeItemCart);
export const completeCart = createRoutine('COMPLETE_CART');
export const completeCartPromiseCreator = promisifyRoutine(completeCart);

export const fetchDeliverySchema = createRoutine('FETCH_DELIVERY_SCHEMA');
export const fetchDelivers = createRoutine('FETCH_DELIVERS');

export const fetchPromotions = createRoutine('FETCH_PROMOTIONS');

export const fetchProductTopList = createRoutine('FETCH_PRODUCT_TOP_LIST');
export const fetchReadNotifications = createRoutine('FETCH_READ_NOTIFICATIONS');
export const fetchUnreadNotifications = createRoutine(
    'FETCH_UNREAD_NOTIFICATIONS'
);
export const fetchUnreadNormalNotifications = createRoutine(
    'FETCH_UNREAD_NORMAL_NOTIFICATIONS'
);
export const fetchUnreadServiceNotifications = createRoutine(
    'FETCH_UNREAD_SERVICE_NOTIFICATIONS'
);
export const setAsReadNotifications = createRoutine(
    'SET_AS_READ_NOTIFICATIONS'
);
export const setAsReadNotificationsPromiseCreator = promisifyRoutine(
    setAsReadNotifications
);

export const fetchPromotionalModal = createRoutine('FETCH_PROMOTIONAL_MODAL');
export const fetchCampaigns = createRoutine('FETCH_CAMPAIGNS');

export const fetchRecipeCategories = createRoutine('FETCH_RECIPE_CATEGORIES');
export const toggleRecipeFavourites = createRoutine('TOGGLE_RECIPE_FAVOURITES');
export const fetchRecipeListByCategory = createRoutine(
    'FETCH_RECIPE_LIST_BY_CATEGORY'
);
export const fetchRecipeListByCategoryPromiseCreator = promisifyRoutine(
    fetchRecipeListByCategory
);
export const fetchRecipeDetails = createRoutine('FETCH_RECIPE_DETAILS');
export const fetchFavouritesRecipeList = createRoutine(
    'FETCH_FAVOURITES_RECIPE_LIST'
);
export const fetchFavouritesRecipeListPromiseCreator = promisifyRoutine(
    fetchFavouritesRecipeList
);
export const searchRecipes = createRoutine('SEARCH_RECIPES');
export const searchRecipesPromiseCreator = promisifyRoutine(searchRecipes);

export const fetchDownloadCenterFolders = createRoutine(
    'FETCH_DOWNLOAD_CENTER_FOLDERS'
);
export const fetchDownloadCenterFoldersPromiseCreator = promisifyRoutine(
    fetchDownloadCenterFolders
);
export const fetchDownloadCenterFiles = createRoutine(
    'FETCH_DOWNLOAD_CENTER_FILES'
);
export const fetchDownloadCenterFilesPromiseCreator = promisifyRoutine(
    fetchDownloadCenterFiles
);
export const searchDownloadCenterFiles = createRoutine(
    'SEARCH_DOWNLOAD_CENTER_FILES'
);

export const fetchProductOrderHistory = createRoutine(
    'FETCH_PRODUCT_ORDER_HISTORY'
);

export const sendNewPasswordAccessToken = createRoutine(
    'NEW_PASSWORD_SEND_ACCESS_TOKEN'
);
export const sendNewPasswordAccessTokenPromiseCreator = promisifyRoutine(
    sendNewPasswordAccessToken
);

export const fetchFairCarts = createRoutine('FETCH_FAIR_CARTS');
export const fetchFairProducts = createRoutine('FETCH_FAIR_PRODUCTS');
export const fetchFairProductGroups = createRoutine(
    'FETCH_FAIR_PRODUCT_GROUPS'
);
export const fetchFairSuppliers = createRoutine('FETCH_FAIR_SUPPLIERS');
export const fetchFairPoints = createRoutine('FETCH_FAIR_POINTS');
export const fetchFairDynamicWeeksData = createRoutine(
    'FETCH_FAIR_DYNAMIC_WEEKS_DATA'
);
export const addFairDynamicWeek = createRoutine('ADD_FAIR_DYNAMIC_WEEK');
export const addFairDynamicWeekPromiseCreator =
    promisifyRoutine(addFairDynamicWeek);
export const completeFairCart = createRoutine('COMPLETE_FAIR_CART');
export const completeFairCartPromiseCreator =
    promisifyRoutine(completeFairCart);

export const removeFromAssortment = createRoutine('REMOVE_FROM_ASSORTMENT');
export const removeFromAssortmentPromiseCreator =
    promisifyRoutine(removeFromAssortment);

export const sendRetourForm = createRoutine('SEND_RETOUR_FORM');
export const sendRetourFormPromiseCreator = promisifyRoutine(sendRetourForm);

export const fetchNoveltiesProducts = createRoutine('FETCH_NOVELTIES_PRODUCTS');
export const fetchNoveltiesProductsPromiseCreator = promisifyRoutine(
    fetchNoveltiesProducts
);

export const fetchProductSuppliers = createRoutine('FETCH_PRODUCT_SUPPLIERS');
export const fetchInvoices = createRoutine('FETCH_INVOICES');

export const toggleProductFavorite = createRoutine('TOGGLE_PRODUCT_FAVOURITE');

export const fetchTagData = createRoutine('FETCH_TAG_DATA');
