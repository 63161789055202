import PropTypes from 'prop-types';
import React from 'react';
import ButtonWithSpinner from '../../../../Utils/ButtonWithSpinner';

const DownloadDocuments = ({ invoiceId, downloadFile, isDownloadingFile }) => {
    return (
        <div className="invoice__documents">
            <ButtonWithSpinner
                width={11}
                height={11}
                onClick={() => downloadFile('pdf', invoiceId)}
                className="delivery__documents__btn btn btn-red btn-sm btn-flat"
                isLoading={isDownloadingFile.pdf === invoiceId}
                label="PDF"
            />
        </div>
    );
};

DownloadDocuments.propTypes = {
    downloadFile: PropTypes.func,
    invoiceId: PropTypes.string,
    isDownloadingFile: PropTypes.object,
};

export default DownloadDocuments;
