import React from "react";
import {FormattedHtmlMessage} from "../../Utils/FormattedHtmlMessage";
import {Col, Row} from "mdbreact";
import oscartLogo from '../../../assets/images/oscart/logo-oscart.png';
import frankyLogo from '../../../assets/images/oscart/logo-franky.png';
import FormattedMessageWithClass from "../../Utils/FormattedMessageWithClass";

const Oscart = () => {
    return (
            <Row className="oscart">
                <Col size="7" className="oscart__left-section">
                    <div>
                        <img src={oscartLogo} alt="oscart logo"/>
                    </div>
                    <div>
                        <p className="oscart__left-section__description">
                            <FormattedHtmlMessage id="oscart.description"/>
                        </p>
                        <p className="oscart__left-section__sub-description">
                            <FormattedHtmlMessage id="oscart.sub_description"/>
                        </p>
                    </div>
                    <div className="text-right">
                        <a href="https://www.oscart.be/Account/Login" target="_blank" rel="noopener noreferrer"
                           className="btn oscart__left-section__button">
                            <FormattedHtmlMessage id="oscart.login_btn"/>
                        </a>
                    </div>
                    <div className="oscart__left-section__footer">
                        <img src={frankyLogo} alt="franky-logo"/>
                        <FormattedMessageWithClass className="oscart__left-section__footer__partner" id="oscart.official_partner"/>
                    </div>
                </Col>
                <Col size="5" className="pr-0">
                    <div className="oscart__right-section">
                        <div className="oscart__right-section__circle__wrapper">
                            <span className="oscart__right-section__circle__text">
                                <FormattedHtmlMessage id="oscart.circle_text"/>
                            </span>
                        </div>
                        <div className="oscart__right-section__lets-meet">
                            <FormattedHtmlMessage id="oscart.lets_meet" />
                        </div>
                        <div className="oscart__right-section__address">
                            <p><FormattedHtmlMessage id="oscart.address.line1"/></p>
                            <p><FormattedHtmlMessage id="oscart.address.line2"/></p>
                        </div>
                        <div className="oscart__right-section__contact">
                            <p><FormattedHtmlMessage id="oscart.phone"/></p>
                            <p><FormattedHtmlMessage id="oscart.email"/></p>
                        </div>
                        <div className="oscart__right-section__site">
                            <a href="https://oscart.eu">www.oscart.eu</a>
                        </div>
                    </div>
                </Col>
            </Row>
    );
};

export default Oscart;
