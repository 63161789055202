import PropTypes from "prop-types";
import React from "react";
import Delivery from "./Delivery";
import { fetchDelivers, sendRetourForm } from "../../../actions/routines";
import { resetRetourError } from "../../../actions/retour";
import { connect } from "react-redux";
import moment from "moment";
import * as R from "ramda";
import { makeDeliveryData } from "../../Utils/makeFakeData";
import * as qs from "qs";
import history from "../../../history";

class DeliveryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inProgressDelivers: [],
            closedDelivers: [],
            onHoldDelivers: [],
            inProgressDeliversCount: 10,
            closedDeliversCount: 10,
            onHoldDeliversCount: 10,
            orderStatuses: {},
        };
    }

    componentDidMount() {
        const queryParams = qs.parse(history.location.search.slice(1));

        if (this.props.data.length > 0) {
            this.setData();
        }

        this.props.fetchDelivers(queryParams);
    }

    componentDidUpdate(prevProps) {
        if (!R.equals(prevProps.data, this.props.data)) {
            this.setData();
        }
    }

    /**
     * Check every order item for the given status
     *
     * @param {array} delivery
     * @param {string} status
     *
     * @returns {boolean}
     */
    checkDeliveryHasStatus = (delivery, status) => {
        const deliveriesThatHaveStatus = delivery.order_history_items.filter(orderHistoryItem => (
            orderHistoryItem.hasOwnProperty('status') && orderHistoryItem.status === status
        ));

        return deliveriesThatHaveStatus.length > 0;
    };

    sortDeliverData = (data) => {
        const closedDelivers = [];
        const openDelivers = [...data];
        const onHoldDelivers = [];
        let onOtherList = false;

        for (let i = 0; i < openDelivers.length; i++) {
            const deliverData = openDelivers[i];
            onOtherList = false;

            // statuses are set on order-item-level, so to check if an ORDER is `ON HOLD`, check every line for this status
            // BUT if SOME order items are `ON HOLD`, that doesn't mean the whole ORDER is `ON HOLD` so it can also be
            // other order items are simply deliverable / delivered
            // TODO: get this status "value" from the backend when more statuses are used/needed
            if (this.checkDeliveryHasStatus(deliverData, 'HOLD')) {
                onHoldDelivers.push(deliverData);
                onOtherList = true;
            }

            if (
                deliverData.delivery_date &&
                moment(deliverData.delivery_date) < moment()
            ) {
                closedDelivers.push(deliverData);
                onOtherList = true;
            }

            if (onOtherList) {
                openDelivers.splice(i, 1);
                i--;
            }
        }

        return {
            closedDelivers: closedDelivers,
            inProgressDelivers: openDelivers,
            onHoldDelivers,
        };
    };

    sortDescendingByDate = (array) => {
        return array.sort((a, b) => {
            return new Date(b.order_date) - new Date(a.order_date);
        });
    };

    setData = () => {
        const sortedData = this.sortDeliverData(this.props.data);

        this.setState({
            inProgressDelivers: sortedData.inProgressDelivers,
            closedDelivers: this.sortDescendingByDate(
                sortedData.closedDelivers
            ),
            onHoldDelivers: sortedData.onHoldDelivers,
            inProgressDeliversCount: sortedData.inProgressDelivers.length,
            closedDeliversCount: sortedData.closedDelivers.length,
            onHoldDeliversCount: sortedData.onHoldDelivers.length,
        });

        this.initOrderStatuses();
    };

    initOrderStatuses = () => {
        this.props.data.forEach((order) => {
            this.updateOrderStatus(order.order_nr, order.status);
        });
    };

    updateOrderStatus = (orderNumber, status) => {
        this.setState(prevState => ({
            orderStatuses: {
                ...prevState.orderStatuses,
                [orderNumber]: status
            }
        }));
    };

    render() {
        return (
            <Delivery
                inProgressDelivers={this.state.inProgressDelivers}
                inProgressDeliversCount={this.state.inProgressDeliversCount}
                closedDelivers={this.state.closedDelivers}
                closedDeliversCount={this.state.closedDeliversCount}
                onHoldDelivers={this.state.onHoldDelivers}
                onHoldDeliversCount={this.state.onHoldDeliversCount}
                loading={this.props.loading}
                sendRetourForm={this.props.sendRetourForm}
                retour={this.props.retour}
                resetRetourError={this.props.resetRetourError}
                orderStatuses={this.state.orderStatuses}
                handleUpdateOrderStatus={this.updateOrderStatus}
                transportCostAmount={this.props.transportCostAmount}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.delivers.data,
        loading: state.delivers.loading,
        retour: state.retour,
        transportCostAmount: state.delivers.transportCostAmount,
    };
};

export default connect(
    mapStateToProps,
    { fetchDelivers, sendRetourForm, resetRetourError }
)(DeliveryContainer);

DeliveryContainer.propTypes = {
    data: PropTypes.array,
    fetchDelivers: PropTypes.func,
};

DeliveryContainer.defaultProps = {
    data: makeDeliveryData(5),
};
