import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './rootSaga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';

const checkForIframe = () => (next) => (action) => {
    if (window.top !== window) {
        window.top.document.location = '/';
    }
    return next(action);
};

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(sagaMiddleware, checkForIframe))
);

const sagas = [rootSaga, routinePromiseWatcherSaga];

sagas.forEach(sagaMiddleware.run);

export default store;
