import PropTypes from 'prop-types'
import React from "react";
import {SelectOption} from "mdbreact";

const NestedOption = ({parent, selected}) => (
    <React.Fragment>
        <SelectOption disabled>{parent.name}</SelectOption>
        {parent.categories && parent.categories.map(child => (
            <SelectOption selected={child.code === selected} key={child.code} value={child.code}>{child.name}</SelectOption>
        ))}
    </React.Fragment>
);

NestedOption.propTypes = {
  parent: PropTypes.object.isRequired
};

export default NestedOption;