import React from 'react';
import PropTypes from 'prop-types'

const ItemVariant = ({id, points, name, color}) => {
    return (
        <div className="campaign__item__variant">
            <style>
                {`
                    .btn-campaign-${id} {
                        background-color: ${color} !important;
                    }
                `}
            </style>
            <span className="campaign__item__variant__points" style={{color: color}}>{points} pt.</span>
            {' '}
            <span className="campaign__item__variant__name">{name}</span>
            {' '}
        </div>
    )
};

ItemVariant.propTypes = {
    id: PropTypes.number.isRequired,
    points: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
};

export default ItemVariant;
