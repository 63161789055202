import { fetchProductCategories } from '../actions/routines';
import {call, put} from "redux-saga/effects";
import Product from "../services/api/Products";
import * as R from "ramda";

export function* fetchProductCategoriesSaga(action) {
    try {
        yield put(fetchProductCategories.request());

        const response = yield call(Product.getAllCategories, action.payload);

        const group = response.data.map(category => {
            if (!category.hasOwnProperty('parent')) {
                return category;
            }

            const parentCategory = R.find(R.propEq('code', category.parent))(response.data) || {};
            if (!parentCategory.categories) {
                parentCategory.categories = [];
            }
            parentCategory.categories.push(category);
            return parentCategory;

        });

        yield put(fetchProductCategories.success(R.uniq(group)));
    } catch (error) {
        yield put(fetchProductCategories.failure(error.message));
    } finally {
        yield put(fetchProductCategories.fulfill());
    }
}