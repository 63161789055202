import React from 'react';
import {validatePasswordEquals, validatePasswordFormat} from "../../../services/validators/password";

class NewPasswordFieldsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            passwordRepeat: '',
            isPasswordFormatValid: true,
            arePasswordEquals: true
        }
    }

    validatePasswordFormat = (e) => {
        this.setState({
            isPasswordFormatValid: validatePasswordFormat(e.target.value)
        });
    };

    validatePasswordEquals = () => {
        this.setState({
            arePasswordEquals: validatePasswordEquals(this.state.password, this.state.passwordRepeat)
        });
    };

    handleOnChange = (event) => {
        this.setState({...this.state, [event.target.name]: event.target.value})
    };

    onSubmit = () => {
        const {submit} = this.props;

        if (this.state.password && this.state.isPasswordFormatValid && this.state.arePasswordEquals) {
            submit(this.state.password, this.state.passwordRepeat);
        }
    };


    render() {
        return React.cloneElement(this.props.children, {
            password: this.state.password,
            passwordRepeat: this.state.passwordRepeat,
            handleOnChange: this.handleOnChange,
            isPasswordFormatValid: this.state.isPasswordFormatValid,
            arePasswordEquals: this.state.arePasswordEquals,
            validatePasswordFormat: this.validatePasswordFormat,
            validatePasswordEquals: this.validatePasswordEquals,
            onSubmit: this.onSubmit
        })
    }
}

export default NewPasswordFieldsContainer;
