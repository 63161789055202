import PropTypes from 'prop-types';
import React from 'react';
import ItemBox from '../../components/ItemBox/ItemBox';
import TitleHeader from '../../components/TitleHeader';
import { recipesGrey } from '../../../../assets/images/icons';
import { FormattedMessage } from 'react-intl';
import SearchContainer from '../components/SearchContainer';
import { Link } from 'react-router-dom';

const RecipesCategories = ({ categories }) => (
    <div className="recipes recipes__categories">
        <TitleHeader icon={recipesGrey} title="recipes.categories.title">
            <SearchContainer />

            {/* <p className="mt-4">
                    <Link to="/recipes/favourites" className="recipes__list__favourites">
                        <i className="favourite-icon__icon favourite-icon--is-favourite"/>
                        <FormattedMessage id="recipes.favourites_url"/>
                    </Link>
                </p> */}
        </TitleHeader>
        {categories.map((category) => (
            <ItemBox
                key={category.id}
                image={category.image}
                title={category.title}
                text={
                    <FormattedMessage
                        id="recipes.categories.recipesNumber"
                        values={{ number: category.recipes_count }}
                    />
                }
                link={`/recipes/${category.slug}`}
            />
        ))}
    </div>
);

RecipesCategories.propTypes = {
    categories: PropTypes.array.isRequired,
};

export default RecipesCategories;
