import PropTypes from 'prop-types'
import React from "react";
import {Animation, Button} from "mdbreact";
import InputDefault from "../../../Form/InputDefault";
import {checkWhite} from "../../../../assets/images/icons";
import ButtonWithSpinner from "../../../Utils/ButtonWithSpinner";

const NewPassword = ({password, passwordRepeat, handleOnChange, isPasswordFormatValid, arePasswordEquals, validatePasswordFormat, validatePasswordEquals, onSubmit, isNewPasswordSent, isLoading}) => {
    return (
        <form className="my-account__new-password">
            <InputDefault
                value={password}
                type="password"
                name="password"
                onChange={handleOnChange}
                className={!isPasswordFormatValid === false ? '' : 'is-invalid'}
                invalidFeedback="security.new_password.invalid_format"
                onBlur={validatePasswordFormat}
                placeholder="security.new_password.password"
            />
            <InputDefault
                value={passwordRepeat}
                type="password"
                name="passwordRepeat"
                onChange={handleOnChange}
                className={!arePasswordEquals === false ? '' : 'is-invalid'}
                invalidFeedback="security.new_password.password_not_equals"
                onBlur={validatePasswordEquals}
                placeholder="security.new_password.password_repeat"
            />
            <div className="my-account__new-password__buttons">
                {isNewPasswordSent ?
                    <Button color="success">
                        <Animation type="bounceIn">
                            <img src={checkWhite} alt="saved" width="22px"/>
                        </Animation>
                    </Button>
                    :
                    <ButtonWithSpinner
                        onClick={onSubmit}
                        type="button"
                        className="btn-brand"
                        label="my_account.new_password.save"
                        width={15}
                        height={15}
                        isLoading={isLoading}
                    />
                }
            </div>
        </form>
    );
};

NewPassword.propTypes = {
  arePasswordEquals: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  isNewPasswordSent: PropTypes.bool.isRequired,
  isPasswordFormatValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  passwordRepeat: PropTypes.string.isRequired,
  validatePasswordEquals: PropTypes.func.isRequired,
  validatePasswordFormat: PropTypes.func.isRequired
};

export default NewPassword;
