import PropTypes from 'prop-types'
import React from 'react';
import RecipesList from "./../components/RecipeList";
import {connect} from "react-redux";
import {
    toggleRecipeFavourites,
    searchRecipesPromiseCreator
} from "../../../../actions/routines";
import {bindActionCreators} from "redux";
import {bindPromiseCreators} from "redux-saga-routines";
import {resetHasMore} from "../../../../actions/recipe";
import * as qs from "qs";
import history from "../../../../history";

class RecipesSearchContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            recipes: [],
            offset: 0,
            limit: 10
        }
    }

    componentDidMount() {
        this.props.resetHasMore()
    }

    loadMore = () => {
        const params = qs.parse(history.location.search.slice(1));
        const { limit, offset } = this.state;

        this.props.searchRecipesPromiseCreator({limit, offset, params})
            .then((data) => {
                this.setState(prevState => {
                    return {
                        recipes: [...prevState.recipes, ...data.data],
                        offset: prevState.offset + limit,
                    }
                });
            });
    };

    onReSearch = () => {
        this.setState({
            offset: 0,
            limit: 10,
            recipes: [],
        });
        this.props.resetHasMore();
    };

    toggleFavourites = (id) => {
        this.props.toggleRecipeFavourites(id)
    };

    render() {
        const {loading, hasMore } = this.props;

        return (
            <RecipesList
                recipes={this.state.recipes}
                hasMore={hasMore}
                isLoading={loading}
                loadMore={this.loadMore}
                toggleFavourites={this.toggleFavourites}
                title="recipes.list.title"
                withSearch={true}
                onReSearch={this.onReSearch}
            />
        );
    }
}

RecipesSearchContainer.propTypes = {
    fetchRecipeListByCategoryPromiseCreator: PropTypes.func,
    hasMore: PropTypes.bool,
    loading: PropTypes.bool
};

RecipesSearchContainer.defaultProps = {
    hasMore: true
};


const mapStateToProps = state => {
    return {
        loading: state.recipeSearch.loading,
        hasMore: state.recipeSearch.hasMore
    }
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({
            toggleRecipeFavourites, resetHasMore
        }, dispatch),
        ...bindPromiseCreators({
            searchRecipesPromiseCreator
        }, dispatch),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(RecipesSearchContainer);