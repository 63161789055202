import PropTypes from 'prop-types'
import React from 'react';
import RecipesList from "./../components/RecipeList";
import {connect} from "react-redux";
import {toggleRecipeFavourites, fetchRecipeListByCategoryPromiseCreator} from "../../../../actions/routines";
import {bindActionCreators} from "redux";
import {bindPromiseCreators} from "redux-saga-routines";
import {resetHasMore} from "../../../../actions/recipe";
import * as _ from 'lodash';

class RecipesListContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            recipes: [],
            offset: 0,
            limit: 10
        }
    }

    componentDidMount() {
        this.props.resetHasMore()
    }

    loadMore = () => {
        const { match: { params: { category } } } = this.props;
        const { limit, offset } = this.state;

        this.props.fetchRecipeListByCategoryPromiseCreator({slug: category, limit: limit, offset: offset})
            .then((data) => {
                this.setState(prevState => {
                    return {
                        recipes: [...prevState.recipes, ...data.data],
                        offset: prevState.offset + limit,
                    }
                });
            });
    };

    toggleFavourites = (id) => {
        this.props.toggleRecipeFavourites(id)
    };

    render() {
        const { match: { params: { category } }, loading, hasMore } = this.props;
 
        const uniqueRecipes = _.uniqWith(this.state.recipes, _.isEqual)

        return (
            <RecipesList
                category={category}
                recipes={uniqueRecipes}
                hasMore={hasMore}
                isLoading={loading}
                loadMore={this.loadMore}
                toggleFavourites={this.toggleFavourites}
                title="recipes.list.title"
                titleValues={{category: `/ ${category}`}}
            />
        );
    }
}

RecipesListContainer.propTypes = {
    fetchRecipeListByCategoryPromiseCreator: PropTypes.func,
    hasMore: PropTypes.bool,
    loading: PropTypes.bool
};

RecipesListContainer.defaultProps = {
    hasMore: true
};


const mapStateToProps = state => {
    return {
        loading: state.recipeListByCategory.loading,
        hasMore: state.recipeListByCategory.hasMore
    }
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({
            toggleRecipeFavourites, resetHasMore
        }, dispatch),
        ...bindPromiseCreators({
            fetchRecipeListByCategoryPromiseCreator
        }, dispatch),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(RecipesListContainer);