import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownItem } from 'mdbreact';
import { Link } from 'react-router-dom';

class DropdownMenuItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovered: false,
        };
    }

    onMouseEnter = () => {
        this.setState({
            isHovered: true,
        });
    };

    onMouseLeave = () => {
        this.setState({
            isHovered: false,
        });
    };

    render() {
        const { label, href, icon, iconHover, onClick, isExternal } =
            this.props;

        if (isExternal) {
            return (
                <a href={href} target="_blank" rel="noreferrer">
                    <DropdownItem
                        onMouseEnter={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                    >
                        <img
                            className="homepage__navbar__icon"
                            src={this.state.isHovered ? iconHover : icon}
                            alt={label}
                        />
                        <FormattedMessage id={label} />
                    </DropdownItem>
                </a>
            );
        }

        return (
            <Link to={href} onClick={onClick}>
                <DropdownItem
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                >
                    <img
                        className="homepage__navbar__icon"
                        src={this.state.isHovered ? iconHover : icon}
                        alt={label}
                    />
                    <span>
                        <FormattedMessage id={label} />
                    </span>
                </DropdownItem>
            </Link>
        );
    }
}

export default DropdownMenuItem;
