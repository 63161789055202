import styles from './Categories.module.scss';
import Products from '../../services/api/Products';
import { useEffect } from 'react';
import * as qs from 'qs';
import history from '../../history';
import { useState } from 'react';
import classNames from 'classnames';

const Categories = ({
    data,
    location,
    fetchProducts,
    currentCategory,
    showOnlyBreadcrumbs,
    closeModal,
    needReloadCategories,
    className = '',
}) => {
    const [subCategories, setSubCategories] = useState([]);
    const [parentCategoryId, setParentCategoryId] = useState(null);
    const [breadCrumbs, setBreadCrumbs] = useState([]);

    function getBreadcrumbs(
        category,
        breadcrumbs = [],
        foundCategories = false
    ) {
        const breadcrumb = {
            id: category.id,
            name: category.name,
            products_count: category.products_count,
        };
        let localFoundCategories = foundCategories;

        breadcrumbs.unshift(breadcrumb);

        const filteredCat = category.children.filter(
            (c) => c?.products_count > 0
        );

        if (
            category?.children?.length === 0 &&
            (category?.parent_category === undefined ||
                category?.parent_category?.children?.length === 0)
        ) {
            localFoundCategories = false;
            setSubCategories([]);
        }

        if (
            category.children &&
            filteredCat.length > 0 &&
            /*subCategories?.length === 0 &&*/
            !localFoundCategories
        ) {
            localFoundCategories = true;
            setSubCategories(filteredCat);
        }

        if (
            category.parent_category &&
            Object.keys(category.parent_category).length > 0
        ) {
            return getBreadcrumbs(
                category.parent_category,
                breadcrumbs,
                localFoundCategories
            );
        }

        return breadcrumbs;
    }

    function getBreadcrumbsByCurrentCategory(category, breadcrumbs = []) {
        const breadcrumb = {
            id: category.id,
            name: category.name,
            products_count: category.products_count,
        };

        breadcrumbs.unshift(breadcrumb);

        if (
            category.parent_category &&
            Object.keys(category.parent_category).length > 0
        ) {
            return getBreadcrumbsByCurrentCategory(
                category.parent_category,
                breadcrumbs
            );
        }

        return breadcrumbs;
    }

    const getSubCategoriesFetch = async () => {
        const queryParams = qs.parse(history.location.search.slice(1));
        if (
            !queryParams['s_category'] ||
            queryParams['s_category'] !== parentCategoryId
        ) {
            setSubCategories([]);
            setBreadCrumbs([]);
        }
        setParentCategoryId(queryParams['s_category'] ?? null);
    };

    useEffect(() => {
        getSubCategoriesFetch();
    }, [location]);

    useEffect(() => {
        const getSubCategories = async () => {
            const queryParams = qs.parse(history.location.search.slice(1));

            const { data: parents_breadcrumb } =
                await Products.getSubCategories(parentCategoryId, queryParams);
            const breadcrumbs =
                parents_breadcrumb.id !== undefined
                    ? getBreadcrumbs(parents_breadcrumb, [])
                    : [];
            setBreadCrumbs(breadcrumbs);
        };

        if (currentCategory) {
            const breadcrumbs =
                getBreadcrumbsByCurrentCategory(currentCategory);
            setBreadCrumbs(breadcrumbs);
        } else if (parentCategoryId) {
            getSubCategories();
        }
    }, [
        parentCategoryId,
        setSubCategories,
        currentCategory,
        needReloadCategories,
    ]);

    const handleBreadcrumbClick = (id) => {
        let params = qs.parse(history.location.search.slice(1));

        if (showOnlyBreadcrumbs) {
            params = {};

            localStorage.setItem('resetAllergenFilters', '1');
        }

        params['page'] = '1';

        const newCategory = subCategories?.find(
            (category) => category.id === id
        );
        const oldCategory = breadCrumbs?.find((category) => category.id === id);

        if (newCategory) {
            params['s_category'] = newCategory.id;
        } else if (oldCategory) {
            params['s_category'] = oldCategory.id;
        }

        history.push({
            ...location,
            search: qs.stringify(params),
        });

        params['s_category'] = id;

        if (closeModal) {
            closeModal();
        }

        if (!history.location.pathname.includes('products')) {
            history.push({
                ...location,
                pathname: '/products',
                search: qs.stringify(params),
            });
        } else {
            fetchProducts({ params });
        }
    };

    if (!currentCategory && !parentCategoryId) {
        return <></>;
    }

    return (
        <div className={`${styles.container} ${className}`}>
            <div className={styles.breadcrumbs}>
                {breadCrumbs.map((breadcrumb, index) => {
                    const isFirst = index === 0;
                    const withSeparator = index < breadCrumbs.length - 1;
                    const isNotLast = index < breadCrumbs.length - 1;

                    return (
                        <span
                            key={breadcrumb.id}
                            className={classNames(styles.breadcrumbItem, {
                                [styles.first]: isFirst,
                            })}
                        >
                            <span
                                onClick={() =>
                                    handleBreadcrumbClick(breadcrumb.id)
                                }
                                className={classNames(styles.name, {
                                    [styles.first]: isFirst,
                                })}
                            >
                                {breadcrumb.name}
                            </span>
                            {(withSeparator || isFirst) && isNotLast && (
                                <span className={styles.breadcrumbSeparator}>
                                    {' '}
                                    /
                                </span>
                            )}
                        </span>
                    );
                })}
            </div>
            {!showOnlyBreadcrumbs && (
                <div className={styles.categoryMenu}>
                    <nav className={styles.categoryItem}>
                        <ol className={styles.subcategories}>
                            {subCategories &&
                                subCategories.map((category, index) => (
                                    <li className={styles.categoryItemList}>
                                        <button
                                            key={category.id}
                                            className={styles.subcategoryButton}
                                            onClick={() =>
                                                handleBreadcrumbClick(
                                                    category.id
                                                )
                                            }
                                        >
                                            {category.name} (
                                            {category.products_count})
                                        </button>
                                    </li>
                                ))}
                        </ol>
                    </nav>
                </div>
            )}
            <div className="body-content-top-products-separator" />
        </div>
    );
};

export default Categories;
