import React, { Component } from 'react';
import FairWeekSubcomponentContainer from './components/FairWeekSubcomponent/FairWeekSubcomponentContainer';

class FairSubcomponent extends Component {
    render() {
        const {
            row,
            fairProducts,
            setFairProducts,
            addProduct,
            pageSize,
            loadingState,
        } = this.props;

        return (
            <div
                className={`fair-subcomponent ${
                    row.index % 2 === 0 ? 'odd' : 'even'
                }`}
            >
                <div>
                    {fairProducts[row.index] &&
                        fairProducts[row.index].fair_product_promotions.map(
                            (week) => (
                                <FairWeekSubcomponentContainer
                                    row={row}
                                    week={week}
                                    setFairProducts={setFairProducts}
                                    fairProducts={fairProducts}
                                    addProduct={addProduct}
                                    lastWeek={
                                        fairProducts[row.index] &&
                                        fairProducts[row.index]
                                            .fair_product_promotions[
                                            fairProducts[row.index] &&
                                                fairProducts[row.index]
                                                    .fair_product_promotions
                                                    .length - 1
                                        ]
                                    }
                                    firstWeek={
                                        fairProducts[row.index] &&
                                        fairProducts[row.index]
                                            .fair_product_promotions[0]
                                    }
                                    pageSize={pageSize}
                                    loadingState={loadingState}
                                />
                            )
                        )}
                </div>
                {row.original.gadget ? (
                    <div className={'fair-subcomponent-gadget'}>
                        {row.original.gadget}
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export default FairSubcomponent;
