import PropTypes from 'prop-types';
import React from 'react';
import { trashGrey } from '../../../../assets/images/icons';
import Spinner from '../../../Utils/Spinner';
import { FormattedMessage, injectIntl } from 'react-intl';

const MyOrderList = ({ handleRemove, isLoading, intl, text = '' }) =>
    isLoading ? (
        <div className="justify-content-center">
            <Spinner className="brand-color" width={25} height={25} />
        </div>
    ) : text ? (
        <button onClick={handleRemove}>
            <FormattedMessage id={text} />
        </button>
    ) : (
        <img
            src={trashGrey}
            onClick={handleRemove}
            alt="remove from assortment"
            title={intl.formatMessage({
                id: 'product.remove_from_assortiment',
            })}
        />
    );

MyOrderList.propTypes = {
    handleRemove: PropTypes.func,
    intl: PropTypes.any,
    isLoading: PropTypes.bool,
};

export default injectIntl(MyOrderList);
