import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const PointsWithoutLink = ({ points, color }) => {
    return (
        <div
            className="campaign__item__points"
            style={{ backgroundColor: color }}
        >
            <p className="campaign__item__points__label">
                <FormattedMessage id="campaign.item.points" />
            </p>
            <p className="campaign__item__points__points">{points}</p>
        </div>
    );
};

PointsWithoutLink.propTypes = {
    color: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
};

export default PointsWithoutLink;
