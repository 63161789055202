import PropTypes from 'prop-types';
import React from 'react';
import { DropdownItem, DropdownMenu } from 'mdbreact';
import {
    accountGrey,
    accountWhite,
    signOutGrey,
    signOutWhite,
    invoicesGrey,
    invoicesWhite,
} from '../assets/images/icons';
import DropdownMenuItem from './Dropdown/DropdownMenuItem';
import { Link } from 'react-router-dom';

const CustomersDropdownMenu = ({
    logout,
    accountList,
    selected,
    customers,
    changeCustomerHandler,
    userDetails,
}) => {
    return (
        <DropdownMenu>
            {customers.map((account) => {
                return (
                    <Link key={account.id} to="#">
                        <DropdownItem
                            className={`customer-dropdown-item`}
                            onClick={() => changeCustomerHandler(account)}
                        >
                            <span
                                className={`text-uppercase ${
                                    account.id === selected
                                        ? 'font-weight-normal'
                                        : ''
                                }`}
                            >
                                {`${account?.username} - ${account?.name}`}
                            </span>
                        </DropdownItem>
                    </Link>
                );
            })}
            <hr />
            {!userDetails?.show_only_fair_module && (
                <DropdownMenuItem
                    label="account.dropdown.my_account"
                    href="/my-account"
                    icon={accountGrey}
                    iconHover={accountWhite}
                />
            )}

            {process.env.REACT_APP_ENABLE_INVOICE === 'true' &&
                !userDetails?.show_only_fair_module && (
                    <DropdownMenuItem
                        label="account.dropdown.invoices"
                        href="/invoices"
                        icon={invoicesGrey}
                        iconHover={invoicesWhite}
                    />
                )}

            <DropdownMenuItem
                label="account.dropdown.sign_out"
                href="#"
                onClick={logout}
                icon={signOutGrey}
                iconHover={signOutWhite}
            />
        </DropdownMenu>
    );
};

CustomersDropdownMenu.propTypes = {
    accountList: PropTypes.array,
    logout: PropTypes.func,
    selected: PropTypes.number,
};

CustomersDropdownMenu.defaultProps = {
    accountList: [],
};

export default CustomersDropdownMenu;
