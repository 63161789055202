import PropTypes from 'prop-types'
import React from "react";
import {Carousel, CarouselInner, CarouselItem, Container} from 'mdbreact';
import ProductBox from "./components/ProductBox";

const ProductSlider = ({products, openProductDetails}) => {
    let itemsCountToVisible = 5;
    if (window.innerWidth < 1200) {
        itemsCountToVisible = 4;
    }

    const numberOfProducts = products.length > 0 && products.map(product => product.length).reduce((a, b) => a + b);

    return (
        <Container fluid className="full-width">
            <Carousel
                activeItem={1}
                length={products.length}
                showControls={numberOfProducts > itemsCountToVisible}
                showIndicators={false}
                interval={false}
                className="product-slider"
            >
                <CarouselInner>
                    {products && products.map((productGroup, id) => (
                        <CarouselItem key={id} itemId={++id}>
                            {productGroup && productGroup.map((product) => {
                                return <ProductBox
                                    key={product.id}
                                    product={product}
                                    openProductDetails={openProductDetails}
                                />
                            })}
                        </CarouselItem>
                    ))}
                </CarouselInner>
            </Carousel>
        </Container>
    );
};


ProductSlider.propTypes = {
    products: PropTypes.array,
    openProductDetails: PropTypes.func
};

export default ProductSlider;
