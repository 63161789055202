import PropTypes from 'prop-types'
import React from "react";

const GreenCheckIcon = ({width, height}) => {
    return (
        <span className="green-check" style={{width: width, height: height}}/>
    );
};

GreenCheckIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

GreenCheckIcon.defaultProps = {
  height: 20,
  width: 20
};

export default GreenCheckIcon;