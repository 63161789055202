import React from 'react';
import Dashboard from "./Dashboard";
import {logoutUser} from "../../actions/security";
import {connect} from "react-redux";
import {fetchUnreadNormalNotifications} from "../../actions/routines";

class DashboardContainer extends React.Component {
    componentDidMount() {
        this.props.fetchUnreadNormalNotifications();
    }

    render() {
        const {logout, userDetails, notifications, locale, isCampaignActive} = this.props;

        return <Dashboard
            logout={logout}
            userDetails={userDetails}
            notifications={notifications}
            locale={locale}
            isCampaignActive={isCampaignActive}
        />
    }
}

const mapStateToProps = state => {
    return {
        userDetails: state.userDetails.data,
        notifications: state.unreadNormalNotifications.data,
        isCampaignActive: state.campaigns.isCampaignActive,
        locale: state.locale.lang
    }
};

export default connect(mapStateToProps, {logout: logoutUser, fetchUnreadNormalNotifications})(DashboardContainer);
