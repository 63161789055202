import React from "react";
import { FormattedHtmlMessage } from '../../Utils/FormattedHtmlMessage';
import Section from "./components/Section";

const data = [
    {
        title: 'cookie_policy.what_is.title',
        text: 'cookie_policy.what_is.text'
    },
    {
        title: 'cookie_policy.cookie_types.title',
        text: 'cookie_policy.cookie_types.text'
    },
    {
        title: 'cookie_policy.cookie_use.title',
        text: 'cookie_policy.cookie_use.text'
    },
    {
        title: 'cookie_policy.cookie_manage.title',
        text: 'cookie_policy.cookie_manage.text'
    }
];

const CookiePolicy = () => {
    return (
        <div className="cookie-policy">
            <h1><FormattedHtmlMessage id="cookie_policy.title"/></h1>
            <p><FormattedHtmlMessage id="cookie_policy.intro"/></p>
            {data.map(section => (
                <Section
                  title={<FormattedHtmlMessage id={section.title}/>}
                  text={<FormattedHtmlMessage id={section.text}/>}
                />
            ))}
        </div>
    );
};

export default CookiePolicy;
