import PropTypes from 'prop-types'
import React from "react";

const CheckboxFilter = ({id, name, label, checked, type, onChange, imageLabel}) => {
    const htmlId = name.split(' ').join('-');
    return (
        <div className={`form-check my-3 ${checked ? 'filters__modal__filter__label--selected' : ''}`}>
            <input
                data-id={id}
                data-type={type}
                data-name={name}
                type="checkbox"
                id={htmlId}
                value={id}
                checked={checked}
                onChange={onChange}
                className="form-control filled-in form-check-input d-inline my-0"
            />
            <label className="form-check-label mr-5" htmlFor={htmlId}>
                {imageLabel &&
                    <img src={label} alt={htmlId} style={{width: 75, height: 90}}/>
                }
                {!imageLabel && label}
            </label>
        </div>
    );
};

CheckboxFilter.propTypes = {
  checked: PropTypes.any,
  id: PropTypes.number.isRequired,
  imageLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

CheckboxFilter.defaultProps = {
  imageLabel: false
};

export default CheckboxFilter;
