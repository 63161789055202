import React from 'react';
import PropTypes from 'prop-types'
import {FormattedMessage} from "react-intl";

class Input extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: false,
            ref: undefined
        }
    }

    setRef = (node) => {
        this.setState({
            ref: node
        })
    };


    triggerFocus = () => {
        this.setState({isActive: true});
        this.state.ref.focus();
    };


    onFocus = () => this.setState({isActive: true});
    onBlur = (e) => {
        if (!e.target.value) {
            this.setState({isActive: false});
        }
    };

    render() {
        const {value, type, onChange, name, label, className, invalidFeedback, onBlur} = this.props;

        return (
            <div className="md-form form-group">
                <input
                    ref={this.setRef}
                    value={value}
                    onChange={onChange}
                    onBlur={(e) => {
                        if (onBlur) {
                            onBlur(e);
                        }
                        this.onBlur(e);
                    }}
                    onFocus={this.onFocus}
                    type={type}
                    name={name}
                    className={`form-control password__input ${className}`}
                />
                <label onClick={this.triggerFocus} className={this.state.isActive ? "active" : ''}><FormattedMessage id={label}/></label>
                {invalidFeedback && <div className="invalid-feedback"><FormattedMessage id={invalidFeedback}/></div>}
            </div>
        )
    }
}

Input.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    onBlur: PropTypes.func
};

export default Input;