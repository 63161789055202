import {
    fetchDownloadCenterFolders,
    fetchDownloadCenterFiles,
    searchDownloadCenterFiles,
} from "../actions/routines";
import routineReducerFactory from "./routineReducerFactory";

export const downloadCenterFolders = routineReducerFactory(fetchDownloadCenterFolders, {data: [], loading: false});
export const downloadCenterFiles = routineReducerFactory(fetchDownloadCenterFiles, {data: [], loading: false});
export const downloadCenterFilesSearch = routineReducerFactory(searchDownloadCenterFiles, {data: [], loading: false});

const initialState = {
    data: [],
    loading: false,
    error: null,
    type: null
};

export const downloadCenter = (state = initialState, action) => {
    switch (action.type) {
        case fetchDownloadCenterFolders.TRIGGER:
        case fetchDownloadCenterFiles.TRIGGER:
        case searchDownloadCenterFiles.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case fetchDownloadCenterFolders.SUCCESS:
            return {
                ...state,
                data: action.payload,
                type: 'folders'
            };
        case fetchDownloadCenterFiles.SUCCESS:
            return {
                ...state,
                data: action.payload.files,
                type: 'files'
            };
        case searchDownloadCenterFiles.SUCCESS:
            return {
                ...state,
                data: action.payload,
                type: 'search'
            };
        case fetchDownloadCenterFolders.FAILURE:
        case fetchDownloadCenterFiles.FAILURE:
        case searchDownloadCenterFiles.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchDownloadCenterFolders.FULFILL:
        case fetchDownloadCenterFiles.FULFILL:
        case searchDownloadCenterFiles.FULFILL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};