import request from './request';

const Promotions = {
    get: (params) => {
        return request({
            url: '/api/promotion/',
            method: 'GET',
            params: params
        });
    },
    getPromotionalModal: () => {
        return request({
            url: '/api/promotion-banner/',
            method: 'GET',
        });
    }
};

export default Promotions;