import React from 'react';
import { FormattedHtmlMessage } from '../../Utils/FormattedHtmlMessage';
import { useIntl } from 'react-intl';

const ResponsibleDisclosurePolicy = () => {
    const intl = useIntl();

    return (
        <div className="cookie-policy">
            <h1>
                <FormattedHtmlMessage id="responsible_disclosure_policy.title" />
            </h1>
            <p>
                <FormattedHtmlMessage id="responsible_disclosure_policy.intro1" />
            </p>
            <p>
                <FormattedHtmlMessage id="responsible_disclosure_policy.intro2" />
            </p>

            <div>
                <h3>
                    {
                        <FormattedHtmlMessage id="responsible_disclosure_policy.we_ask_you.title" />
                    }
                </h3>
                <ul>
                    <li
                        dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                                id: 'responsible_disclosure_policy.we_ask_you.list.0',
                            }),
                        }}
                    />
                    <li>
                        {
                            <FormattedHtmlMessage id="responsible_disclosure_policy.we_ask_you.list.1" />
                        }
                    </li>
                    <li>
                        {
                            <FormattedHtmlMessage id="responsible_disclosure_policy.we_ask_you.list.2" />
                        }
                    </li>
                    <li>
                        {
                            <FormattedHtmlMessage id="responsible_disclosure_policy.we_ask_you.list.3" />
                        }
                    </li>
                </ul>
            </div>

            <div>
                <h3>
                    {
                        <FormattedHtmlMessage id="responsible_disclosure_policy.we_promise.title" />
                    }
                </h3>
                <ul>
                    <li>
                        {
                            <FormattedHtmlMessage id="responsible_disclosure_policy.we_promise.list.0" />
                        }
                    </li>
                    <li>
                        {
                            <FormattedHtmlMessage id="responsible_disclosure_policy.we_promise.list.1" />
                        }
                    </li>
                    <li>
                        {
                            <FormattedHtmlMessage id="responsible_disclosure_policy.we_promise.list.2" />
                        }
                    </li>
                    <li>
                        {
                            <FormattedHtmlMessage id="responsible_disclosure_policy.we_promise.list.3" />
                        }
                    </li>
                    <li>
                        {
                            <FormattedHtmlMessage id="responsible_disclosure_policy.we_promise.list.4" />
                        }
                    </li>
                </ul>
            </div>

            <p>
                <FormattedHtmlMessage id="responsible_disclosure_policy.intro3" />
            </p>
        </div>
    );
};

export default ResponsibleDisclosurePolicy;
