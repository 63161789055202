import {fetchCampaigns, fetchUserData} from '../actions/routines';

const initialState = {
    data: [],
    loading: true,
    error: null,
};

const campaigns = (state = initialState, action) => {
    switch (action.type) {
        case fetchCampaigns.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case fetchCampaigns.SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case fetchCampaigns.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchCampaigns.FULFILL:
            return {
                ...state,
                loading: false,
            };
        case fetchUserData.SUCCESS:
            return {
                ...state,
                isCampaignActive: action.payload.is_campaign_active,
            };
        default:
            return state;
    }
};


export default campaigns;
