import request from './request';

const Recipe = {
    getDetails: (slug) => {
        return request({
            url:`/api/recipe/detail/${slug}`,
            method: 'GET',
        });
    },
    getCategories: (params) => {
        return request({
            url: '/api/recipe/',
            method: 'GET',
            params: params
        });
    },
    getListByCategory: ({slug, limit, offset}) => {
        return request({
            url: `/api/recipe/category/${slug}/${limit}/${offset}`,
            method: 'GET',
        });
    },
    toggleFavourites: (id) => {
        return request({
            url: `/api/recipe/favorite/${id}`,
            method: 'PATCH',
        });
    },
    getFavouritesList: ({limit, offset}) => {
        return request({
            url: `/api/recipe/favourites/${limit}/${offset}`,
            method: 'GET',
        });
    },
    searchRecipes: ({limit, offset, params}) => {
        return request({
            url: `/api/recipe/search/${limit}/${offset}`,
            method: 'GET',
            params: {...params}
        });
    },

};

export default Recipe;