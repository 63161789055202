import React from 'react';
import PropTypes from 'prop-types';
import TitleHeader from '../components/TitleHeader';
import { cartGrey, trashGrey } from '../../../assets/images/icons';
import CartContainer from './components/Cart/CartContainer';
import WrongDeliveryDate from './components/WrongDeliveryDate';
import TransportCostWarning from './components/TransportCostWarning';
import FairCartContainer from './components/FairCart/FairCartContainer';
import { FormattedMessage } from 'react-intl';
import swal from 'sweetalert2';
import bindPromiseCreators from 'redux-saga-routines/dist/bindPromiseCreators';
import {
    completeCartPromiseCreator,
    deleteCartPromiseCreator,
} from '../../../actions/routines';
import { bindActionCreators } from 'redux';
import { openDeliveryDatepicker } from '../../../actions';
import CampaignStickers from '../../CampaignPoints/CampaignStickers';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

function castOldCartToDeliveriesStructure(cart) {
    let deliveriesMap = {};
    cart.basket_items.forEach(({ delivery_date, product, ...rest }) => {
        deliveriesMap[delivery_date] = [
            ...(deliveriesMap[delivery_date] ?? []),
            {
                ...product,
                ...rest,
                product,
                delivery_date,
            },
        ];
    });

    return Object.entries(deliveriesMap).map(
        ([deliveryDate, deliveryProducts]) => ({
            ...cart,
            delivery_date: deliveryDate,
            basket_items: deliveryProducts,
        })
    );
}

const CartPage = ({
    cartList,
    handleRemoveProduct,
    handleCartRemove,
    loading,
    isFairActive,
    fairCartProducts,
    fairData,
    setFairCartProducts,
    addProduct,
    fairCart,
    userDetails,
    loadingState,
    columns,
    handleToggleColumn,
    showOnlyFairModule,
}) => {
    const showPrice =
        userDetails?.current_customer?.customer_details?.show_price;

    let [fullCart] = cartList;
    let cartListAsDeliveries = cartList
        ?.map(castOldCartToDeliveriesStructure)
        .flat();
    let mainDeliveryDate = fullCart?.delivery_date;
    let cartId = fullCart?.id || 0;
    let totalCost = fullCart?.total_cost;

    // needed for head
    let allBasketItems = cartListAsDeliveries
        ?.map((delivery) => delivery.basket_items)
        .flat();

    let mainDelivery = { basket_items: [], subtotal: null };
    let otherDelivery = { basket_items: [], subtotal: null };

    cartListAsDeliveries.forEach((delivery) => {
        let isMain = mainDeliveryDate?.startsWith(delivery?.delivery_date);
        let targetDelivery = isMain ? mainDelivery : otherDelivery;

        targetDelivery.basket_items.push(...(delivery.basket_items || []));
        targetDelivery.subtotal =
            delivery?.sub_total_by_date[isMain ? 'main' : 'other'];
    });

    // yes, this made in kinda hacky way, but this is the only option to aggregate react-table v6 like this, so let's just make it good
    // click on first table will trigger sorting in main and other deliveries list, that's the thing
    function headTableClickHandler(event) {
        let clickedColumnText = event.target.innerText;
        let [mainDeliveryTable] = document.getElementsByClassName(
            'main-deliveries-table'
        );
        let [otherDeliveriesTable] = document.getElementsByClassName(
            'other-deliveries-table'
        );

        [mainDeliveryTable, otherDeliveriesTable].forEach((table) => {
            table
                ?.querySelectorAll('div.rt-th[role="columnheader"]')
                .forEach((tableHeaderButton) => {
                    tableHeaderButton.innerText === clickedColumnText &&
                        tableHeaderButton.click();
                });
        });
    }

    function convertSubtotalToEuroFormat(numberStr) {
        let number = parseFloat(numberStr);

        if (isNaN(number)) {
            return '';
        }

        return new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
        })
            .format(number)
            .replace('€', '');
    }

    return (
        <div className="cart-page">
            {/* <TitleHeader title="cart.title" icon={cartGrey} /> */}
            <div className="col-sm-12 col-md-12">
                {!showOnlyFairModule && (
                    <div className="cart-page__heading-block">
                        <div style={{ display: 'flex', gap: '40px' }}>
                            <div>
                                <h3 className="title-page">
                                    <img
                                        style={{
                                            marginRight: 16,
                                            width: 38,
                                            marginBottom: 10,
                                        }}
                                        src={cartGrey}
                                        alt="icon"
                                    />
                                    <FormattedMessage id="cart.title_with_no_s" />
                                </h3>
                                {showPrice && (
                                    <p className="subtitle-page">
                                        <FormattedMessage id="cart.text_for_summary_price" />
                                        :{' '}
                                        <span className="price">
                                            €{' '}
                                            {convertSubtotalToEuroFormat(
                                                totalCost
                                            )}
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>
                        <CampaignStickers hideTitle={true} />
                    </div>
                )}

                {loading || cartList.length === 0
                    ? !showOnlyFairModule && ( //Show fake table for loader. Fake data is loaded inside component.
                          <CartContainer
                              id={0}
                              dataMainDelivery={[]}
                              data={[]}
                              date={new Date()}
                              handleRemoveProduct={handleRemoveProduct}
                              handleCartRemove={handleCartRemove}
                              showPrice={showPrice}
                              columns={columns}
                              handleToggleColumn={handleToggleColumn}
                              convertSubtotalToEuroFormat={
                                  convertSubtotalToEuroFormat
                              }
                          />
                      )
                    : !showOnlyFairModule && (
                          <>
                              {/*used as header for sorting purposes only */}
                              {/* <CartContainer
                            id={cartId}
                            dataMainDelivery={cartList[0].basket_items}
                            data={allBasketItems}
                            date={mainDeliveryDate}
                            handleRemoveProduct={handleRemoveProduct}
                            handleCartRemove={handleCartRemove}
                            tableClassName="cart-table head-utility-table"
                            tableOnClick={headTableClickHandler}
                            showHeader={false}
                            showFooter={false}
                            hideBody
                            showPrice={showPrice}
                            columns={columns}
                            handleToggleColumn={handleToggleColumn}
                            convertSubtotalToEuroFormat={convertSubtotalToEuroFormat}
                        /> */}

                              {/*main delivery */}
                              <CartContainer
                                  id={cartId}
                                  title={
                                      <FormattedMessage id="cart.order_for_lower" />
                                  }
                                  dataMainDelivery={cartList[0].basket_items}
                                  data={mainDelivery?.basket_items}
                                  date={mainDeliveryDate}
                                  handleRemoveProduct={handleRemoveProduct}
                                  handleCartRemove={handleCartRemove}
                                  showFooter={
                                      !otherDelivery?.basket_items?.length
                                  }
                                  tableClassName="cart-table main-deliveries-table"
                                  subtotal={mainDelivery.subtotal?.toFixed(2)}
                                  fullCart={fullCart}
                                  showOrderNumberInput={true}
                                  showPrice={showPrice}
                                  columns={columns}
                                  handleToggleColumn={handleToggleColumn}
                                  convertSubtotalToEuroFormat={
                                      convertSubtotalToEuroFormat
                                  }
                              />

                              {/*products that have other delivery dates */}
                              {otherDelivery?.basket_items?.length > 0 && (
                                  <CartContainer
                                      id={cartId}
                                      title={
                                          <FormattedMessage id="cart.not_available_order_for_lower" />
                                      }
                                      dataMainDelivery={
                                          cartList[0].basket_items
                                      }
                                      data={otherDelivery?.basket_items}
                                      date={mainDeliveryDate}
                                      handleRemoveProduct={handleRemoveProduct}
                                      handleCartRemove={handleCartRemove}
                                      tableClassName="cart-table other-deliveries-table"
                                      hideHead
                                      fullCart={fullCart}
                                      subtotal={otherDelivery.subtotal?.toFixed(
                                          2
                                      )}
                                      showPrice={showPrice}
                                      columns={columns}
                                      handleToggleColumn={handleToggleColumn}
                                      disableSelectDate={true}
                                      convertSubtotalToEuroFormat={
                                          convertSubtotalToEuroFormat
                                      }
                                  />
                              )}
                          </>
                      )}
                {/* <TransportCostWarning/> */}
                {/*{cartListAsDeliveries.map((cart, index) => {
        return (
          <React.Fragment key={cart.id}>
            <CartContainer
              id={cart.id}
              data={cart.basket_items}
              date={cart.delivery_date}
              handleRemoveProduct={handleRemoveProduct}
              handleCartRemove={handleCartRemove}
              showFooter={index === cartListAsDeliveries.length - 1}
              convertSubtotalToEuroFormat={convertSubtotalToEuroFormat}
            />
            <WrongDeliveryDate date={cart.delivery_date}/>
            <TransportCostWarning/>
          </React.Fragment>
        );
      })}*/}

                {isFairActive && (
                    <FairCartContainer
                        loading={loading}
                        fairData={fairData}
                        fairCartProducts={fairCartProducts}
                        fairCart={fairCart}
                        setFairCartProducts={setFairCartProducts}
                        addProduct={addProduct}
                        loadingState={loadingState}
                    />
                )}
            </div>
        </div>
    );
};

CartPage.propTypes = {
    cartList: PropTypes.array,
    columns: PropTypes.array,
    fairCart: PropTypes.array,
    handleRemoveProduct: PropTypes.func.isRequired,
    handleCartRemove: PropTypes.func.isRequired,
    handleToggleColumn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(CartPage)
);
