import PropTypes from 'prop-types'
import React from "react";

const Section = ({title, text}) => (
    <div>
        <h3>{title}</h3>
        <p>{text}</p>
    </div>
);

Section.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Section;