import request from './request';

const DownloadCenter = {
    getFolders: () => {
        return request({
            url:`/api/downloadcenter/`,
            method: 'GET',
        });
    },
    getFiles: (folderId) => {
        return request({
            url:`/api/downloadcenter/files/${folderId}`,
            method: 'GET',
        });
    },
    getFile: (fileId) => {
        return request({
            url:`/api/downloadcenter/download/${fileId}`,
            method: 'GET',
            responseType: 'arraybuffer'
        });
    },
    searchFile: ({s_all}) => {
        return request({
            url:`/api/downloadcenter/search/${s_all}`,
            method: 'GET',
        });
    },

};

export default DownloadCenter;