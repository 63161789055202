import { BrowserView, MobileView } from 'react-device-detect';

const ImageBanner = ({ product }) => {
    return (
        <a href={product?.url}>
            <BrowserView>
                <img
                    className="toplist-banner"
                    src={product.image}
                    width={256}
                    alt={product?.name}
                />
            </BrowserView>
            <MobileView>
                <img
                    className="toplist-banner"
                    src={product.mobile_image}
                    width={256}
                    alt={product?.name}
                />
            </MobileView>
        </a>
    );
};

export default ImageBanner;
