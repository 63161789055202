import React from 'react';
import {FormattedMessage} from "react-intl";
import NotificationContainer from "../../../Notification/NotificationContainer";
import errorWhite from "../../../../assets/images/error-white.svg";
import moment from "moment";

class WrongDeliveryDate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
    }

    componentDidMount() {
        this.setState({
            show: moment(this.props.date).isSameOrBefore(moment(), "day")
        });
    }

    close = () => {
        this.setState({
            show: false
        })
    };

    render() {
        return (
            <NotificationContainer
                icon={errorWhite}
                status="warning"
                type="dialog"
                text={
                    <p><FormattedMessage id="cart.notification.wrong_date.text"/></p>
                }
                show={this.state.show}
                handleClose={this.close}
            />
        );
    }
}

export default WrongDeliveryDate;
