import React from 'react';
import {FormattedMessage} from "react-intl";
import ModalBottom from "./../Modal/Bottom/ModalBottom";
import Ping from "../../services/api/Ping";

class NoInternetConnectionContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            forceClose: false,
            ping: null
        };
    }

    componentDidMount() {
        const interval = setInterval(() => {
            Ping.ping()
            .then(() => {
                this.setState({
                    isOpen: false,
                    forceClose: false
                });
            })
            .catch((error) => {
                if (!error.status && !this.state.forceClose) {
                    this.setState({
                        isOpen: true
                    });
                }
            });
        }, 30000);

        this.setState({
            ping: interval
        });
    }

    componentWillUnmount(){
        clearInterval(this.state.interval)
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
            forceClose: true
        });
    };

    forceClose = () => {
        this.setState({
            forceClose: true,
            isOpen: false
        })
    };

    render() {
        return (
            <ModalBottom isOpen={this.state.isOpen}>
                <div className="d-flex align-items-center">
                    <FormattedMessage id="no_internet_connection.text"/>
                    {/*<Button size="sm" color="warning" className="pull-right shadow-none ml-auto" onClick={this.forceClose}>Close</Button>*/}
                </div>
            </ModalBottom>
        );
    }
}
export default NoInternetConnectionContainer;