import PropTypes from 'prop-types';
import React from 'react';
import { DropdownItem, DropdownMenu } from 'mdbreact';
import { Link } from 'react-router-dom';

const CustomerDropdownMenu = ({ customersList, selected, customerHandler }) => (
    <DropdownMenu>
        {customersList.map((customer) => (
            <Link
                key={customer.id}
                to="#"
                onClick={() => customerHandler(customer)}
            >
                <DropdownItem>
                    <span
                        className={`text-uppercase ${
                            customer.id === selected ? 'font-weight-normal' : ''
                        }`}
                    >
                        {customer.username} - {customer.name}
                    </span>
                </DropdownItem>
            </Link>
        ))}
    </DropdownMenu>
);

CustomerDropdownMenu.propTypes = {
    accountList: PropTypes.array,
    logout: PropTypes.func,
    selected: PropTypes.number,
};

CustomerDropdownMenu.defaultProps = {
    accountList: [],
};

export default CustomerDropdownMenu;
