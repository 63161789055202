import PropTypes from 'prop-types'
import React from "react";
import FiltersContainer from "../Filters/FiltersContainer";
import DiscountSummaryBox from "../DiscountSummaryBox/DiscountSummaryBoxContainer";

const PageHeader = ({handleProductTypeSelect, fairCartProducts}) => (
    <div className="page-header">
        <div className="page-header__boxes">
            <FiltersContainer handleProductTypeSelect={handleProductTypeSelect} fairCartProducts={fairCartProducts}/>
            <DiscountSummaryBox/>
        </div>
    </div>
);

PageHeader.propTypes = {
    handleProductTypeSelect: PropTypes.func
};

export default PageHeader;