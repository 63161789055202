import React from 'react';
import Campaign from "./Campaign";
import {connect} from "react-redux";
import {fetchCampaigns} from "../../../actions/routines";
import history from "../../../history";

class CampaignContainer extends React.Component {

    componentDidMount() {
        this.props.fetchCampaigns();
    }

    componentDidUpdate(prevProps) {
        const {isCampaignActive} = this.props;

        if (typeof isCampaignActive !== 'undefined' && isCampaignActive === false) {
            history.push('/dashboard');
        }
    }

    render() {
        return (
            <Campaign items={this.props.items} loading={this.props.loading}/>
        );
    }
}

const mapStateToProps = state => {
    return {
        items: state.campaigns.data,
        loading: state.campaigns.loading,
        isCampaignActive: state.campaigns.isCampaignActive,
    }
};

export default connect(mapStateToProps, {fetchCampaigns})(CampaignContainer);
