import React from 'react';
import Footer from "../../../../Footer/Footer";
import {Button, Col, Container, Row} from "mdbreact";
import {FormattedMessage} from "react-intl";
import { FormattedHtmlMessage } from '../../../../Utils/FormattedHtmlMessage';
import Header from "../../Header";
import {Link} from "react-router-dom";

const CheckEmail = () => {
    return (
            <div className="security check-email container-fluid">
                <Header/>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="check-email__title"><FormattedMessage id="security.check_email.title"/></h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="4">
                            <p className="check-email__instructions"><FormattedMessage id="security.check_email.instructions_sent"/></p>
                            <p>
                                <strong><FormattedMessage id="security.check_email.not_recevied"/></strong>
                            </p>
                            <p className="check-email__contact"><FormattedHtmlMessage id="security.check_email.contact"/></p>
                            <div>
                                <Link to="/">
                                    <Button type="submit" className="btn-brand">
                                        <FormattedMessage id="security.check_email.btn"/>
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer inverted/>
            </div>
    );
};

export default CheckEmail;
