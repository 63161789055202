import { fetchProductData } from '../actions/routines';
import {call, put} from "redux-saga/effects";
import Products from "../services/api/Products";

export function* fetchProductDetailsSaga(action) {
    try {
        yield put(fetchProductData.request());

        const response = yield call(Products.getProduct, action.payload);

        yield put(fetchProductData.success(response.data));
    } catch (error) {
        yield put(fetchProductData.failure(error.message));
    } finally {
        yield put(fetchProductData.fulfill());
    }
}
