import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { selectedLocale } from '../../actions/index';
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types'
import LanguageSwitcherHeader from "./LanguageSwitcherHeader";
import LanguageSwitcherFooter from "./LanguageSwitcherFooter";
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/nl';

class LanguageSwitcherContainer extends React.Component {

    selectLocale = (locale) => {
        const {selectedLocale} = this.props;

        selectedLocale(locale);
        moment.locale(locale);

        localStorage.setItem('lang', locale);
        window.location = (window.location.href).replace(window.location.host.split('.')[0], locale)
    };

    render() {
        const {intl, variant, toggleDisclaimerModal} = this.props;

        if (variant === 'header') {
            return (
                <LanguageSwitcherHeader locale={intl.locale} selectLocale={this.selectLocale}/>
            );
        }

        return (
            <LanguageSwitcherFooter locale={intl.locale} selectLocale={this.selectLocale} toggleDisclaimerModal={toggleDisclaimerModal}/>
        );
    }
}

LanguageSwitcherContainer.propTypes = {
    intl: PropTypes.object.isRequired,
    selectedLocale: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['header', 'footer']).isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ selectedLocale }, dispatch);
}

export default injectIntl(connect(null, mapDispatchToProps)(LanguageSwitcherContainer));
