import React from 'react';
import PropTypes from 'prop-types';
import Department from "./Department";

class DepartmentContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditActive: false,
            password: this.props.password,
            isNewPasswordSent: false
        }
    }

    toggleEdit = () => {
        this.setState(prevState => {
            return {isEditActive: !prevState.isEditActive}
        });
    };

    saveEdit = () => {

        this.setState({
            isNewPasswordSent: true,
        });

        setTimeout(() => {
            this.setState({
                isNewPasswordSent: false,
                isEditActive: false
            });
        }, 3000)
    };

    handlePasswordOnChange = (event) => {
        this.setState({password: event.target.value})
    };

    render() {
        const {id, name} = this.props;

        return <Department
            id={id}
            name={name}
            password={this.state.password}
            isEditActive={this.state.isEditActive}
            isNewPasswordSent={this.state.isNewPasswordSent}
            saveEdit={this.saveEdit}
            toggleEdit={this.toggleEdit}
            handlePasswordOnChange={this.handlePasswordOnChange}
        />;
    }
}

DepartmentContainer.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired
};

export default DepartmentContainer;
