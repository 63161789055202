import request from './request';

const Invoice = {
    getHistory: (params) => {
        return request({
            url: `/api/invoice/history`,
            method: 'GET',
            params: params,
        });
    },
    getPdf: (invoiceId) => {
        return request({
            url: `/api/invoice/pdf/${invoiceId}`,
            method: 'GET',
            responseType: 'arraybuffer',
        });
    },
};

export default Invoice;
