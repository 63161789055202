import { fetchCampaigns } from '../actions/routines';
import {call, put} from "redux-saga/effects";
import Campaign from "../services/api/Campaign";

export function* campaignSaga() {
    try {
        yield put(fetchCampaigns.request());

        const response = yield call(Campaign.get);

        yield put(fetchCampaigns.success(response.data));
    } catch (error) {
        yield put(fetchCampaigns.failure(error.message));
    } finally {
        yield put(fetchCampaigns.fulfill());
    }
}