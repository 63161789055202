import React from "react";
import {Link} from "react-router-dom";

const Breadcrumbs = ({breadcrumbs}) => {
    return (
        <p className="downloads__breadcrumbs">
            {breadcrumbs.map((breadcrumb, i) => (
                <React.Fragment>
                   <u><Link to={breadcrumb.path}>{breadcrumb.name.toUpperCase()}</Link></u> {' '} {breadcrumbs.length - 1 === i ? '' : `/  `}
                </React.Fragment>
            ))}
        </p>
    );
};

export default Breadcrumbs;
