import { fetchNoveltiesProducts } from '../actions/routines';

const initialState = {
    data: [],
    hasMore: true,
    loading: false,
    error: null,
};

export const noveltiesProducts = (state = initialState, action) => {
    switch (action.type) {
        case fetchNoveltiesProducts.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case fetchNoveltiesProducts.SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                hasMore: action.payload.hasMore === 1,
            };
        case fetchNoveltiesProducts.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchNoveltiesProducts.FULFILL:
            return {
                ...state,
                loading: false,
            };
        case 'RESET_HAS_MORE_NOVELTY':
            return {
                ...state,
                hasMore: true,
            };
        default:
            return state;
    }
};
