import React from 'react';
import PropTypes from 'prop-types';
import Input from "../../Form/Input";

class PasswordField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPassword: false
        }
    }

    showPassword = () => this.setState({showPassword: true});
    hidePassword = () => this.setState({showPassword: false});

    render() {
        const {value, onChange, name, label, className, invalidFeedback, onBlur} = this.props;

        return (
            <div className="password">
                <div className="md-form form-group">
                    <Input
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        type={this.state.showPassword ? 'text': 'password'}
                        name={name}
                        className={`form-control password__input ${className ? className : ''}`}
                        label={label}
                        invalidFeedback={invalidFeedback}
                        required
                    />
                </div>
                <i
                    onMouseDown={this.showPassword}
                    onMouseUp={this.hidePassword}
                    onTouchStart={this.showPassword}
                    onTouchEnd={this.hidePassword}
                    className="password__show fa fa-eye"
                />
            </div>
        )
    }
}

PasswordField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    onBlur: PropTypes.func
};

export default PasswordField;
