import {
    fetchFairCarts,
    fetchFairProducts,
    fetchFairProductGroups,
    fetchFairSuppliers,
    fetchFairPoints,
    completeFairCart,
    fetchFairDynamicWeeksData,
    addFairDynamicWeek,
} from '../actions/routines';
import { call, put } from 'redux-saga/effects';
import Fair from '../services/api/Fair';

export function* fetchCartsSaga() {
    try {
        yield put(fetchFairCarts.request());

        const response = yield call(Fair.getBaskets);

        yield put(fetchFairCarts.success({ data: response.data }));
    } catch (error) {
        yield put(fetchFairCarts.failure(error.message));
    } finally {
        yield put(fetchFairCarts.fulfill());
    }
}

export function* fetchProductsSaga(action) {
    try {
        yield put(fetchFairProducts.request());

        const response = yield call(Fair.getProducts, action.payload);

        yield put(
            fetchFairProducts.success({
                data: response.data,
                total: response.headers['x-total-count'],
                countAll: response.headers['x-all-count'],
                countUser: response.headers['x-user-count'],
            })
        );
    } catch (error) {
        yield put(fetchFairProducts.failure(error.message));
    } finally {
        yield put(fetchFairProducts.fulfill());
    }
}

export function* fetchSuppliersSaga(action) {
    try {
        yield put(fetchFairSuppliers.request());

        const response = yield call(Fair.getSuppliers, action.payload);

        yield put(fetchFairSuppliers.success(response.data));
    } catch (error) {
        yield put(fetchFairSuppliers.failure(error.message));
    } finally {
        yield put(fetchFairSuppliers.fulfill());
    }
}

export function* fetchPointsSaga(action) {
    try {
        yield put(fetchFairPoints.request());

        const response = yield call(Fair.getPointsInfo, action.payload);

        yield put(fetchFairPoints.success(response.data));
    } catch (error) {
        yield put(fetchFairPoints.failure(error.message));
    } finally {
        yield put(fetchFairPoints.fulfill());
    }
}

export function* completeFairCartSaga(action) {
    try {
        yield put(completeFairCart.request());

        const response = yield call(Fair.completeCart, action.payload);

        yield put(completeFairCart.success(response.data));
    } catch (error) {
        yield put(completeFairCart.failure(error.message));
    } finally {
        yield put(completeFairCart.fulfill());
    }
}

export function* fetchDynamicWeeksDataSaga(action) {
    try {
        yield put(fetchFairDynamicWeeksData.request());

        const response = yield call(Fair.getDynamicWeeksData, action.payload);

        yield put(fetchFairDynamicWeeksData.success(response.data));
    } catch (error) {
        yield put(fetchFairDynamicWeeksData.failure(error.message));
    } finally {
        yield put(fetchFairDynamicWeeksData.fulfill());
    }
}

export function* addFairDynamicWeekSaga(action) {
    try {
        yield put(addFairDynamicWeek.request());

        const response = yield call(Fair.addDynamicWeek, action.payload);

        yield put(addFairDynamicWeek.success(response.data));
    } catch (error) {
        yield put(addFairDynamicWeek.failure(error.message));
    } finally {
        yield put(addFairDynamicWeek.fulfill());
    }
}

export function* fetchProductGroupsSaga(action) {
    try {
        yield put(fetchFairProductGroups.request());

        const response = yield call(Fair.getProductGroups, action.payload);

        yield put(
            fetchFairProductGroups.success({
                data: response.data,
            })
        );
    } catch (error) {
        yield put(fetchFairProductGroups.failure(error.message));
    } finally {
        yield put(fetchFairProductGroups.fulfill());
    }
}
