import {fetchActiveCartData} from "../actions/routines";

const deliveryDatepicker = (state = {}, action) => {
    switch (action.type) {
        case 'DELIVERY_DATEPICKER_OPEN':
            return {
                isDeliveryDatepickerOpen: true
            };
        case 'DELIVERY_DATEPICKER_CLOSE':
            return {
                isDeliveryDatepickerOpen: false
            };
        case 'DELIVERY_DATE_SET':
            return {
                deliveryDate: action.deliveryDate
            };
        case fetchActiveCartData.SUCCESS:
            return {
                deliveryDate: action.payload.delivery_date
            };
        default:
            return state
    }
};

export default deliveryDatepicker