import React from 'react';
import PropTypes from 'prop-types';
import Notification from "./Notification";
import {setAsReadNotifications} from "../../../../actions/routines";
import {connect} from "react-redux";

class NotificationContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: true,
            display: true
        }
    }

    handleClose = () => {
        this.props.setAsReadNotifications(this.props.id);
        this.setState({
            show: false
        })
    };

    handleAnimationEnd = () => {
        this.setState({
            display: false
        })
    };
    
    render() {
        const {text, url} = this.props;
        const {show, display} = this.state;

        return <Notification
            text={text}
            url={url}
            display={display}
            show={show}
            handleClose={this.handleClose}
            handleAnimationEnd={this.handleAnimationEnd}
        />
    }
}

NotificationContainer.propTypes = {
  id: PropTypes.number,
  setAsReadNotifications: PropTypes.func,
  text: PropTypes.string,
  url: PropTypes.string
};

export default connect(null, {setAsReadNotifications})(NotificationContainer);
