import PropTypes from 'prop-types'
import React from "react";
import {Card, CardBody, CardTitle, CardText, Button} from 'mdbreact';
import truncateWithEllipses from "../../../../Utils/truncateWithEllipses";
import ImageIcon from "../../../components/ItemBox/ImageIcon";
import Spinner from "../../../../Utils/Spinner";

const FileBox = ({image, imageHover, title, pdfViewLink, text, isLoading, handleDownload, fileImage}) => {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    let itemBoxStyles = {};
    if (isIE11) {
        itemBoxStyles = {
            display: 'inline-flex'
        }
    }

    return (
        <Card className="item-box" onClick={handleDownload} style={itemBoxStyles}>
            <div className="item-box__image-wrapper" style={image && image.width ? {width: image.width} : {}}>
                {isLoading ?
                    <Spinner className="brand-color" width={50} height={50}/>
                    :
                    <React.Fragment>
                        {image &&
                        <ImageIcon image={image} fileImage={fileImage} className={imageHover ? 'item-box__image--hoverable' : ''}/>
                        }
                        <img src={imageHover.path} style={{height: imageHover.height}}
                             className="item-box__image--hover img-fluid" alt="hover"/>
                    </React.Fragment>
                }
            </div>
            <CardBody>
                <CardTitle title={title} className="item-box__title">{truncateWithEllipses(title)}</CardTitle>
                <CardText className="item-box__text">{text}</CardText>
                {pdfViewLink &&
                    <a href={pdfViewLink} target="_blank">
                        <Button type="submit" className="btn-brand">
                            <i className="fa fa-eye fa-lg"></i>
                        </Button>
                    </a>
                }
            </CardBody>
        </Card>
    );
};

FileBox.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.shape({
        path: PropTypes.string.isRequired,
        height: PropTypes.number,
        width: PropTypes.number
    })]),
    imageHover: PropTypes.shape({
        path: PropTypes.string.isRequired,
        height: PropTypes.number,
        width: PropTypes.number
    }),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    title: PropTypes.string,
    pdfViewLink: PropTypes.string,
    handleDownload: PropTypes.func,
    index: PropTypes.number.isRequired
};

export default FileBox;
