import PropTypes from 'prop-types'
import React from "react";

const FileIcon = ({type}) => {
    return (
        <div className="item-box__image item-box__image--hoverable">
            <svg width="40px" height="50px" viewBox="0 0 40 50" version="1.1">
                <g id="baseline-insert_drive_file-24px-copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-10.000000, -5.000000)" id="Shape">
                        <path d="M15,5 C12.25,5 10.025,7.25 10.025,10 L10,50 C10,52.75 12.225,55 14.975,55 L45,55 C47.75,55 50,52.75 50,50 L50,20 L35,5 L15,5 Z M32.5,22.5 L32.5,8.75 L46.25,22.5 L32.5,22.5 Z" fill="#000000" fillRule="nonzero"/>
                        <polygon points="0 0 60 0 60 60 0 60"/>
                    </g>
                    <text id="DOC" fontFamily="Roboto Condensed" fontSize="12" fontStyle="condensed" fontWeight="normal" fill="#FFFFFF">
                        <tspan x="9.06738281" y="42">{type && type.toUpperCase()}</tspan>
                    </text>
                </g>
            </svg>
        </div>
    );
};

FileIcon.propTypes = {
  type: PropTypes.string.isRequired
};

export default FileIcon;
