import { fetchAllergensData } from '../actions/routines';
import {call, put} from "redux-saga/effects";
import Product from "../services/api/Products";

export function* fetchAllergensSaga(action) {
    try {
        yield put(fetchAllergensData.request());

        const response = yield call(Product.getProducts, action.payload);

        yield put(fetchAllergensData.success({data: response.data, total: response.headers["x-total-count"]}));
    } catch (error) {
        yield put(fetchAllergensData.failure(error.message));
    } finally {
        yield put(fetchAllergensData.fulfill());
    }
}
