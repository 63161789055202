import ProductGroups from './ProductGroups';
import React from 'react';
import { bindActionCreators } from 'redux';
import { fetchFairProductGroups } from '../../../../../actions/routines';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Fair from '../../Fair';

class ProductGroupsContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchFairProductGroups();
    }

    render() {
        const { groups, selectedGroup, onGroupFilter } = this.props;

        return (
            <ProductGroups
                selectedGroup={selectedGroup}
                groups={groups}
                onGroupFilter={onGroupFilter}
            />
        );
    }
}

ProductGroupsContainer.propTypes = {
    fetchFairProductGroups: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        groups: state.fairProductGroups.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                fetchFairProductGroups,
            },
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductGroupsContainer);
