import { fetchProductData } from '../actions/routines';

const initialState = {
    data: null,
    loading: true,
    error: null,
};

export const productDetailsModal = (state = {isOpen: false, id: null}, action) => {
    switch (action.type) {
        case 'PRODUCT_DETAILS_SHOW':
            return {
                isOpen: true,
                id: action.id
            };
        case 'PRODUCT_DETAILS_HIDE':
            return {
                isOpen: false,
                id: null
            };
        default:
            return state
    }
};


const productDetails = (state = initialState, action) => {
    switch (action.type) {
        case fetchProductData.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case fetchProductData.SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case fetchProductData.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchProductData.FULFILL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default productDetails;
