import React from 'react';
import { Tooltip } from 'mdbreact';
import PropTypes from 'prop-types';

const PopoverBadge = ({
    id,
    type,
    badgeText,
    popoverHeader,
    popoverBody,
    backgroundColor,
    fontColor,
    wrapperClassName,
    placement,
    popoverBodyComponent = null,
}) => {
    return (
        <div
            className={`popover-badge__wrapper ${
                wrapperClassName ? wrapperClassName : ''
            }`}
        >
            {/*Ugly hack as JSX does not support !important in inline styles and it is hard to use dynamic variable for arrow color*/}
            <style>
                {`
                    .badge-${id}-${type} + .tooltip .arrow::before {
                        border-left-color: ${backgroundColor} !important;
                    }
                `}
            </style>
            <Tooltip
                className={`popover-badge popover-badge-${type}`}
                placement={placement}
                componentClass={`badge default popover-badge__badge badge-${id}-${type} popover-badge__badge--${type}`}
                componentStyle={{ backgroundColor: backgroundColor }}
                tag="div"
                component="button"
                tooltipContent={
                    <div
                        className="popover-badge__popover"
                        style={{
                            backgroundColor: backgroundColor,
                            color: fontColor,
                        }}
                    >
                        <div
                            className="popover-badge__popover__header"
                            style={{ backgroundColor: backgroundColor }}
                            dangerouslySetInnerHTML={{ __html: popoverHeader }}
                        ></div>
                        <div
                            className="popover-badge__popover__body"
                            dangerouslySetInnerHTML={{ __html: popoverBody }}
                        ></div>
                        <div className="popover-badge__popover__body">
                            {popoverBodyComponent}
                        </div>
                    </div>
                }
            >
                {badgeText}
            </Tooltip>
        </div>
    );
};

PopoverBadge.propTypes = {
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['promotion', 'campaign']).isRequired,
    badgeText: PropTypes.string.isRequired,
    popoverHeader: PropTypes.node.isRequired,
    popoverBody: PropTypes.node.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    fontColor: PropTypes.string,
    wrapperClassName: PropTypes.string,
    placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
};

PopoverBadge.defaultProps = {
    placement: 'left',
    fontColor: 'white',
};

export default PopoverBadge;
