import PropTypes from 'prop-types'
import React from "react";
import {Redirect} from "react-router";
import {connect} from "react-redux";

const SecurityRoute = ({ component: Component, routeType: Route, withHeader, withFooter, isAuthenticated,  ...rest }) => (
    <Route {...rest} render={(props) => (
        isAuthenticated === false ?
            <Component {...props} /> : <Redirect exact to='/dashboard'/>
    )} />
);


const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.security.access_token
    }
};

SecurityRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  withFooter: PropTypes.bool,
  withHeader: PropTypes.bool
};

export default connect(mapStateToProps, null)(SecurityRoute);