import React from 'react';
import NewPassword from "./../components/NewPassword";
import {sendNewPasswordResetToken} from "../../../../../actions/security";
import {connect} from "react-redux";
import qs from 'qs';

class NewPasswordResetTokenContainer extends React.Component {

    onSubmit = (password, passwordRepeat) => {
        const token = this.props.match.params.token;

        this.props.sendNewPasswordResetToken(token, password, passwordRepeat)
    };

    render() {
        const email = qs.parse(this.props.location.search.slice(1)).email;

        return <NewPassword email={email} submit={this.onSubmit} errors={this.props.serverErrors} isLoading={this.props.isLoading}/>;
    }
}

const mapStateToProps = (state) => {
    return {
        serverErrors: state.apiErrors.security,
        isLoading: state.loadingState.loading
    }
};

export default connect(mapStateToProps, {sendNewPasswordResetToken})(NewPasswordResetTokenContainer);
