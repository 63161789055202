import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import * as R from "ramda";
import {Badge} from "mdbreact";

class CampaignPointsBadgeContainer extends Component {
    constructor(props) {
        super(props);

        const productCampaign = this.props.campaign;
        const campaignPrice = this.findCampaignPrice();
        const campaignPoints = this.findCampaignPoints();
        this.state = {
            name: campaignPrice ? campaignPrice.name : 0,
            productPoints: productCampaign.points,
            newPoints: 0,
            userPoints: campaignPoints ? campaignPoints.points : 0,
            campaignPrice: campaignPrice ? campaignPrice.points : '',
            pointsFromAmount: productCampaign.points_from_amount,
            specialPoints: productCampaign.special_points,
            specialPointsEvery: productCampaign.special_points_every
        }
    }

    componentDidMount() {
        if (!R.isEmpty(this.props.cart)) {
            this.calculateProperPoints();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {cart, campaign} = this.props;

        //Calculate proper points based on number of products added to cart
        if (!R.equals(prevProps.cart, cart) && campaign) {
            this.calculateProperPoints(prevState.name);
        }
    }

    findCampaignPrice = () => {
        const productCampaign = this.props.campaign;
        const campaign = productCampaign.campaign;

        if (!campaign || !campaign.campaign_prices) return null;
        if (campaign.campaign_prices.length === 0) return null;

        return campaign.campaign_prices[0];
    };

    findCampaignPoints = () => {
        const productCampaign = this.props.campaign;
        const campaign = productCampaign.campaign;

        if (!campaign || !campaign.campaign_points) return null;
        if (campaign.campaign_points.length === 0) return null;

        return campaign.campaign_points[0];
    };

    calculateProperPoints = (name = '') => {
        const {campaign} = this.props;

        const newPoints = this.getNewPoints();
        const campaignPoints = this.findCampaignPoints();
        const userPoints = campaignPoints ? campaignPoints.points : 0;
        const totalPoints = userPoints + newPoints + this.calculateOtherProductsPoints();
        const campaignPrice = this.findCampaignPriceByPoints(totalPoints);

        this.calculateOtherProductsPoints();
        this.setState({
            name: campaignPrice ? campaignPrice.name : name,
            productPoints: campaign.points,
            pointsFromAmount: campaign.points_from_amount,
            newPoints: newPoints,
            userPoints: totalPoints,
            campaignPrice: campaignPrice ? campaignPrice.points : '',
        })
    };

    findCampaignPriceByPoints = (points) => {
        const campaign = this.props.campaign.campaign;

        if (!campaign || !campaign.campaign_prices) return null;
        if (campaign.campaign_prices.length === 0) return null;

        const where = R.where({
            points: R.gt(R.__, points)
        });

        return R.find(where)(campaign.campaign_prices);
    };

    getNewPoints = () => {
        const campaign = this.props.campaign.campaign;
        const product = this.findAllProductData();

        if (!campaign) return 0;

        return product && product.campaign_new_points && product.campaign_new_points[campaign.id]
            ? product.campaign_new_points[campaign.id] : 0
    };

    calculateOtherProductsPoints = () => {
        const {productId, cart, campaign: {campaign}} = this.props;

        if (!productId || R.isEmpty(cart) || R.isEmpty(cart.basket_items)) {
            return 0;
        }

        let points = 0;
        cart.basket_items.forEach(product => {
            const p = product.campaign_new_points[campaign.id];

            let pid = product.product ? product.product.id : product.id;
            if (p && pid !== productId) {
                points += p;
            }
        });

        return points;
    }

    findAllProductData = () => {
        const {productId, cart} = this.props;

        if (!productId || R.isEmpty(cart) || R.isEmpty(cart.basket_items)) {
            return 0;
        }

        const hasProduct = R.propEq('id', productId);

        let findMethod = R.compose(hasProduct, R.prop('product'));
        if (!cart.basket_items[0].product) { //If the amount is inside product directly (happens on cart page)
            findMethod = hasProduct
        }

        return R.find(findMethod)(cart.basket_items);

    };


    render() {
        const {newPoints} = this.state;

        const campaign = this.props.campaign.campaign;

        //Do not display badge when there is no campaign products or calculation based on product quantity is not finished
        if (!campaign || !campaign.is_active) {
            return '';
        }

        return (
            newPoints > 0 &&
            <Badge
                className={`badge-campaign badge-campaign-${campaign.id}`}
            >
                {/*Ugly hack as JSX does not support !important in inline styles and it is hard to use dynamic variable for arrow color*/}
                <style>
                    {`
                        .badge-campaign-${campaign.id} {
                            background-color: ${campaign.color ? campaign.color  : '#000'} !important;
                        }
                    `}
                </style>
                {`${newPoints} pt`}
            </Badge>
        )

        /*
        return (
            <CampaignPointsBadge
                id={campaign.id}
                name={name}
                color={campaign.color}
                productPoints={productPoints}
                newPoints={newPoints}
                userPoints={userPoints}
                campaignPrice={campaignPrice}
                pointsFromAmount={pointsFromAmount}
                specialPoints={specialPoints}
                specialPointsEvery={specialPointsEvery}
            />
        );
        */
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart.data
    }
};

export default connect(mapStateToProps, null)(CampaignPointsBadgeContainer);

CampaignPointsBadgeContainer.propTypes = {campaign: PropTypes.any};
