import React from 'react';
import PropTypes from 'prop-types';
import PasswordField from "../PasswordField";
import ButtonWithSpinner from "../../../Utils/ButtonWithSpinner";

/** PROPS ARE PASSED FROM src/components/Security/NewPasswordFields/NewPasswordFieldsContainer.jsx to keep logic there. **/
const NewPasswordFields = ({
    password, passwordRepeat, handleOnChange, isPasswordFormatValid, arePasswordEquals,
    validatePasswordFormat, validatePasswordEquals, onSubmit, isLoading
}) => {
    return (
        <form className="form--white-text">
            <PasswordField
                value={password}
                name="password"
                onChange={handleOnChange}
                className={!isPasswordFormatValid === false ? '' : 'is-invalid'}
                invalidFeedback="security.new_password.invalid_format"
                onBlur={validatePasswordFormat}
                label="security.new_password.password"
            />
            <PasswordField
                value={passwordRepeat}
                name="passwordRepeat"
                onChange={handleOnChange}
                className={!arePasswordEquals === false ? '' : 'is-invalid'}
                invalidFeedback="security.new_password.password_not_equals"
                onBlur={validatePasswordEquals}
                label="security.new_password.password_repeat"
            />
            <div className="login__sign-up">
                <ButtonWithSpinner
                    onClick={onSubmit}
                    label="security.new_password.save"
                    isLoading={isLoading}
                    type="button"
                    className="btn-brand"
                />
            </div>
        </form>
    );
};

NewPasswordFields.propTypes = {
    password: PropTypes.string,
    passwordRepeat: PropTypes.string,
    handleOnChange: PropTypes.func,
    isPasswordFormatValid: PropTypes.bool,
    arePasswordEquals: PropTypes.bool,
    validatePasswordFormat: PropTypes.func,
    validatePasswordEquals: PropTypes.func,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool
};

export default NewPasswordFields;
