import PropTypes from "prop-types";
import React from "react";

const ImageIcon = ({ image, className, fileImage }) => {
    if (fileImage) {
        return (
            <img
                src={fileImage}
                className={`item-box__image img-fluid image-file ${className}`}
                alt="box"
            />
        );
    } else if (image && image.path) {
        return (
            <img
                src={image.path}
                className={`item-box__image img-fluid ${className}`}
                alt="box"
            />
        );
    }

    return; //COMPONENT
};

ImageIcon.propTypes = {
    className: PropTypes.string,
    image: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            height: PropTypes.number,
            width: PropTypes.number
        })
    ])
};

export default ImageIcon;
