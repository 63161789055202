import request from './request';

const Delivery = {
    getSchema: () => {
        return request({
            url: '/api/delivery/',
            method: 'GET',
        });
    },
    getHistory: (params) => {
        return request({
            url: `/api/delivery/history`,
            method: 'GET',
            params: params
        });
    },
    getPdf: (orderNumber) => {
        return request({
            url: `/api/delivery/pdf/${orderNumber}`,
            method: 'GET',
            responseType: 'arraybuffer'
        });
    },
    getExcel: (orderNumber) => {
        return request({
            url: `/api/delivery/excel/${orderNumber}`,
            method: 'GET',
            responseType: 'arraybuffer'
        });
    },
    getXml: (orderNumber) => {
        return request({
            url: `/api/delivery/xml/${orderNumber}`,
            method: 'GET',
            responseType: 'arraybuffer'
        });
    }
};

export default Delivery;