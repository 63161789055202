export const fetchProducts = (data) => ({
    type: 'FETCH_PRODUCTS',
    data
});

export const productsFetched = (data) => ({
    type: 'PRODUCTS_FETCHED',
    data
});

export const productsFetchedFailure = (error) => ({
    type: 'PRODUCTS_FETCHED_FAILURE',
    error
});