import { removeFromAssortment } from '../actions/routines';

const masterTable = (state = {}, action) => {
    switch (action.type) {
        case 'FETCH_PRODUCTS':
            return { ...state, ...action.data };
        case 'PRODUCTS_FETCHED':
            return {
                ...state,
                products: action.data.products,
                total: action.data.total,
                myTotal: action.data.myTotal,
                myFavorite: action.data.myFavorite,
                hasMyList: action.data.hasMyList,
                hasMore: action.data.hasMore,
                currentPage: action.data.currentPage,
                totalWithPromotionFilter: action.data.totalWithPromotionFilter,
                totalWithoutPromotionFilter:
                    action.data.totalWithoutPromotionFilter,
            };
        case removeFromAssortment.SUCCESS:
            return {
                ...state,
                products: action.payload.data,
                total: action.payload.total,
                hasMyList: action.payload.hasMyList,
                hasMore: action.payload.hasMore,
                currentPage: action.payload.currentPage,
                totalWithPromotionFilter:
                    action.payload.totalWithPromotionFilter,
                totalWithoutPromotionFilter:
                    action.payload.totalWithoutPromotionFilter,
            };
        default:
            return state;
    }
};

export default masterTable;
