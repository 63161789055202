import PropTypes from 'prop-types'
import React from "react";
import {Card, CardBody, CardTitle, CardText} from 'mdbreact';
import FavouriteIcon from "../../../Favourite/FavouriteIcon";
import ImageIcon from "./ImageIcon";
import ItemLink from "./ItemLink";
import truncateWithEllipses from "../../../Utils/truncateWithEllipses";

const ItemBox = ({id, image, imageHover, title, text, link, isExternalLink, isFavourite, toggleFavourites}) => {
    return (
        <Card className="item-box">
            <ItemLink link={link} isExternal={isExternalLink}/>
            <div className="item-box__image-wrapper" style={image && image.width ? {width: image.width} : {}}>
                {image &&
                    <ImageIcon image={image} className={imageHover ? 'item-box__image--hoverable' : ''}/>
                }
                {imageHover &&
                    <img src={imageHover.path} style={{height: imageHover.height}} className="item-box__image--hover img-fluid" alt="hover"/>
                }
            </div>
            <CardBody>
                {toggleFavourites &&
                    <FavouriteIcon onClick={() => toggleFavourites(id)} isFavourite={isFavourite}/>
                }
                {title &&
                <CardTitle title={title} className="item-box__title">{truncateWithEllipses(title)}</CardTitle>
                }

                <CardText className="item-box__text">{text}</CardText>
            </CardBody>
        </Card>
    );
};

ItemBox.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.shape({
        path: PropTypes.string.isRequired,
        height: PropTypes.number,
        width: PropTypes.number
    })]),
    imageHover: PropTypes.shape({
        path: PropTypes.string.isRequired,
        height: PropTypes.number,
        width: PropTypes.number
    }),
    link: PropTypes.string.isRequired,
    isExternalLink: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    title: PropTypes.string,
    inFavourites: PropTypes.bool,
    toggleFavourites: PropTypes.func
};

ItemBox.defaultProps = {
    isExternalLink: false
};

export default ItemBox;
