import request from './request';

const Products = {
    getProducts: ({ deliveryDate, params }) => {
        if (deliveryDate) {
            params.deliveryDate = new Date(deliveryDate).toISOString();
        }

        return request({
            url: `/api/product/list`,
            method: 'GET',
            params: params,
        });
    },
    getProduct: ({ id }) => {
        return request({
            url: `/api/product/${id}`,
            method: 'GET',
        });
    },
    getTopList: () => {
        return request({
            url: '/api/product/toplist?with-banners=1',
            method: 'GET',
        });
    },
    getMyListPDF: ({ params }) => {
        return request({
            url: `/api/product/pdf`,
            method: 'GET',
            responseType: 'arraybuffer',
            params: params,
        });
    },
    getMyListExcel: ({ params }) => {
        return request({
            url: `/api/product/excel`,
            method: 'GET',
            responseType: 'arraybuffer',
            params: params,
        });
    },
    getProductSpecificationPdf: (id) => {
        return request({
            url: `/api/product/allergensheet/${id}`,
            method: 'GET',
            responseType: 'arraybuffer',
        });
    },
    getAllCategories: () => {
        return request({
            url: '/api/product/categories',
            method: 'GET',
        });
    },
    getSubCategories: (id, queryParams) => {
        if (queryParams['s_category'] !== undefined) {
            delete queryParams['s_category'];
        }

        return request({
            url: `/api/product/categories/${id}`,
            method: 'GET',
            params: queryParams,
        });
    },
    getOrderHistory: (id) => {
        return request({
            url: `/api/product/order-history/${id}`,
            method: 'GET',
        });
    },
    getAllergenListPdf: () => {
        return request({
            url: `/api/product/allergen-list/pdf`,
            method: 'GET',
            responseType: 'arraybuffer',
        });
    },
    removeFromAssortiment: ({ id, params }) => {
        return request({
            url: `/api/product/remove-from-assortiment/${id}`,
            method: 'DELETE',
            params: params,
        });
    },
    getAllSuppliers: () => {
        return request({
            url: '/api/product/suppliers',
            method: 'GET',
        });
    },
    toggleFavourite: (id) => {
        return request({
            url: `/api/product/favorite/${id}`,
            method: 'PATCH',
        });
    },
    getFavouritesList: ({ limit, offset }) => {
        return request({
            url: `/api/product/favourites/${limit}/${offset}`,
            method: 'GET',
        });
    },
    getTag: (id) => {
        return request({
            url: `/api/tag/${id}`,
            method: 'GET',
        });
    },
};

export default Products;
