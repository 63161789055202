import { fetchRecipeCategories } from '../actions/routines';
import {call, put} from "redux-saga/effects";
import Recipe from "../services/api/Recipe";

export function* fetchRecipeCategoriesSaga(action) {
    try {
        yield put(fetchRecipeCategories.request());

        const response = yield call(Recipe.getCategories, action.payload);

        yield put(fetchRecipeCategories.success(response.data));
    } catch (error) {
        yield put(fetchRecipeCategories.failure(error.message));
    } finally {
        yield put(fetchRecipeCategories.fulfill());
    }
}