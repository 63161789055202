import PropTypes from 'prop-types'
import React from "react";
import {FormattedMessage} from "react-intl";

const DiscountSummaryBox = ({userPoints}) => (
    <div className="discount-summary-box">
        <p className="discount-summary-box__title"><FormattedMessage id="fair.discount_summary_box.title"/></p>
        <p className="discount-summary-box__amount-label"><FormattedMessage id="fair.discount_summary_box.amount_label"/>
        </p>
        <p className="discount-summary-box__amount">&euro; {userPoints}</p>
    </div>
);

DiscountSummaryBox.propTypes = {
  userPoints: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DiscountSummaryBox.defaultProps = {
  userPoints: 0,
};

export default DiscountSummaryBox;