import PropTypes from 'prop-types'
import React from "react";
import FavouriteIcon from "../../../Favourite/FavouriteIcon";
import {FormattedMessage} from "react-intl";
import {Col, Row} from "mdbreact";

const RecipeDetails = ({id, title, image, ingredients, preparation, isFavourite, toggleFavourites, goBack, print}) => {
    const headerStyles = {
        backgroundImage: `url(${image.path})`,
        height: image.height
    };

    return (
        <div className="recipe__details">
            <div className="container-fluid full-width recipe__details__header" style={headerStyles}>
                <img src={image.path} alt="print" className="recipe__details__header__image--print"/>
                <div className="recipe__details__header__body">
                    <div className="recipe__details__header__body__inner">
                        <FavouriteIcon isFavourite={isFavourite} onClick={() => toggleFavourites(id)}/>
                        <h1 className="recipe__details__header__body__title">{title}</h1>
                        <button className="btn-white" onClick={print}>
                            <FormattedMessage id="recipes.details.print_btn"/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="recipe__details__go-back">
                <span onClick={goBack}>
                    <FormattedMessage id="recipes.details.go_back"/>
                </span>
            </div>
            <div className="recipe__details__body">
                <Row>
                    <Col sm="5" className="recipe__details__body__ingredients">
                        <div className="recipe__details__body__ingredients__content">
                            <ul className="recipe__details__body__ingredients__content__list">
                                {ingredients.map(ingredient => (
                                    <li key={ingredient.name}>{ingredient.name} {ingredient.amount}</li>
                                ))}
                            </ul>
                            <h2 className="recipe__details__body__ingredients__content__title">
                                <FormattedMessage id="recipes.details.ingredients"/>
                            </h2>
                        </div>
                    </Col>
                    <Col sm="6" className="offset-1 recipe__details__body__instructions">
                        <h2 className="recipe__details__body__instructions__title"><FormattedMessage id="recipes.details.instructions"/></h2>
                        <div className="recipe__details__body__instructions__content" dangerouslySetInnerHTML={{__html: preparation}}/>
                    </Col>
                </Row>
            </div>
        </div>
    );
};


RecipeDetails.propTypes = {
    goBack: PropTypes.func,
    image: PropTypes.object,
    inFavourites: PropTypes.bool,
    ingredients: PropTypes.array,
    preparation: PropTypes.string,
    print: PropTypes.func,
    title: PropTypes.string,
    toggleFavourites: PropTypes.func
};

export default RecipeDetails;
