const saveFile = (response, filename) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (typeof window.chrome !== 'undefined' || isSafari) {
        // Chrome version
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        link.download = filename;
        link.click();
    } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE version
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        window.navigator.msSaveBlob(blob, filename);
    } else {
        // Firefox version
        const file = new File([response.data], filename, { type: 'application/force-download' });
        window.open(URL.createObjectURL(file));
    }
};

export default saveFile;