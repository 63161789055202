import {
    fetchReadNotifications,
    fetchUnreadNotifications,
    fetchUnreadNormalNotifications,
    fetchUnreadServiceNotifications,
    setAsReadNotifications
} from '../actions/routines';
import {call, put} from "redux-saga/effects";
import Notifications from "../services/api/Notifications";

export function* fetchReadNotificationsSaga() {
    try {
        yield put(fetchReadNotifications.request());

        const response = yield call(Notifications.getAllRead);

        yield put(fetchReadNotifications.success(response.data));
    } catch (error) {
        yield put(fetchReadNotifications.failure(error.message));
    } finally {
        yield put(fetchReadNotifications.fulfill());
    }
}

export function* fetchUnreadNotificationsSaga() {
    try {
        yield put(fetchUnreadNotifications.request());

        const response = yield call(Notifications.getAllUnread);

        yield put(fetchUnreadNotifications.success(response.data));
    } catch (error) {
        yield put(fetchUnreadNotifications.failure(error.message));
    } finally {
        yield put(fetchUnreadNotifications.fulfill());
    }
}

export function* fetchUnreadNormalNotificationsSaga() {
    try {
        yield put(fetchUnreadNormalNotifications.request());

        const response = yield call(Notifications.getNormalUnread);

        yield put(fetchUnreadNormalNotifications.success(response.data));
    } catch (error) {
        yield put(fetchUnreadNormalNotifications.failure(error.message));
    } finally {
        yield put(fetchUnreadNormalNotifications.fulfill());
    }
}

export function* fetchUnreadServiceNotificationsSaga() {
    try {
        yield put(fetchUnreadServiceNotifications.request());

        const response = yield call(Notifications.getServiceUnread);

        yield put(fetchUnreadServiceNotifications.success(response.data));
    } catch (error) {
        yield put(fetchUnreadServiceNotifications.failure(error.message));
    } finally {
        yield put(fetchUnreadServiceNotifications.fulfill());
    }
}

export function* setAsReadNotificationsSaga(action) {
    try {
        yield put(setAsReadNotifications.request());

        const response = yield call(Notifications.setAsRead, action.payload);

        yield put(setAsReadNotifications.success(response.data));
    } catch (error) {
        yield put(setAsReadNotifications.failure(error.message));
    } finally {
        yield put(setAsReadNotifications.fulfill());
    }
}