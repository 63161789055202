import PropTypes from 'prop-types'
import React from 'react';
import { FormattedHtmlMessage } from "../../../Utils/FormattedHtmlMessage";
import Currency from "../../../Utils/Currency";
import NotificationContainer from "../../../Notification/NotificationContainer";
import errorWhite from "../../../../assets/images/error-white.svg";
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import { closeTransportCostWarning } from "../../../../actions";

class TransportCostWarning extends React.Component {
    close = () => {
        this.props.closeTransportCostWarning()
    };

    render() {
        const {show, amount} = this.props;

        return (
            <NotificationContainer
                icon={errorWhite}
                status="warning"
                type="dialog"
                text={
                    <p>
                        <FormattedHtmlMessage
                          id="cart.notification.no_transport_cost.text"
                          values={{
                            amount: <Currency value={amount} />
                          }}
                        />
                    </p>
                }
                show={show}
                handleClose={this.close}
            />
        );
    }
}

TransportCostWarning.propTypes = {
    show: PropTypes.bool,
    amount: PropTypes.number,
    closeTransportCostWarning: PropTypes.func,
};

TransportCostWarning.defaultProps = {
    show: false,
    amount: 0,
};

const mapStateToProps = state => {
    return {
        show: !!+state.cart.transportCostAmount,
        amount: state.cart.transportCostAmount
    }
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ closeTransportCostWarning }, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TransportCostWarning);

