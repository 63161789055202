import React from 'react';
import PropTypes from 'prop-types';
import TitleHeader from '../components/TitleHeader';
import { campaignsGrey } from '../../../assets/images/icons';
import Item from './components/Item/Item';
import Spinner from '../../Utils/Spinner';

const Campaign = ({ items, loading }) => {
    return (
        <div className="campaign">
            <TitleHeader
                title="campaign.title"
                icon={campaignsGrey}
                className="campaign-header"
            />

            {loading ? (
                <div className="d-flex justify-content-center">
                    <Spinner width={50} height={50} className="brand-color" />
                </div>
            ) : (
                items.map((item) => {
                    return (
                        <Item
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            image={item.image}
                            pdf={item.pdf}
                            activeFrom={item.start_date}
                            activeTo={item.end_date}
                            variants={item.campaign_prices}
                            description={item.description}
                            color={item.color}
                            userPoints={
                                item.campaign_points.length > 0
                                    ? item.campaign_points[0].points
                                    : 0
                            }
                        />
                    );
                })
            )}
        </div>
    );
};

Campaign.propTypes = {
    items: PropTypes.array.isRequired,
};

export default Campaign;
