import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'
import {Col, Row} from "mdbreact";
import FormattedMessageWithClass from "../../../Utils/FormattedMessageWithClass";

const SectionButton = ({to, label, icon, iconHover, isHovered, onMouseEnter, onMouseLeave, isExternal, isDisabled}) => {
    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={`dashboard__section-button ${isDisabled && 'dashboard__section-button--disabled'}`}>
            {isExternal ? <a href={to} target="_blank"/> : <Link to={to}/>}
            <Row>
                <Col>
                    <img className="dashboard__section-button__icon" src={isHovered ? iconHover : icon} alt={label}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormattedMessageWithClass className="dashboard__section-button__label" id={label}/>
                </Col>
            </Row>
        </div>
    );
};

SectionButton.propTypes = {
  isDisabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  iconHover: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  isHovered: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired
};


SectionButton.defaultProps = {
  isDisabled: false,
  isExternal: false
};

export default SectionButton;
