const fairDeliveryDates = (state = {data: []}, action) => {
    switch (action.type) {
        case 'FAIR_DELIVERY_DATES_SET':
            return {
                ...state,
                data: action.deliveryDates
            };
        default:
            return state
    }
};

export default fairDeliveryDates