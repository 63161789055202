import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'mdbreact';
import { FormattedMessage } from 'react-intl';
import FileBox from './components/FileBox';
import MyOrderListContainer from '../Table/components/MyOrderList/MyOrderListContainer';
import Specification from './components/Specification';
import Allergens from './components/Allergens/Allergens';
import Promotions from './components/Promotions';
import Campaigns from './components/Campaigns';
import pdfImg from '../../assets/images/pdfImg.svg';
import QuantityContainer from '../Quantity/QuantityContainer';
import placeholderImage from '../../assets/images/image-placeholder.svg';
import Categories from '../Categories';

const ProductDetails = ({
    product,
    hasTechnicalSheet,
    isOpen,
    closeModal,
    inAssortment,
    handleDownloadProductSpecificationsPdf,
    handleRemoveFromAssortment,
    fetchProducts,
    handleTagClick,
}) => {
    return (
        <React.Fragment>
            <span onClick={closeModal} className="modal-close" />
            <div className="product-details__wrapper-header">
                <Categories
                    fetchProducts={fetchProducts}
                    currentCategory={product.category}
                    location={null}
                    showOnlyBreadcrumbs={true}
                    closeModal={closeModal}
                />
                <section>
                    <Row>
                        <Col>
                            <img
                                key={
                                    product.images.length
                                        ? product.images[0].url
                                        : placeholderImage
                                }
                                src={
                                    product.images.length
                                        ? product.images[0].url
                                        : placeholderImage
                                }
                                className="product-details__images__wrapper__image img-fluid"
                                alt="product"
                            />
                        </Col>
                        <Col className="product-details__left-side">
                            <div>
                                {inAssortment && (
                                    <span className="product-details__order__on-order-list">
                                        <MyOrderListContainer
                                            id={product.id}
                                            callback={
                                                handleRemoveFromAssortment
                                            }
                                            text="product.remove_from_assortiment"
                                        />
                                    </span>
                                )}

                                <p className="product-details__title">
                                    {product.name}
                                </p>
                            </div>
                            <div>
                                <p className="product-details__article_nr">
                                    {product.article_nr && (
                                        <Specification
                                            label="product_details.specifications.article_nr_lower"
                                            value={product.article_nr}
                                        />
                                    )}
                                </p>

                                <Row className="product-novelty__item-box__price-wraper">
                                    {product.price && (
                                        <Col className="product-novelty__item-box__price">
                                            <span>{product.price.label}</span>
                                        </Col>
                                    )}

                                    <Col className="product-novelty__item-box__quantity">
                                        <QuantityContainer
                                            product={product}
                                            productId={product.id}
                                            quantity={product.amount}
                                            itemsInPackage={
                                                product.items_in_package
                                            }
                                            orderAsPackage={
                                                product.order_as_package
                                            }
                                            tabIndex={9292929292 + product.id} //Small hack to omit toplist in tab-ing
                                            isModal
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </section>
            </div>

            {(product.promotions.length > 0 ||
                product.campaigns.length > 0) && (
                <section className="product-details__promotion-campaign">
                    <Row className="product-details__promotion-campaign__list">
                        {product.promotions.length > 0 && (
                            <Promotions promotions={product.promotions} />
                        )}
                        {product.campaigns.length > 0 && (
                            <Campaigns
                                campaigns={product.campaigns.filter(
                                    (campaign) => campaign.campaign.is_active
                                )}
                            />
                        )}
                    </Row>
                </section>
            )}
            {/* <section className="product-details__images">
                <Row>
                    <Col>
                        <div className="product-details__images__wrapper">
                            {product.images.map((image) => (
                                <img
                                    key={image.url}
                                    src={image.url}
                                    className="product-details__images__wrapper__image img-fluid"
                                    alt="product"
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
            </section> */}
            <section className="product-details__specifications">
                <Row>
                    <Col
                        size="6"
                        className="product-details__specification-list"
                    >
                        <Row>
                            <Col>
                                <p className="product-details__section__title">
                                    <FormattedMessage id="product_details.specifications.title_lower" />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="column-list">
                                {product.article_nr && (
                                    <Specification
                                        label="product_details.specifications.article_nr"
                                        value={product.article_nr}
                                    />
                                )}
                                {product.weight && (
                                    <Specification
                                        label="product_details.specifications.weight"
                                        value={product.weight}
                                    />
                                )}
                                {product.availableFrom && (
                                    <Specification
                                        label="product_details.specifications.availability"
                                        value={product.availableFrom}
                                    />
                                )}
                                {product.supplier && (
                                    <Specification
                                        label="product_details.specifications.supplier"
                                        value={product.supplier}
                                    />
                                )}
                                {product.ean14 && (
                                    <Specification
                                        label="product_details.specifications.ean_14"
                                        value={product.ean14}
                                    />
                                )}
                                {product.ean13 && (
                                    <Specification
                                        label="product_details.specifications.ean_13"
                                        value={product.ean13}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <div className="product-details__divider" />
                    <Col size="5">
                        {(product.allergens.has_sheet || product.files) && (
                            <section className="product-details__downloads">
                                <Row>
                                    <Col>
                                        <p className="product-details__section__title">
                                            <FormattedMessage id="product_details.downloads.title_lower" />
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-wrap">
                                        {product.allergens.has_sheet && (
                                            <FileBox
                                                key={0}
                                                name={
                                                    <FormattedMessage id="product_details.downloads.sheet_file_title" />
                                                }
                                                handleDownload={
                                                    handleDownloadProductSpecificationsPdf
                                                }
                                                image={{
                                                    path: pdfImg,
                                                    width: 55,
                                                    height: 69,
                                                }}
                                                inline={true}
                                            />
                                        )}
                                        {product.files &&
                                            product.files.map((file) => (
                                                <FileBox
                                                    key={file.name}
                                                    name={file.name}
                                                    link={file.downloadUrl}
                                                    image={file.image}
                                                    inline={true}
                                                />
                                            ))}
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="tagsContainer">
                                        {product.tags.map((tag) => (
                                            <div className="tag" key={tag.id}>
                                                <a
                                                    href="#"
                                                    onClick={() =>
                                                        handleTagClick(tag.id)
                                                    }
                                                    className="tag__text"
                                                >
                                                    {tag.name}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </Row>
                            </section>
                        )}
                    </Col>
                </Row>
            </section>
            <section className="product-details__description">
                <Row>
                    <Col>
                        <p className="product-details__section__title">
                            <FormattedMessage id="product_details.description.title_lower" />
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {product.description ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: product.description,
                                }}
                                className="product-details__description__text"
                            ></div>
                        ) : (
                            <FormattedMessage id="product_details.description.no_description" />
                        )}
                    </Col>
                </Row>
            </section>
            <section className="product-details__allergens">
                <Row>
                    <Col>
                        <p className="product-details__section__title">
                            <FormattedMessage id="product_details.allergens.title_lower" />
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Allergens allergens={product.allergens} />
                    </Col>
                </Row>
            </section>
        </React.Fragment>
    );
};

ProductDetails.propTypes = {
    closeModal: PropTypes.func,
    files: PropTypes.array,
    handleDownloadAllergensSheet: PropTypes.func,
    hasTechnicalSheet: PropTypes.bool,
    isOpen: PropTypes.bool,
    onOrderList: PropTypes.bool,
    product: PropTypes.object,
};

export default ProductDetails;
