const loadingState = (state = {loading: false}, action) => {
    switch (action.type) {
        case 'LOADING_START':
            return {loading: true};
        case 'LOADING_STOP':
            return {loading: false};
        default:
            return state
    }
};

export default loadingState;