import { fetchPromotions } from '../actions/routines';

const initialState = {
    data: [],
    total: 0,
    loading: true,
    error: null,
    hasMore: false,
    currentPage: 1,
};

const promotions = (state = initialState, action) => {
    switch (action.type) {
        case fetchPromotions.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case fetchPromotions.SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total,
                hasMore: action.payload.hasMore,
                currentPage: action.payload.currentPage,
            };
        case fetchPromotions.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchPromotions.FULFILL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default promotions;
