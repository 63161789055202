import React from 'react';
import RecipeDetails from "./RecipeDetails";
import {fetchRecipeDetails, toggleRecipeFavourites} from "../../../../actions/routines";
import {connect} from "react-redux";
import Spinner from "../../../Utils/Spinner";

class RecipeDetailsContainer extends React.Component {
    componentDidMount() {
        const { match: { params: { slug } } } = this.props;
        this.props.fetchRecipeDetails(slug)
    }

    toggleFavourites = (id) => {
        this.props.toggleRecipeFavourites(id)
    };

    print = () => {
        const oldTitle = document.title;
        document.title = this.props.recipe.title;
        window.print();
        document.title = oldTitle;
    };

    render() {

        const {history, recipe} = this.props;

        if (!recipe) {
            return <div className="flex-center mt-5"> <Spinner className="brand-color" width={50} height={50}/></div>
        }
        return (
            <RecipeDetails
                id={recipe.id}
                title={recipe.title}
                image={recipe.image}
                ingredients={recipe.ingredients}
                preparation={recipe.preparation}
                isFavourite={recipe.isFavourite}
                toggleFavourites={this.toggleFavourites}
                goBack={history.goBack}
                print={this.print}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        recipe: state.recipeDetails.data,
        loading: state.recipeDetails.loading,
    }
};

export default connect(mapStateToProps, {fetchRecipeDetails, toggleRecipeFavourites})(RecipeDetailsContainer);
