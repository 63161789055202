import React from 'react';
import {FormattedHtmlMessage} from "./FormattedHtmlMessage";
import PropTypes from 'prop-types';

const FormattedMessageWithClass = props => (
    <FormattedHtmlMessage {...props}>
        {(txt) => (
            <span className={props.className}>
          {txt}
        </span>
        )}
    </FormattedHtmlMessage>
);

FormattedMessageWithClass.propTypes = {
    className: PropTypes.string.isRequired
};

export default FormattedMessageWithClass;
