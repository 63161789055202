import PropTypes from 'prop-types';
import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

const ImagePromotion = ({ data }) => {
    return (
        <div>
            <a href={data.url}>
                <BrowserView>
                    <img src={data.image} alt="promotion" width="80%" />
                </BrowserView>
                <MobileView>
                    <img src={data.mobile_image} alt="promotion" width="80%" />
                </MobileView>
            </a>
        </div>
    );
};

ImagePromotion.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ImagePromotion;
