import React, { Component } from 'react';
import { globeGrey, globeWhite } from '../../assets/images/icons';
import PropTypes from 'prop-types';
import LanguageSwitcherContainer from '../LanguageSwitcher/LanguageSwitcherContainer';
import { connect } from 'react-redux';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDisclaimerOpen: false,
        };
    }

    render() {
        const { inverted, userDetails } = this.props;
        const email = 'info@huppa.be';

        const footerText = userDetails?.show_only_fair_module
            ? 'HUPPA OOSTENDE | ZANDVOORDESTRAAT 370 | 8400 OOSTENDE | T 059 80 47 47 | '
            : 'WAREGEMSEWEG 28 | 9790 WORTEGEM-PETEGEM | T 056 68 95 75 | F 056 68 76 23 | ';

        return (
            <footer className={`footer ${inverted ? 'footer--inverted' : ''}`}>
                <p className="footer__contact-info">
                    {footerText}{' '}
                    <a href={`mailto:${email}`}>{email.toLocaleUpperCase()}</a>
                </p>
                <img
                    className="footer__icon"
                    src={inverted ? globeWhite : globeGrey}
                    alt="globe icon"
                />
                <LanguageSwitcherContainer variant="footer" />
            </footer>
        );
    }
}

Footer.propTypes = {
    inverted: PropTypes.bool,
};

Footer.defaultProps = {
    inverted: false,
};

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails.data,
    };
};

export default connect(mapStateToProps)(Footer);
