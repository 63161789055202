import React from 'react';
import ProductSlider from "./ProductSlider";
import * as R from 'ramda';
import {fetchProductTopList} from "../../actions/routines";
import {connect} from "react-redux";
import {showProductDetails} from "../../actions/productDetails";
import Spinner from "../Utils/Spinner";

class ProductSliderContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            loading: false,
            windowWidth: 0,
            showItems: 5
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateNumberOfItems);

        this.props.fetchProductTopList();
    }

    componentDidUpdate(prevProps) {
        if (
            (!R.equals(prevProps.products, this.props.products) || this.state.products.length === 0)
            && this.props.products.length > 0
        ) {
            const products = R.splitEvery(this.state.showItems, this.props.products);
            this.setState({
                products: products
            });

            this.updateNumberOfItems();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateNumberOfItems);
    }

    updateSlider = () => {
        const products = R.splitEvery(this.state.showItems, R.flatten(this.props.products));
        this.setState({
            products: products
        });
    };

    updateNumberOfItems = () => {
        if (window.innerWidth < 1200 && this.state.showItems !== 4) {
            this.setState({
                showItems: 4
            }, () => this.updateSlider())
        } else if (window.innerWidth >= 1200 && this.state.showItems <= 4) {
            this.setState({
                showItems: 5
            }, () => this.updateSlider())
        }
    };

    openProductDetails = (id) => {
        const {showProductDetails, deliveryDate} = this.props;

        showProductDetails(id, deliveryDate);
    };

    render() {
        if (this.props.loading) {
            return <div className="d-flex justify-content-center my-4">
                <Spinner className="brand-color"/>
            </div>
        }

        if (this.props.products.length === 0) {
            return '';
        }

        return <ProductSlider
            products={this.state.products}
            openProductDetails={this.openProductDetails}
        />
    }
}

ProductSliderContainer.propTypes = {};

const mapStateToProps = state => {
    return {
        products: state.productTopList.data,
        loading: state.productTopList.loading,
        deliveryDate: state.deliveryDatepicker.deliveryDate
    }
};

export default connect(mapStateToProps, {fetchProductTopList, showProductDetails})(ProductSliderContainer);