import React from 'react';
import Quantity from './Quantity';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addItemCart } from '../../actions/routines';
import debounce from 'lodash.debounce';
import swal from 'sweetalert2';
import { injectIntl } from 'react-intl';

class QuantityContainer extends React.Component {
    constructor(props) {
        super(props);

        const { quantity, itemsInPackage, orderAsPackage, isShortInterface } =
            this.props;

        this.state = {
            value: quantity,
            itemsInPackage: orderAsPackage ? itemsInPackage : 1,
            previousValue: 0,
            isShortInterface:
                typeof isShortInterface !== 'undefined'
                    ? isShortInterface
                    : true,
        };

        this.ref = null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.productId !== this.props.productId) {
            //Update quantity when deleting row in table in cart
            this.setState({
                value: this.props.quantity,
                previousValue: this.props.quantity,
                itemsInPackage: this.props.orderAsPackage
                    ? this.props.itemsInPackage
                    : 1,
            });
        }

        if (prevProps.quantity !== this.props.quantity) {
            this.setState({
                value: this.props.quantity,
                previousValue: this.props.quantity,
                itemsInPackage: this.props.orderAsPackage
                    ? this.props.itemsInPackage
                    : 1,
            });
        }
    }

    setRef = (node) => {
        this.ref = node;
    };

    handleIncrement = () => {
        const { itemsInPackage } = this.state;

        this.setState((prevState) => {
            const value =
                this.getProperValue(prevState.value, itemsInPackage) +
                itemsInPackage;

            this.addItemToCart(value);
            return {
                value: value,
            };
        });
    };

    handleDecrement = () => {
        const { itemsInPackage } = this.state;

        this.setState((prevState) => {
            const value =
                this.getProperValue(prevState.value, itemsInPackage) -
                itemsInPackage;

            this.addItemToCart(value);
            return {
                value: this.getProperValue(value),
            };
        });
    };

    handleChange = (e) => {
        const { previousValue } = this.state;
        this.setState({
            value: this.getProperValue(e.target.value),
            previousValue,
        });
    };

    getProperValue = (value, itemsInPackage) => {
        let v = Number.parseInt(value, 10) || 0;

        if (itemsInPackage && v % itemsInPackage !== 0) {
            return itemsInPackage * Math.ceil(v / itemsInPackage);
        }

        if (v <= 0) {
            return 0;
        }

        return v;
    };

    addItemToCartOnBlur = (e) => {
        const { itemsInPackage, intl, orderAsPackage } = this.props;

        if (e.target.value % itemsInPackage === 0) {
            this.addItemToCart(e.target.value);
            return;
        }

        this.addItemToCart(this.getProperValue(e.target.value, itemsInPackage));

        this.setState({
            value: this.getProperValue(
                e.target.value,
                this.state.itemsInPackage
            ),
        });

        if (orderAsPackage) {
            swal.fire({
                text: intl.formatMessage(
                    { id: 'quantity.order_as_package.text' },
                    { itemsInPackage: itemsInPackage }
                ),
                type: 'warning',
                confirmButtonText: intl.formatMessage({
                    id: 'quantity.order_as_package.btn',
                }),
            });
        } else {
            swal.fire({
                text: intl.formatMessage(
                    { id: 'quantity.order_as_package_false.text' },
                    { itemsInPackage: itemsInPackage }
                ),
                type: 'warning',
                confirmButtonText: intl.formatMessage({
                    id: 'quantity.order_as_package_false.btn',
                }),
            });
        }
    };

    addItemToCart = debounce(async (amount) => {
        this.props.addItemCart({
            productId: this.props.productId,
            amount: amount,
        });
    }, 500);

    goToNextInputKeyPress = (e) => {
        const { tabIndex } = this.props;
        if (['Enter', 'ArrowDown'].includes(e.key)) {
            const input = document.querySelector(
                '[tabIndex="' + (tabIndex + 1) + '"]'
            );
            if (input) {
                input.focus();
            }
        } else if (e.key === 'ArrowUp') {
            const input = document.querySelector(
                '[tabIndex="' + (tabIndex - 1) + '"]'
            );
            if (input) {
                input.focus();
            }
        }
    };

    render() {
        const { product, isProductsList, showCount, isModal } = this.props;

        if (product.is_available === false) {
            return (
                <div className="quantity">
                    <span className="not_available">
                        {product.not_available_message}
                    </span>
                </div>
            );
        }

        return (
            <Quantity
                value={this.state.value}
                isShortInterface={this.state.isShortInterface}
                requestForInputBlur={true}
                handleChange={this.handleChange}
                handleIncrement={this.handleIncrement}
                handleDecrement={this.handleDecrement}
                tabIndex={this.props.tabIndex}
                addItemToCart={this.addItemToCartOnBlur}
                setRef={this.setRef}
                goToNextInputKeyPress={this.goToNextInputKeyPress}
                isProductsList={isProductsList}
                showCount={showCount}
                isModal={isModal}
            />
        );
    }
}

QuantityContainer.propTypes = {
    addItemCart: PropTypes.func,
    itemsInPackage: PropTypes.number,
    orderAsPackage: PropTypes.bool,
    productId: PropTypes.number,
    quantity: PropTypes.number,
    tabIndex: PropTypes.number,
};

const mapStateToProps = (state) => {
    return {
        isOpen: state.deliveryDatepicker.isDeliveryDatepickerOpen,
        products: state.masterTable.products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                addItemCart,
            },
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(QuantityContainer));
