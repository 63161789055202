import React from 'react';
import PropTypes from 'prop-types';
import SectionButton from "./SectionButton";

class SectionButtonContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovered: false,
        }
    }

    onMouseEnter = () => {
        this.setState({
            isHovered: true
        })
    };

    onMouseLeave = () => {
        this.setState({
            isHovered: false
        })
    };


    render() {
        const {label, icon, iconHover, to, isExternal, isDisabled} = this.props;
        return (
            <SectionButton
                to={to}
                label={label}
                icon={icon}
                iconHover={iconHover}
                isHovered={this.state.isHovered}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                isExternal={isExternal}
                isDisabled={isDisabled}
            />
        )
    }
}

SectionButtonContainer.propTypes = {
  isDisabled: PropTypes.any,
  icon: PropTypes.string.isRequired,
  iconHover: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};


SectionButtonContainer.defaultProps = {
  isDisabled: false,
  isExternal: false
};

export default SectionButtonContainer;
