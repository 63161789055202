import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { Context } from '../context/context';

const TotalPriceColor = ({ rowOriginal }) => {
    const { draftCartList, cartList } = useContext(Context);
    const [isAmountMismatched, setIsAmountMismatched] = useState();

    useEffect(() => {
        const draftItem = draftCartList?.find(
            (el) => el.product?.id === rowOriginal?.id
        );
        const fullCartItem = cartList[0]?.basket_items?.find(
            (el) => el?.product?.id === rowOriginal?.id
        );
        draftItem &&
            fullCartItem &&
            setIsAmountMismatched(
                Boolean(draftItem.amount !== fullCartItem.amount)
            );
    }, [draftCartList, cartList, rowOriginal]);

    return (
        <div
            style={{
                backgroundColor: isAmountMismatched ? '#cccccc' : 'inherit',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            <div>{rowOriginal.total_price}</div>
        </div>
    );
};

export default TotalPriceColor;
