import { fetchFairProductGroups } from '../actions/routines';

const initialState = {
    data: [],
    loading: false,
};

const fairProductGroups = (state = initialState, action) => {
    switch (action.type) {
        case 'FAIR_PRODUCT_GROUPS_SET':
            return { ...state };
        case fetchFairProductGroups.TRIGGER:
            return {
                ...state,
                loading: false,
            };
        case fetchFairProductGroups.SUCCESS:
            return {
                ...state,
                data: action.payload.data,
            };
        case fetchFairProductGroups.FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case fetchFairProductGroups.FULFILL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default fairProductGroups;
