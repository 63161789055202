import React from "react";
import { Col, Row, Button } from "mdbreact";
import { FormattedMessage, injectIntl } from "react-intl";
import SupplierSelect from "./components/SupplierSelect";

const TableHeader = ({
    selectedSupplier,
    suppliers,
    handleSelectSupplier,
    fairPoints,
    showDynamicWeeksButton,
    disableDynamicWeeksButton,
    handleDynamicWeeksClick,
   intl,
}) => {
    return (
        <Row className="fair-table-header">
            <Col sm="4">
                <SupplierSelect
                    selectedSupplier={selectedSupplier}
                    suppliers={suppliers}
                    handleSelectSupplier={handleSelectSupplier}
                />
            </Col>
            {showDynamicWeeksButton && <Col sm="3">
              <Button className="btn-main" onClick={handleDynamicWeeksClick} disabled={disableDynamicWeeksButton}>
                + &nbsp;
                <FormattedMessage id="fair.dynamic_weeks.add_week_button" />
              </Button>
            </Col>}
            {/*<Col className="fair-table-header__points" sm="5">*/}
            {/*    {fairPoints.isLoading ? (*/}
            {/*        <Spinner className="brand-color" width={30} height={30} />*/}
            {/*    ) : (*/}
            {/*        <React.Fragment>*/}
            {/*            <FormattedMessage id="fair.table_header.points" />*/}
            {/*            /!*:{" "}*!/*/}
            {/*            /!*{fairPoints.data.rewardPoints || 0}{" "}*!/*/}
            {/*            /!*<FormattedMessage id="units.pts" />*!/*/}
            {/*            <div>*/}
            {/*                <a href={intl.locale === 'fr' ? pdfFr : pdfNl} download className="fair-table-header__link-download">*/}
            {/*                    <FormattedMessage id="fair.table_header.download" />*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </React.Fragment>*/}
            {/*    )}*/}
            {/*</Col>*/}
        </Row>
    );
};

export default injectIntl(TableHeader);
