import PropTypes from 'prop-types'
import React from 'react';
import Search from "../../../Search/Search";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {searchRecipes} from "../../../../actions/routines";
import history from '../../../../history';
import * as qs from "qs";

class SearchContainer extends React.Component {

    search = (params) => {
        if (history.location.pathname !== "/recipes/search") {
            history.push(`/recipes/search?${qs.stringify(params)}`);
            return
        }

        this.props.onReSearch();
    };

    render() {
        const {intl} = this.props;

        return (
            <Search
                placeholder={intl.formatMessage({id: "recipes.search.placeholder"})}
                label="recipes.search.btn"
                onSubmit={this.search}
                loading={this.props.loading}
            />
        );
    }
}

SearchContainer.propTypes = {
  intl: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        loading: state.recipeCategories.loading
    }
};

export default injectIntl(connect(mapStateToProps, {searchRecipes})(SearchContainer));
