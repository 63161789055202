import React from 'react';
import PropTypes from 'prop-types'

const Inline = ({title, text, url, display, show, onAnimationEnd, handleClose}) => (
    <div onAnimationEnd={onAnimationEnd} className={`notification notification--inline animated ${!display? 'd-none': ''} ${!show ? 'fadeOutUp': ''}`}>
        <p className="notification__title">{title}</p>
        {url ?
        <a href={url} className="notification__url" target="__blank">
            <p className="notification__text">{text}</p>
        </a>
            :
        <p className="notification__text">{text}</p>
        }
        <span onClick={handleClose} className="notification__close"/>
    </div>
);

Inline.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string,
    display: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    onAnimationEnd: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default Inline;
