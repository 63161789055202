import PropTypes from 'prop-types'
import React from "react";
import {Redirect} from "react-router";
import {connect} from "react-redux"
import HeaderFooterRoute from "./HeaderFooterRoute";
import history from '../../history';

const ProtectedRoute = ({ component: Component, withHeader, withFooter, isAuthenticated,  ...rest }) => {
    if (!isAuthenticated) {
        return <Redirect to="/"/>
    }


    if (localStorage.needPasswordReset === '1' && history.location.pathname !== "/security/force-new-password") {
        return <Redirect to="/security/force-new-password"/>
    }

    if (withHeader || withFooter) {
        return <HeaderFooterRoute component={Component} withFooter={withFooter} withHeader={withHeader} {...rest}/>
    }


    return <Component {...rest} />
};


ProtectedRoute.propTypes = {
    isAuthenticated: PropTypes.bool,
    withFooter: PropTypes.bool,
    withHeader: PropTypes.bool
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.security.access_token
    }
};

export default connect(mapStateToProps, null)(ProtectedRoute);