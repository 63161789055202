import PropTypes from 'prop-types';
import React from 'react';
import Search from '../../../Search/Search';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchInvoices } from '../../../../actions/routines';

class SearchContainer extends React.Component {
    search = (params) => {
        this.props.fetchInvoices(params);
    };

    render() {
        const { intl, loading } = this.props;

        return (
            <Search
                placeholder={intl.formatMessage({
                    id: 'invoice.search.placeholder',
                })}
                label="invoice.search.btn"
                onSubmit={this.search}
                loading={loading}
            />
        );
    }
}

SearchContainer.propTypes = {
    intl: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        loading: state.invoices.loading,
    };
};

export default injectIntl(
    connect(mapStateToProps, { fetchInvoices })(SearchContainer)
);
