import PropTypes from 'prop-types';
import React from 'react';
import { Carousel, CarouselInner, CarouselItem, View } from 'mdbreact';
import ImagePromotion from './ImagePromotion';
import BoxesPromotion from './BoxesPromotion';

const CarouselPromotion = ({ promotions }) => {
    const totalProducts = promotions
        .map((promotion) => promotion.products)
        .flat();

    const totalImages = promotions
        .filter((promotion) => promotion.image)
        .flat();

    let position = 0;
    return (
        <Carousel
            activeItem={1}
            length={totalProducts.length + totalImages.length}
            showControls={true}
            showIndicators={true}
            interval={10000}
        >
            <CarouselInner>
                {promotions &&
                    promotions.map((promotion, index) => {
                        if (promotion.type === 0) {
                            ++position;
                            return (
                                <CarouselItem
                                    key={`${promotion.id}-${position}-image`}
                                    itemId={position}
                                    className="promotional-modal__item--boxes"
                                >
                                    <View>
                                        <ImagePromotion data={promotion} />
                                    </View>
                                </CarouselItem>
                            );
                        } else {
                            return (
                                promotion.products &&
                                promotion.products.map(
                                    (product, productIndex) => {
                                        ++position;
                                        return (
                                            <CarouselItem
                                                key={`${promotion.id}-${product.id}`}
                                                itemId={position}
                                                className="promotional-modal__item--boxes"
                                            >
                                                <View>
                                                    <BoxesPromotion
                                                        items={[product]}
                                                    />
                                                </View>
                                            </CarouselItem>
                                        );
                                    }
                                )
                            );
                        }
                    })}
            </CarouselInner>
        </Carousel>
    );
};

CarouselPromotion.propTypes = {
    promotions: PropTypes.array.isRequired,
};

export default CarouselPromotion;
