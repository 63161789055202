import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchTagData } from '../../../../../actions/routines';
import qs from 'qs';
import history from '../../../../../history';
import * as R from 'ramda';
import { fetchProducts } from '../../../../../actions/masterTable';

class TagInfoContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lastLoadedTagId: 0,
        };
    }

    componentDidMount() {
        const params = qs.parse(history.location.search.slice(1));

        if (params['s_tag']) {
            this.setState({ lastLoadedTagId: params['s_tag'] });
            this.props.fetchTagData(params['s_tag']);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const params = qs.parse(history.location.search.slice(1));

        if (
            (!this.state.lastLoadedTagId ||
                !R.equals(
                    prevState.lastLoadedTagId,
                    this.state.lastLoadedTagId
                )) &&
            !this.props.tagLoading &&
            params['s_tag']
        ) {
            this.setState({ lastLoadedTagId: params['s_tag'] });
            this.props.fetchTagData(params['s_tag']);
        }

        if (!params['s_tag'] && this.state.lastLoadedTagId) {
            this.setState({ lastLoadedTagId: 0 });
        }
    }

    handlerClearSelectedTag = () => {
        const params = qs.parse(history.location.search.slice(1));
        delete params['s_tag'];

        this.setState({ lastLoadedTagId: 0 });

        history.push({
            ...history.location,
            search: qs.stringify(params),
        });

        if (!history.location.pathname.includes('products')) {
            history.push({ pathname: '/products' });
        } else {
            this.props.fetchProducts({ params });
        }
    };

    render() {
        const { tag } = this.props;

        if (!tag || parseInt(this.state.lastLoadedTagId) !== parseInt(tag.id)) {
            return <></>;
        }

        return (
            <div className="row">
                <div className="col mb-3">
                    <FormattedMessage
                        id="selectedTag"
                        values={{ tagName: this.props.tag?.name }}
                    />
                    &nbsp;
                    <a href="#" onClick={() => this.handlerClearSelectedTag()}>
                        x
                    </a>
                </div>
            </div>
        );
    }
}

TagInfoContainer.defaultProps = {
    tag: null,
};

TagInfoContainer.propTypes = {
    tagLoading: PropTypes.bool,
    fetchTagData: PropTypes.func,
    fetchProducts: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        tag: state.tag?.data,
        tagLoading: state.tag?.loading,
    };
};
export default injectIntl(
    connect(mapStateToProps, { fetchTagData, fetchProducts })(TagInfoContainer)
);
