import request from './request';

const Security = {
    login: ({login, password}) => {
        return request({
            url: '/oauth/v2/token',
            method: 'POST',
            data: {
                username: login,
                password: password,
                grant_type: 'password',
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET
            }
        });
    },
    requestNewPassword: (login) => {
        return request({
            url: `/api/auth/reset/request/${login}`,
            method: 'GET'
        });
    },
    sendNewPasswordResetToken: ({token, password, passwordRepeat}) => {
        return request({
            url: `/api/auth/reset/confirm/${token}`,
            method: 'POST',
            data: {
                plainPassword: {
                    first: password,
                    second: passwordRepeat
                }
            }
        });
    },
    sendNewPasswordAccessToken: ({password, passwordRepeat}) => {
        return request({
            url: `/api/auth/new-password`,
            method: 'POST',
            data: {
                plainPassword: {
                    first: password,
                    second: passwordRepeat
                }
            }
        });
    },
    refresh: () => {
        return request({
            url: '/oauth/v2/token',
            method: 'POST',
            data: {
                refresh_token: localStorage.refreshToken,
                grant_type: 'refresh_token',
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET
            }
        });
    },
};

export default Security;