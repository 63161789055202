import PropTypes from 'prop-types'
import React from 'react';
import Search from "../../../Search/Search";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {fetchDownloadCenterFolders, searchDownloadCenterFiles} from "../../../../actions/routines";
import history from '../../../../history';

class SearchContainer extends React.Component {
    search = (params) => {
        history.push('/downloads');

        if (params['s_all']) {
            this.props.searchDownloadCenterFiles(params);

            return;
        }

        this.props.fetchDownloadCenterFolders();
    };

    render() {
        const {intl, loading} = this.props;

        return (
            <Search
                onSubmit={this.search}
                loading={loading}
                placeholder={intl.formatMessage({id: "downloads.search.placeholder"})}
                label="downloads.search.btn"
            />
        );
    }
}

SearchContainer.propTypes = {
  intl: PropTypes.object.isRequired
};
const mapStateToProps = state => {
    return {
        loading: state.downloadCenterFilesSearch.loading
    }
}

export default injectIntl(connect(mapStateToProps, {searchDownloadCenterFiles, fetchDownloadCenterFolders})(SearchContainer));
