import routineReducerFactory from "./routineReducerFactory";
import {fetchDelivers, fetchDeliverySchema} from "../actions/routines";

export const deliverySchema = routineReducerFactory(fetchDeliverySchema, {data: [], loading: false});

const deliversState = {
    data: [],
    loading: true,
    error: null,
    loaded: false,
    transportCostAmount: 0
};
export const delivers = (state = deliversState, action) => {
    switch (action.type) {
        case fetchDelivers.TRIGGER:
            return {
                ...state,
                loading: true,
            };
        case fetchDelivers.SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                transportCostAmount: action.payload.transportCostAmount,
                loaded: true,
            };
        case fetchDelivers.FAILURE:
            return {
                ...state,
                error: action.payload.data,
            };
        case fetchDelivers.FULFILL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    };
};
