import {connect} from "react-redux";
import DiscountSummaryBox from "./DiscountSummaryBox";

const mapStateToProps = state => {
    const {
        fairPoints: {
            data: {
                userPoints,
            }
        },
    } = state;

    return {
        userPoints,
    }
};

export default connect(mapStateToProps, null)(DiscountSummaryBox);